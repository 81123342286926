<template>
    <HeaderView />

    <div class="main-content place-detail-view">        
        <main>
            <!-- Main Slides Area -->
            <!-- <div class="main-slide-container">
              <div class="slides-bg">
                  <div class="section-title">
                    <h2>Find the different ways to explore <br> mouthwatering foods </h2>
                  </div>
              </div>
            </div> -->
            <div class="main-slide-container home-slider no-bg-image grid-slider-swiper grid-slider-swiperEvent">
              <div>
                <div class="grid m-0">
                  <div class="col-12 md:col-6 md:col-6 p-0">
                    <div class="slider-left-content-event-area">
                      <div class="section-title">
                        <!-- ENJOY TOP EVENTS IN DOHA AT ESTEEMED PLACES. BOOK WITH CONVENIENCE. -->
                        {{$t('event_banner_text')}}
                      </div>
                      <div class="leftBannerSec-bottom-text">
                        <p>{{$t('5000_restaurents')}}</p>
                        <p>{{ $t('banner_subtitle_text') }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 md:col-6 md:col-6 p-0">
                    <div class="main-slide-container event-bg">
                      
                    </div>
                    <!-- <swiper 
                      :slides-per-view="1" 
                      :space-between="0" 
                      :modules="modules" 
                      setWrapperSize="ture"
                      :loop="true"
                      :autoplay="{
                        delay: 2000,
                        disableOnInteraction: false,
                      }"
                      :pagination="{
                        clickable: true,
                      }"
                      :breakpoints="{
                        '@0.00': {slidesPerView: 1,spaceBetween: 0},
                        '@0.75': {slidesPerView: 1,spaceBetween: 0},
                        '@1.00': {slidesPerView: 1,spaceBetween: 0},
                        '@1.50': {slidesPerView: 1,spaceBetween: 0},
                      }"
                      @swiper="onSwiper"
                      @slideChange="onSlideChange"
                    >
                      <swiper-slide>  
                        <router-link to='/place-detail/'>
                              <div class="main-slide-container">
                                <div class="section-title">
                                </div>
                              </div>
                        </router-link>
                      </swiper-slide>

                      <swiper-slide>  
                        <router-link to='/place-detail/'>
                              <div class="main-slide-container">
                                <div class="section-title">
                                </div>
                              </div>
                        </router-link>
                      </swiper-slide>

                      <swiper-slide>  
                        <router-link to='/place-detail/'>
                              <div class="main-slide-container">
                                <div class="section-title">
                                </div>
                              </div>
                        </router-link>
                      </swiper-slide>
                    </swiper> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- / Main Slides Area -->

            <EventsFilterPopup @events="getEvents"></EventsFilterPopup>


            <!-- Restaurents Section Area -->
            <div class="restaurens-box restaurents-section-area">
                <div class="grid m-0">

                    <div class="all-restaurents-leftBox col-12 md:col-12 lg:col-12">
                        <div class="flex align-items-center justify-content-between mb-5">
                            <h2 class="section-title-text mb-0 margin-top-0">{{$t('all_events')}}</h2>

                            <div class="switch-restaurents-layout">
                                <div class="addFilter-itemsArea mr-1">
                                  <div class="addFilter-item">
                                    <dropdown-prime v-model="selectedCity" :options="cities" optionLabel="name" :placeholder="$t('sorting')" class="w-full md:w-10rem lg:w-10rem" />
                                  </div>
                                </div>
                                <!-- <span class="grid-layout-icon" v-on:click="isHidden = !isHidden">
                                  <i class="pi pi-bars" style="font-size: 1rem"></i>
                                </span> -->
                                <span class="list-layout-icon" v-on:click="isHidden = !isHidden">
                                  <i class="pi pi-th-large" style="font-size: 1rem"></i>
                                </span>
                            </div>
                        </div>

                        <div v-bind:class="{ 'grid ml-0 mr-0 restaurents-grid-layout': !isHidden, 'grid ml-0 mr-0 restaurents-list-layout events-list-layout' : isHidden}">
                            <div v-bind:class="{ 'col-12 md:col-3 lg:col-3': !isHidden, 'col-12 md:col-12 lg:col-12' : isHidden}" v-for="item in events" :key="item">
                                <div class="restaurents-box-items">
                                    <div class="restaurents-box-image">
                                      <i :class="'pi pi-heart-fill love-sign-restaurent event-id-'+item.id"  @click="addremovelove($event, item.id)" />
                                      <i class="pi pi-share-alt share-sign" />
                                      <swiper
                                        :slides-per-view="4"
                                        :space-between="10"
                                        :modules="modules"
                                        :navigation="true"
                                        :breakpoints="{
                                          '@0.00': { slidesPerView: 1, spaceBetween: 0,},
                                          '@0.75': { slidesPerView: 1, spaceBetween: 0,},
                                          '@1.00': { slidesPerView: 1, spaceBetween: 0,},
                                          '@1.50': { slidesPerView: 1, spaceBetween: 0,},
                                        }"
                                        @swiper="onSwiper"
                                        @slideChange="onSlideChange"
                                        :pagination="{ clickable: true}"
                                      >
                                        <swiper-slide v-show="item.photos.length>0" v-for="photos in item.photos" :key="photos">
                                          <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                              <div class="mb-3">
                                                <router-link :to="'/event-detail/' + item.id">   
                                                  <img :src="photos.image_path"  class="w-6 shadow-2" />
                                                </router-link>
                                              </div>
                                          </div>
                                        </swiper-slide>
                                        <swiper-slide v-if="item.photos.length==0">
                                          <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                              <div class="mb-3">
                                                <router-link :to="'/event-detail/' + item.id">   
                                                  <img v-bind:src="require('/src/assets/images/restaurent-sliders/slider2.jpg')"  class="w-6 shadow-2" />
                                                </router-link>
                                              </div>
                                          </div>
                                        </swiper-slide>
                                        <swiper-slide v-show="item.photos.length==0">
                                          <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                              <div class="mb-3">
                                                <router-link :to="'/event-detail/' + item.id">   
                                                  <img v-bind:src="require('/src/assets/images/restaurent-sliders/slider3.jpg')"  class="w-6 shadow-2" />
                                                </router-link>
                                              </div>
                                          </div>
                                        </swiper-slide>
                                      </swiper>

                                      <div class="restaurent-logo">
                                        <img v-bind:src="require('/src/assets/images/logo/amaken-logo.svg')">
                                      </div>
                                    </div>
                                    <div class="card-info-area card-info-restaurent-area card-info-restaurent-area-event" v-if="!isHidden"><!--Grid view-->
                                      <div class="restaurent-info-right">
                                        <div class="listing-card-title">
                                            <span class="restaurents-name event-name">{{ get_event_name(item) }}</span>
                                        </div>
                                        <!-- <div class="listing-card-subtitle">
                                            <span class="event-location"> {{ item.place.area == null ? "" : item.place.area.name+", " }} {{ item.place.city == null ? "" : item.place.city.name }} {{ item.place.country == null ? "" : item.place.country.name }}</span>
                                            <span class="event-place">
                                              <b>Starting</b>
                                          </span>
                                        </div> -->
                                        <div class="listing-card-subtitle">
                                            <span class="event-time"><strong>{{ $t('starting') }} </strong>{{ item.price }}</span>
                                        </div>
                                        <div class="listing-card-subtitle">
                                            <span class="event-time">{{item.currentdate}} {{ item.start_time }} </span>
                                        </div>
                                        
                                      </div>
                                    </div>

                                    <div v-else class="card-info-area card-info-restaurent-area"><!--list view-->
                                      <div class="restaurent-info-right">
                                        <div class="listing-card-title">
                                            <span class="restaurents-name">{{ get_event_name(item) }}</span>
                                        </div>
                                        <!-- <div class="listing-card-subtitle">
                                            <span class="event-location">  {{ item.place.area == null ? "" : item.place.area.name+", " }} {{ item.place.city == null ? "" : item.place.city.name }} {{ item.place.country == null ? "" : item.place.country.name }}</span>
                                            <span class="event-place">
                                              <b>Starting</b>
                                          </span>
                                        </div> -->
                                        <div class="listing-card-subtitle">
                                            <span class="event-time"><strong>{{ $t('starting') }} </strong>{{ item.start_time }}</span>
                                        </div>
                                        <div class="listing-card-subtitle">
                                            <span class="rwview-label">{{item.price}}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                        <!-- <div class="mt-3 mb-2">
                            <paginator-prime :rows="5" :totalRecords="120"></paginator-prime>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- / Restaurents Section Area -->

        </main>
    </div>

    <FooterView />
    <div class="card flex justify-content-center text-center">
      <dialog-prime class="login-popup-modal" v-model:visible="login_alert_modal" modal header="" :style="{ width: '50vw', textAlign : 'center' }">
          <p>{{ $t('login_your_ac_first') }}</p>
          <router-link to="/signin"><button-prime :label="$t('signin')" class="" severity="primary" rounded /></router-link>
          <router-link to="/signin"><button-prime :label="$t('signup')" class="ml-2" severity="primary" rounded /></router-link>
      </dialog-prime>
    </div>
</template>
  


<script>
import HeaderView from '/src/components/Header/HeaderView.vue'
import FooterView from '/src/components/Footer/FooterView.vue'
import EventsFilterPopup from '/src/components/EventsFilterPopup.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import axios from 'axios'
import $ from 'jquery'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation } from 'swiper';
import { Pagination } from 'swiper';
import { Autoplay } from 'swiper';

export default {
    components: {
        HeaderView,
        FooterView,
        Swiper,
        SwiperSlide,
        EventsFilterPopup
    },
    data() {
        return {
          responsiveOptionsThree : [{breakpoint: '1199px',numVisible: 1,numScroll: 1},{breakpoint: '991px',numVisible: 1, numScroll: 1},{breakpoint: '767px',numVisible: 1,numScroll: 1}],
          bannersImage : [],
          events : [],
          modules: [Navigation, Pagination, Autoplay],
          isHidden: false,
          active: false,
          heartValue : false,
          like_value : 0,
          like_event : 0,
          login_alert_modal : false,
          liked_events : []
        }
        
    },
    mounted() {
      this.get_events();
    },
    methods: {
      get_event_name : function(item) {
        var event_name = item.name;
        if (this.amaken_language == 'AR') {
          event_name = item.arabic_name;
        }
        if(event_name == null) {
          event_name = item.name;
        }
        return event_name;
      },
      getEvents(value) {
          this.events = value;
      },
      get_liked_data : function() {
        axios.get(this.api_url+'liked_events',{
          headers: { 
              'Authorization': 'Bearer '+localStorage.getItem('token')
          }
        }).then((response) => {
          if (response.data.status == true) {
            this.liked_events = response.data.data; 
            $.each(this.liked_events, function(index, value) {
              $('.event-id-'+value.event_id).addClass('heartActive');
            })
          }
        });
      },
      addremovelove : function(event, event_id) {
        this.like_event = event_id;
        if (event.target.classList.contains('heartActive')) {
          this.like_value = 0;
          this.heartValue = false;
        } else {
          this.like_value = 1;
          this.heartValue = true;
        }
        axios.get(this.api_url+'submit_like_event',{
          headers: { 
              'Authorization': 'Bearer '+localStorage.getItem('token')
          },
          params : {
            like : this.like_value,
            event : this.like_event
          } 
        }).then((response) => {
          if (response.data.status == false) {
            this.login_alert_modal = true;
          } else {
            if (!this.heartValue) {
              event.target.classList.remove('heartActive');
            } else {
              event.target.classList.toggle('heartActive');
            }
          }
        });
      },
      set_cuisine_text : function(cuisinedata) {
        var text = '';
        for (let i = 0; i < cuisinedata.length; i++) {
          text += cuisinedata[i].filtername.name;
          if (cuisinedata.length-1 != i) {
            text += ", ";
          }
          
        }
        return text;
      },
      get_events : function() {
        const searchURL = new URL(window.location);
        var priceRange = searchURL.searchParams.get('price_range') == null ? [] : searchURL.searchParams.get('price_range');
        var searchEventTypes = searchURL.searchParams.get('event_types') == null ? [] : searchURL.searchParams.get('event_types');
        var searchValue = searchURL.searchParams.get('search') == null ? [] : searchURL.searchParams.get('search');
        axios.get(this.api_url+'get_events', {
          params : {
            event_types: searchEventTypes,
            search : searchValue,
            price_range : priceRange
          }
        }).then((response) => {
          this.events = response.data.events;
          this.get_liked_data();
        });
      }
    }
   
};
</script>

<style>
</style>
  
  