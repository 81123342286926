<template>
    <HeaderView />

    <div class="main-content">        
        <main>
            <!-- Main Slides Area -->
            <div class="main-slide-container inner-sec-bg">
            <div class="slides-bg">

                <div class="section-title">
                <h2>{{$t('privacy_policy')}}</h2>
                </div>
            </div>
            </div>
            <!-- / Main Slides Area -->

            <div class="content-area"></div>

        </main>
    </div>

    <FooterView />
</template>


<script>
import HeaderView from '/src/components/Header/HeaderView.vue'
import FooterView from '/src/components/Footer/FooterView.vue'

export default {
    components: {
        HeaderView,
        FooterView,
    },
    data() {
        return {
        }
    },
    setup() {
        return {
        };
    },
};
</script>
  
<style>
.main-slide-container.inner-sec-bg {
    background: url('~@/assets/images/sliders/slide-1.png');
    height: 350px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.main-slide-container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000059;
    width: 100%;
    height: 100%;
}

.section-title h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.4;
}

.section-title {
    position: absolute;
    bottom: 60px;
    left: 40px;
}

.slides-bg {
    position: relative;
    height: 100%;
}

.content-area {
    height: 100vh;
}

</style>
  
  
  