<template>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {}
  },
  created() {
    if (localStorage.getItem('amaken_language') == 'EN') {
      $('body').removeClass('direction-rtl');
    } else if (localStorage.getItem('amaken_language') == 'AR') {
      $('body').addClass('direction-rtl');
    }
  } 
  
}
</script>