<template>
    <HeaderView />

    <div class="main-content place-detail-view place-detail-wrapper offer-detail-wrapper grid m-0">
        <main class="col-12 md:col-6 lg:col-6 p-0">
            <div class="restaurent-detail-sliderInfo offer-detail-sliderInfo">
                <h2 class="section-title-text m-0"> {{ get_offer_name(offer) }} </h2>
            </div>

           <!-- Main Slides Area -->
          <div class="main-slide-container home-slider">
            <!-- <i :class="'pi pi-heart-fill love-sign-restaurent offer-id-'+offer.id" /> -->
            <!-- <i class="pi pi-share-alt share-sign" /> -->
            <div>
              <swiper 
                :slides-per-view="1" 
                :space-between="0" 
                :modules="modules" 
                setWrapperSize="ture"
                :loop="true"
                :autoplay="{
                  delay: 2000,
                  disableOnInteraction: false,
                }"
                :pagination="{
                  clickable: true,
                }"
                :breakpoints="{
                  '@0.00': {slidesPerView: 1,spaceBetween: 0},
                  '@0.75': {slidesPerView: 1,spaceBetween: 0},
                  '@1.00': {slidesPerView: 1,spaceBetween: 0},
                  '@1.50': {slidesPerView: 1,spaceBetween: 0},
                }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
              >
                <swiper-slide>  
                  <div class="main-slide-container" v-bind:style="{'background-image': 'url('+offer.photos.image_path+')'}">
                    <div class="section-title">
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        <!-- / Main Slides Area -->
        
        <!-- Description Section -->
        <div class="restaurents-section-area restaurentsDetail-overview-area section-mt mb-5" id="overview" v-html="get_offer_description(offer)">
           
        </div>
        <!-- / Description Section -->

        <!-- Description Section -->
        <!-- <div class="restaurents-section-area restaurentsDetail-overview-area section-mt mb-5 contents" id="description">
            <h2 class="section-title-text"> Highlights </h2>

            <ul>
                <li>
                    Make your Friday special and indulge in a delectable brunch buffet including a variety of International delicacies at Holiday Inn Riyadh Al Qasr for SAR 98!
                </li>
                <li>
                    <strong>Choose one of the following options:</strong><br>
                    <strong>Option 1:</strong> Friday brunch buffet for 1 adult for SAR 98<br>
                    <strong>Option 2: </strong>Friday brunch buffet for 1 child for SAR 50
                </li>
                <li>
                    <strong>Deal includes:</strong>
                    <br>
                    - International dishes<br>
                    - 2 Grill stations<br>
                    - Desserts<br>
                    - Hot and cold drinks
                </li>
                <li>Children's play area</li>
            </ul>
        </div> -->
        <!-- / Description Section -->

        <!-- Conditions Section -->
        <!-- <div class="restaurents-section-area restaurentsDetail-overview-area section-mt mb-5 contents" id="conditions">
            <h2 class="section-title-text"> Conditions </h2>

            <ul>
                <li>Valid for 30 days from date of purchase</li>

                <li>Valid on Friday for dine-in only</li>

                <li><strong>Timings:</strong> From 11:30 am to 4:00 pm</li>

                <li>Prior booking required, Priority for early bookings</li>
                
                <li>
                    For availability &amp; bookings please WhatsApp Amaken reservations team on +966536663121, daily between 09:30 am to 06:30 pm
                </li>

                <li>
                    <strong>Child Policy:</strong>
                    <br>
                    - Maximum of 2 children below 6 years can eat for free (per adult voucher)<br>
                    - Children aged 6 to 11 years require a Amaken child voucher (Option 2)<br>
                    - Children aged above 11 years require a Amaken adult voucher (Option 1)
                </li>

                <li>All preventive measures and precautions are applied according to the instructions of the Ministry of Health</li> -->

                <!-- <li>Please read the terms that apply for all deals <a href="https://www.amaken.com/en/static/deal-terms" target="_blank">here</a></li> -->
            <!-- </ul>
        </div> -->
        <!-- / Conditions Section -->
    
        </main>
    </div>

    <FooterView />

    <div class="card flex justify-content-center text-center">
      <dialog-prime class="login-popup-modal" v-model:visible="login_alert_modal" modal header="" :style="{ width: '50vw', textAlign : 'center' }">
          <p>{{ $t('login_your_ac_first') }}</p>
          <router-link to="/signin"><button-prime :label="$t('signin')" class="" severity="primary" rounded /></router-link>
          <router-link to="/signin"><button-prime :label="$t('signup')" class="ml-2" severity="primary" rounded /></router-link>
      </dialog-prime>
    </div>

</template>

<script>
import HeaderView from '/src/components/Header/HeaderView.vue'
import FooterView from '/src/components/Footer/FooterView.vue'
// import Reviewlisting from '/src/components/ReviewListing.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import axios from 'axios'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Pagination } from 'swiper';

// import required modules
import { Navigation } from 'swiper';

export default {
    components: {
        HeaderView,
        FooterView,
        Swiper,
        SwiperSlide,
        // Reviewlisting
    },
    data() {
        return {
            modules: [Navigation, Pagination],
            responsiveOptions : [{breakpoint: '1199px',numVisible: 1,numScroll: 3},{breakpoint: '991px',numVisible: 1,numScroll: 1},{breakpoint: '767px',numVisible: 1,numScroll: 1}],
            offer : {
                name : null,
                arabic_name : null,
                description : null,
                arabic_description : null,
                id : this.$route.params.id,
                photos : {
                    image_path : null
                }
            }
        }
    },
    mounted() {
      this.get_offer();
    },
    methods: {
        get_offer_name : function(item) {
            var offer_name = item.name;
            if (this.amaken_language == 'AR') {
                offer_name = item.arabic_name;
            }
            if(offer_name == null) {
                offer_name = item.name;
            }
            return offer_name;
        },
        get_offer_description : function(item) {
            var offer_description = item.description;
            if (this.amaken_language == 'AR') {
                offer_description = item.arabic_description;
            }
            if(offer_description == null) {
                offer_description = item.description;
            }
            return offer_description;
        },
        get_offer : function() {
            axios.get(this.api_url+'get_offer_detail', {
                params: { 
                    offer_id: this.offer.id
                } 
            }).then((response) => {
                this.offer = response.data.offer;
            });
        }
    }
};
</script>

<style>
.widget-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.widget-social-icon {
    margin-top: 0.6rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.widget-social-icon .social-icon-circle {
  color: #000;
    font-size: 16px;
    margin-right: 15px;
    background: #fff !important;
    width: 40px;
    height: 40px;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
}
.widget-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.widget-social-icon a {
    display: flex;
    color: #000;
    align-items: center;
    margin-bottom: 0.5rem;
}

.widget-social-icon a span {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
}

.select-time-chips a {
    color: #fff;
}
</style>
  
  
  