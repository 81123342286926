<template>
        <footer class="footer-section">
            <div class="footer-content pt-5 pb-5">
                <div class="grid m-0">
                    <div class="col-12 md:col-4 lg:col-4 mb-50">
                        <div class="footer-widget">
                            <div class="footer-logo mb-2">
                                <a><img class="site-logo-amaken" src="../../assets/images/logo/footer-logo.svg"></a>
                            </div>
                            <div class="footer-text">
                                <p>Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed do eiusmod tempor incididuntut consec tetur adipisicing
                                elit,Lorem ipsum dolor sit amet.</p>
                            </div>
                            <!-- <div class="app-download-icon-footer">
                              <a class="mr-1"><img class="site-logo-amaken" src="../../assets/Icons/google-app-icon.svg"></a>
                              <a><img class="site-logo-amaken" src="../../assets/Icons/app-store-icon.svg"></a>
                            </div> -->
                            <div class="footer-social-icon">
                                <!-- <span>Follow us</span> -->
                                <a href="#"><i class="pi pi-instagram" /></a>
                                <a href="#"><i class="pi pi-facebook" /></a>
                                <a href="#"><i class="pi pi-linkedin" /></a>
                                <!-- <a href="#"><i class="pi pi-linkedin" /></a> -->
                                <a href="#"><i class="pi pi-twitter" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-2 lg:col-2 col-md-2 mb-30">
                        <div class="footer-widget">
                            <ul>
                                <li><router-link to="/about-us"><a href="#"> {{ $t('about_us') }} </a></router-link></li>
                                <li><router-link to="/contact-us"><a href="#"> {{ $t('contact_us') }} </a></router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3 col-md-3 mb-30">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3> {{ $t('our_partners') }} </h3>
                            </div>
                            <ul>
                                <li>
                                  <router-link to="/become-a-partner">
                                    <a href="#"> {{ $t('become_a_partner') }} </a>
                                  </router-link>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 md:col-3 lg:col-3 col-md-3 mb-30">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>{{ $t('legal') }}</h3>
                            </div>
                            <ul>
                                <li><router-link to="/terms-and-conditions"><a href="#"> {{$t('terms_and_conditions')}} </a></router-link></li>
                                <li><router-link to="/privacy-policy"><a href="#"> {{ $t('privacy_policy') }} </a></router-link></li>
                                <li><a href="#"> {{ $t('cookies') }} </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    </footer>
</template>

<style>
ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
    background: #0091FB;
    position: relative;
    padding: 0px 20px;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #fff;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #fff;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
    max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
      color: #fff;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon {
    margin-top: 0.6rem;
}
.footer-social-icon a {
  color: #000;
    font-size: 16px;
    margin-right: 15px;
    background: #fff !important;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg{
  background: #3B5998;
}
.twitter-bg{
  background: #55ACEE;
}
.google-bg{
  background: #DD4B39;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #fff;
  display: none;
}
.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 100%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover{
  color: #fff;
}

.footer-widget ul li a {
  color: #fff;
  text-transform: capitalize;
  background: transparent;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    background: #fff;
    padding: 13px 20px;
    border: 1px solid #fff;
    top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area{
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.copyright-text p a{
  color: #fff;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a{
  color: #fff;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.app-download-icon-footer a img.site-logo-amaken {
    width: 160px;
}

.app-download-icon-footer {
    display: flex;
    align-items: center;
}
</style>