<template>
    <HeaderView />

    <div class="main-content place-detail-view place-detail-wrapper">
        <main>
           <!-- Main Slides Area -->
          <div class="main-slide-container home-slider">
            <i :class="'pi pi-heart-fill love-sign-restaurent place-id-'+place.id"  @click="addremoveloveinplace($event, place.id)" />
            <i class="pi pi-share-alt share-sign" />
            <div>
              <swiper 
                :slides-per-view="1" 
                :space-between="0" 
                :modules="modules" 
                setWrapperSize="ture"
                :loop="true"
                :autoplay="{
                  delay: 2000,
                  disableOnInteraction: false,
                }"
                :pagination="{
                  clickable: true,
                }"
                :breakpoints="{
                  '@0.00': {slidesPerView: 1,spaceBetween: 0},
                  '@0.75': {slidesPerView: 1,spaceBetween: 0},
                  '@1.00': {slidesPerView: 1,spaceBetween: 0},
                  '@1.50': {slidesPerView: 1,spaceBetween: 0},
                }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
              >
                <swiper-slide  v-for="item in bannerImages" :key="item">  
                  <div class="main-slide-container" v-bind:style="{'background-image': 'url('+item.image_path+')'}" >
                    <div class="section-title">
                    </div>
                  </div>
                </swiper-slide>
              </swiper>

              <div class="restaurent-detail-sliderInfo">
                <div class="title-rating-box">
                    <span class="closing-time" v-if="!openedOrClosed(place.current_day_timings)"> {{ $t('closed') }} </span>
                    <span class="opening-time" v-else>{{ $t('open_now') }}</span>
                    <!-- <span v-if="place.ratings != null" class="rating-label"><rating-prime :stars="1" :cancel="false" />{{place.ratings.rating+".0"}}</span> -->
                </div>

                <h2 class="section-title-text m-0">{{ get_place_name(place) }}</h2>
                <p class="detail-info-boxIcon cuisine-box"><span>{{ set_cuisine_text(place.cuisines) }}</span></p>
                <p class="detail-info-boxIcon cuisine-box">
                    <span>{{place.address != null ? place.address+',' : ''}} {{ place.area == null ? "" : place.area.name+", " }} {{ place.city == null ? "" : place.city.name }} {{ place.country == null ? "" : place.country.name }}</span>
                </p>
              </div>

            </div>
          </div>
        <!-- / Main Slides Area -->
    
        <!-- Category Section Area -->
        <div class="restaurents-section-area mt-5"> 
            <div class="grid m-0">
                <div class="col-12 restaurent-logo-detail detail-optionBtn-right">
                    <div class="restaurent-logo">
                        <img  v-bind:src="require('/src/assets/images/logo/amaken-logo.svg')">
                    </div>
                    <div class="section-title-area flex align-items-center justify-content-between detail-view-top-titleBtn-header">
                        <!-- <h2 class="section-title-text m-0">{{ place.name }} 
                            <span class="closing-time" v-if="!openedOrClosed(place.current_day_timings)">Closed</span>
                            <span class="opening-time" v-else>Open Now</span>
                        </h2> -->

                        <button-prime class="call-now-btn" label="Call now" severity="success" icon="pi pi-phone" rounded><a @click="call_logs" :href="'tel:'+place.landline" style="color : white;"> {{ $t('call_now') }} </a></button-prime>

                        <button-prime v-if="place.allow_booking==1" @click="book(place)" class="book-a-table-btn" label="Book a table" severity="success" icon="pi pi-phone" rounded>
                            <!-- <router-link :to="'/' +place.slug+'/book-a-table'"> -->
                                {{ $t('book_a_table') }}
                            <!-- </router-link> -->
                        </button-prime>
                                                                                                   
                    </div>
                </div>
            </div>
        </div>
        <!-- / Category Section Area -->

        <div class="details-inner-menu-options mb-5">
            <ul class="details-inner-menu-optionsUl">
                <swiper
                    :slides-per-view="6"
                    :space-between="0"
                    :modules="modules"
                    :breakpoints="{
                        '@0.00': {
                            slidesPerView: 2,
                            spaceBetween: 0,
                            // allowTouchMove: false,
                        },
                        '@0.75': {
                            slidesPerView: 4,
                            spaceBetween: 0,
                        },
                        '@1.00': {
                            slidesPerView: 4,
                            spaceBetween: 0,
                        },
                        '@1.50': {
                            slidesPerView: 6,
                            spaceBetween: 0,
                        },
                    }"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                >
                    <swiper-slide>
                        <li class="details-inner-menu-optionsLi active">
                            <a href="#overview"><span> {{ $t('overview') }} </span></a>
                        </li>
                    </swiper-slide>
                    <swiper-slide>
                        <li class="details-inner-menu-optionsLi">
                            <a href="#menu"><span>{{$t('menu')}}</span></a>
                        </li>
                    </swiper-slide>
                    <swiper-slide>
                        <li class="details-inner-menu-optionsLi">
                            <a href="#gallery"><span>{{$t('gallery')}}</span></a>
                        </li>
                    </swiper-slide>
                    <swiper-slide>
                        <li class="details-inner-menu-optionsLi">
                            <a href="#location"><span>{{ $t('location') }}</span></a>
                        </li>
                    </swiper-slide>
                    <swiper-slide>
                        <li class="details-inner-menu-optionsLi">
                            <a href="#reviews"><span>{{ $t('reviews') }}</span></a>
                        </li>
                    </swiper-slide>
                    <swiper-slide>
                        <li class="details-inner-menu-optionsLi">
                            <a href="#place-events">   
                                <span>{{$t('events')}}</span>
                            </a>
                        </li>
                    </swiper-slide>
                </swiper>
            </ul>
        </div>
        
        <div class="restaurent-detail-info mt-3">
            <div class="grid m-0">
                <div class="col-6 md:col-4 lg:col-4">
                    <div class="detail-info-box">
                        <div class="detail-info-boxIcon">
                            <i class="pi pi-map" style="font-size: 1.8rem;color: #0091fb;"></i>
                        </div>
                        <h4 class="detail-box-titles">{{ $t('place_type') }}</h4>
                        <p class="detail-info-boxIcon placetype-box">
                            <span>{{get_place_type_name(place.category)}}</span>
                        </p>
                    </div>
                </div>
                <div class="col-6 md:col-4 lg:col-4">
                    <div class="detail-info-box">
                        <div class="detail-info-boxIcon">
                            <svg style="width: 40px;color: #0091fb;fill: #0091fb;" viewBox="0 0 64 64" id="food"><path d="M52.229 16.398a2.381 2.381 0 1 0-.222-4.758l-.892.041-.041-.892a2.381 2.381 0 1 0-4.758.222l.263 5.65 5.65-.263zm1.101 7.014-.032.688-.688-.032a1.836 1.836 0 1 0-.17 3.668l4.355.202.202-4.355a1.835 1.835 0 1 0-3.667-.171z"></path><circle cx="44.5" cy="4.188" r="1.25"></circle><circle cx="57" cy="7.911" r="1.25"></circle><circle cx="45.75" cy="21.661" r="1.25"></circle><path d="m11.771 16.398 5.65.263.263-5.65a2.382 2.382 0 0 0-4.758-.222l-.041.892-.892-.041a2.38 2.38 0 1 0-.222 4.758zm-4.769 7.184.202 4.355 4.355-.202a1.836 1.836 0 1 0-.17-3.668l-.688.032-.032-.688a1.836 1.836 0 1 0-3.667.171z"></path><circle cx="19.5" cy="4.188" r="1.25"></circle><circle cx="7" cy="7.911" r="1.25"></circle><circle cx="18.25" cy="21.661" r="1.25"></circle><path d="M32 27.313c2.412 0 4.375-1.963 4.375-4.375S34.412 18.563 32 18.563s-4.375 1.963-4.375 4.375 1.963 4.375 4.375 4.375zm0-7.5c1.723 0 3.125 1.402 3.125 3.125S33.723 26.063 32 26.063s-3.125-1.402-3.125-3.125 1.402-3.125 3.125-3.125zM3.899 53.563c.32 4.189 3.831 7.5 8.101 7.5h40c4.27 0 7.781-3.311 8.101-7.5H3.899z"></path><path d="M6.383 52.313h5.003c.218-7.089 4.122-13.649 10.301-17.232a.625.625 0 0 1 .627 1.081c-5.795 3.359-9.461 9.505-9.678 16.15h44.982c-.333-13.842-11.696-25-25.617-25S6.716 38.47 6.383 52.313zm25.617-20c2.673 0 5.275.506 7.735 1.505a.625.625 0 1 1-.47 1.158A19.217 19.217 0 0 0 32 33.563a.625.625 0 0 1 0-1.25z"></path></svg>

                        </div>
                        <h4 class="detail-box-titles">{{ $t('cuisine_type') }}</h4>
                        <p class="detail-info-boxIcon cuisine-box">
                            <span>{{ set_cuisine_text(place.cuisines) }}</span>
                        </p>
                    </div>
                </div>
                <div class="col-6 md:col-4 lg:col-4">
                    <div class="detail-info-box">
                        <div class="detail-info-boxIcon">
                            <i class="pi pi-star-fill" style="font-size: 1.5rem;color: #0091fb;"></i>
                            <i class="pi pi-star-fill" style="font-size: 1.3rem;color: #0091fb;"></i>
                            <i class="pi pi-star-fill" style="font-size: 1.5rem;color: #0091fb;"></i>
                        </div>
                        <h4 class="detail-box-titles"> {{ $t('average_rating') }} </h4>
                        <p class="detail-info-boxIcon">
                            <span>{{ (place.ratings != null ? place.ratings.rating+".0" : "") }} ({{place.totalreviews != null ? place.totalreviews.total : $t('no') }} {{ $t('reviews') }})</span> 
                            <span>{{ get_place_type_name(place.category) }} {{ $t('rating') }}</span>
                        </p>
                    </div>
                </div>

                <div class="col-6 md:col-4 lg:col-4">
                    <div class="detail-info-box">
                        <div class="detail-info-boxIcon">
                            <svg width="30" height="30" viewBox="0 0 26.248 37.497">
                                <path id="Icon_material-location-on" data-name="Icon material-location-on" d="M20.624,3A13.114,13.114,0,0,0,7.5,16.124C7.5,25.967,20.624,40.5,20.624,40.5s13.124-14.53,13.124-24.373A13.114,13.114,0,0,0,20.624,3Zm0,17.811a4.687,4.687,0,1,1,4.687-4.687A4.689,4.689,0,0,1,20.624,20.811Z" transform="translate(-7.5 -3)" fill="#3591ff"/>
                            </svg>
                        </div>
                        <h4 class="detail-box-titles">{{$t('location')}}</h4>
                        <p class="detail-info-boxIcon">
                            <span>{{place.address != null ? place.address+',' : ''}} {{ place.area == null ? "" : place.area.name+", " }} {{ place.city == null ? "" : place.city.name }} {{ place.country == null ? "" : place.country.name }}</span>
                        </p>
                    </div>
                </div>
                <div class="col-6 md:col-4 lg:col-4">
                    <div class="detail-info-box">
                        <div class="detail-info-boxIcon">
                            <svg width="30" height="30" viewBox="0 0 37.56 37.497">
                            <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                <path id="Path_3688" data-name="Path 3688" d="M45.249,43.749H39V47.5h8.124A1.875,1.875,0,0,0,49,45.624V35h-3.75Z" transform="translate(-27.058 -25.626)" fill="#0091f7"/>
                                <path id="Path_3689" data-name="Path 3689" d="M38.707,57.5a18.748,18.748,0,0,0,17.1-26.429A6.249,6.249,0,0,1,52.325,32.5a15,15,0,1,1-6.687-7.018,6.249,6.249,0,0,1,1.575-3.406A18.748,18.748,0,1,0,38.707,57.5Z" transform="translate(-19.891 -20)" fill="#0091f7"/>
                                <ellipse id="Ellipse_13" data-name="Ellipse 13" cx="2.172" cy="3.258" rx="2.172" ry="3.258" transform="translate(28.848 3.646)" fill="#0091f7"/>
                            </g>
                            </svg>
                        </div>
                        <h4 class="detail-box-titles">{{ $t('opening_time') }}</h4>
                        <p class="detail-info-boxIcon" v-html="showTimings()">
                                                   
                        </p>
                    </div>
                </div>
                <div class="col-6 md:col-4 lg:col-4">
                    <div class="detail-info-box">
                        <div class="detail-info-boxIcon">
                            <span class="currency-icons" v-html="currency_icons(place)"></span>
                        </div>
                        <h4 class="detail-box-titles">{{ $t('average_price_for_two') }}</h4>
                        <p class="detail-info-boxIcon">
                            <span>{{ place.average_price }}</span> 
                            <!-- <span>(700)</span> -->
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Overview Section -->
        <div class="restaurents-section-area restaurentsDetail-overview-area section-mt mb-5" id="overview" v-if="place.description != null">
            <h2 class="section-title-text">{{$t('overview')}}</h2>

            <p class="overview-ino-text" v-html="place.description">
               
            </p>
        </div>
        <!-- / Overview Section -->
        
        <!-- Vibes & Amenities -->
        <div class="restaurents-section-area section-mt mb-5" v-if="place.ameneties.length>0 || place.vibes.length>0">
            <h2 class="section-title-text">{{ $t('vibes_and_ameneties') }}</h2>
            
            <div class="grid m-0">
                <div class="col-6 md:col-3 lg:col-2" v-for="item in place.ameneties" :key="item">
                    <div class="column-item-box">
                        <div class="icon-box-area">
                            <img class="" src="../assets/Icons/beach.svg">
                        </div>
                        <p>{{ get_filter_name(item.filtername) }}</p>
                    </div>
                </div>
                <div class="col-6 md:col-3 lg:col-2" v-for="item in place.vibes" :key="item">
                    <div class="column-item-box">
                        <div class="icon-box-area">
                            <img class="" src="../assets/Icons/beach.svg">
                        </div>
                        <p>{{ get_filter_name(item.filtername)}}</p>
                    </div>
                </div>
            </div>
    
        </div>
        <!-- / Vibes & Amenities -->
        
        <!-- Hotel Menu Section -->
        <div class="restaurents-section-area section-mt mb-5" id="menu">
            <h2 class="section-title-text">{{ $t('menu') }}</h2>

            <div class="hotel-menu-area">
                <div class="grid m-0">
                    <div class="col-12 md:col-3 lg:col-3">
                        <a :href="place.menu_url" v-if="place.menu_url != null" target="_blank"><div class="hotel-menu-area-box"><img class="" src="../assets/images/black-menu.png"></div></a>
                        <div v-else class="card-info-area pl-0 pr-0">
                            <div class="listing-card-titles">
                                <span class="restaurents-name restaurent-menu-mainBox">
                                    <div class="">
                                        {{ $t('menu_not_available') }}
                                    </div>
                                </span>
                            </div>
                            <!-- <div class="listing-card-subtitle">
                                <span class="food-item">5 Pages</span>
                                <span class="review-label">Download Now</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Hotel Menu Section -->

        <!-- Place Events Section Area -->
        <div class="restaurens-box restaurents-section-area" id="place-events">
            <h2 class="section-title-text">{{ $t('events') }}</h2>
            <div class="grid m-0">

                <div class="all-restaurents-leftBox col-12 md:col-12 lg:col-12">
                    <!-- <div class="flex align-items-center justify-content-between mb-5"> -->
                        <!-- <h2 class="section-title-text mb-0 margin-top-0">{{ place.name }} Events</h2> -->
                    <!-- </div> -->

                    <div class="grid ml-0 mr-0 restaurents-grid-layout" v-if="place.events.length>0">
                        <div class="col-12 md:col-4 lg:col-4" v-for="item in place.events" :key="item">
                            <div class="restaurents-box-items">
                                <div class="restaurents-box-image">
                                    <i :class="'pi pi-heart-fill love-sign-restaurent event-id-'+item.id"  @click="addremovelove($event, item.id)" />
                                    <i class="pi pi-share-alt share-sign" />
                                    <swiper
                                        :slides-per-view="4"
                                        :space-between="10"
                                        :modules="modules"
                                        :breakpoints="{
                                          '@0.00': { slidesPerView: 1, spaceBetween: 0,},
                                          '@0.75': { slidesPerView: 1, spaceBetween: 0,},
                                          '@1.00': { slidesPerView: 1, spaceBetween: 0,},
                                          '@1.50': { slidesPerView: 1, spaceBetween: 0,},
                                        }"
                                        @swiper="onSwiper"
                                        @slideChange="onSlideChange"
                                        :pagination="{ clickable: true}"
                                      >
                                        <swiper-slide v-show="item.photos.length>0" v-for="photos in item.photos" :key="photos">
                                          <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                              <div class="mb-3">
                                                <router-link :to="'/event-detail/' + item.id">   
                                                  <img :src="photos.image_path"  class="w-6 shadow-2" />
                                                </router-link>
                                              </div>
                                          </div>
                                        </swiper-slide>
                                        <swiper-slide v-if="item.photos.length==0">
                                          <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                              <div class="mb-3">
                                                <router-link :to="'/event-detail/' + item.id">   
                                                  <img v-bind:src="require('/src/assets/images/restaurent-sliders/slider2.jpg')"  class="w-6 shadow-2" />
                                                </router-link>
                                              </div>
                                          </div>
                                        </swiper-slide>
                                        <swiper-slide v-show="item.photos.length==0">
                                          <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                              <div class="mb-3">
                                                <router-link :to="'/event-detail/' + item.id">   
                                                  <img v-bind:src="require('/src/assets/images/restaurent-sliders/slider3.jpg')"  class="w-6 shadow-2" />
                                                </router-link>
                                              </div>
                                          </div>
                                        </swiper-slide>
                                      </swiper>

                                    <div class="restaurent-logo">
                                    <img v-bind:src="require('/src/assets/images/logo/amaken-logo.svg')">
                                    </div>
                                </div>
                                <div class="card-info-area card-info-restaurent-area">
                                    <div class="restaurent-info-right">
                                    <div class="listing-card-title">
                                        <span class="restaurents-name event-name">{{ get_event_name(item) }}</span>
                                    </div>
                                    <div class="listing-card-subtitle">
                                        <span class="event-time"><strong>{{ $t('starting') }} :  </strong>{{ item.price }}</span>
                                    </div>
                                    <div class="listing-card-subtitle">
                                        <span class="event-time">{{item.currentdate}} {{ item.start_time }} </span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="card-info-area pl-0 pr-0 grid ml-0 mr-0">
                        <div class="listing-card-titles col-12">
                            <span class="restaurents-name">{{ $t('no_events_available') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Place Events Section Area -->

        <!-- Restaurant Gallary Section -->
        <div class="restaurents-section-area section-mt mb-5" id="gallery">
            <h2 class="section-title-text">{{$t('gallery')}}</h2>

            <div class="restaurents-gallery-area" v-if="place.galleryphotos.length > 0">
                <galleria-prime v-model:activeIndex="activeIndex" v-model:visible="displayCustom" :value="place.galleryphotos" :responsiveOptions="responsiveOptions" :numVisible="7"
                    containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false">
                    <template #item="slotProps">
                        <img :src="slotProps.item.image_path" :alt="slotProps.item.alt" style="width: 100%; display: block" />
                    </template>
                </galleria-prime>

                <div v-if="place.galleryphotos.length > 0" class="grid">
                    <div  v-for="(image, index) of place.galleryphotos" :key="index"  class="col-2 restaurents-gallery-images">
                        <img :src="image.image_path" :alt="image.image_path" style="cursor: pointer" @click="imageClick(index)" />
                    </div>
                </div>
            </div>
            <div v-else class="card-info-area pl-0 pr-0">
                <div class="listing-card-titles col-12">
                    <span class="restaurents-name">{{$t('photos_not_available')}}</span>
                </div>
            </div>

        </div>
        <!-- / Restaurant Gallary Section -->

        <!-- Video Section -->
        <div class="restaurents-section-area restaurents-section-area-video section-mt mb-5">
            <h2 class="section-title-text">{{$t('video')}}</h2>

            <!-- <iframe v-if="place.socialmedia!=null && place.socialmedia.youtube!=null" width="600" height="338" :src="place.socialmedia.youtube" frameborder="0" allowfullscreen></iframe> -->

            <div v-if="place.videos!=null && place.videos.length>0" class="grid ml-0 mr-0">
                <swiper :slides-per-view="4" :space-between="10" setWrapperSize="ture" :navigation="true" :modules="modules"
                :breakpoints="{
                    '@0.00': {slidesPerView: 1,spaceBetween: 0},
                    '@0.75': {slidesPerView: 2,spaceBetween: 0},
                    '@1.00': {slidesPerView: 3,spaceBetween: 0},
                    '@1.50': {slidesPerView: 4,spaceBetween: 0},
                }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                >
                    <swiper-slide v-for="video in place.videos" :key="video">
                        <div class="grid ml-0 mr-0">
                        <div class="restaurents-main-box col-12 md:col-12 lg:col-12">
                            <div class="restaurents-box-items restaurents-box-items-videos">
                                <iframe width="600" height="338" :src="video.video_link" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </div>
                        </div>
                    </swiper-slide>

                    
                </swiper>
            </div>

            <div v-else class="card-info-area pl-0 pr-0">
                <div class="listing-card-titles col-12">
                    <span class="restaurents-name">{{$t('video_not_available')}}</span>
                </div>
            </div>
        </div>
        <!-- / Video Section -->

        <!-- Social Section -->
        <div class="grid m-0" v-if="place.socialmedia!=null">
            <div class="col-12">
                <div class="social-widget social-widget-detail">
                    <div class="widget-social-icon">
                        <a @click="social_media_logs('tiktok', place.socialmedia.tiktok)" target="_blank" v-if="place.socialmedia.tiktok!=null" href="#">
                            <div class="social-icon-circle">
                                <img src="../assets/Icons/tiktokIcon.svg">
                            </div>
                            <span>{{place.socialmedia.tiktok}}</span>
                        </a>
                        <a @click="social_media_logs('facebook')" target="_blank" v-if="place.socialmedia.facebook!=null" :href="place.socialmedia.facebook">
                            <div class="social-icon-circle">
                            <img src="../assets/Icons/footerFbIcon.svg">
                            </div>
                            <span>{{place.socialmedia.facebook}}</span>
                        </a>
                        <a @click="social_media_logs('snapchat')" target="_blank" v-if="place.socialmedia.snapchat!=null" :href="place.socialmedia.snapchat">
                            <div class="social-icon-circle">
                            <img src="../assets/Icons/snapChatIcon.svg">
                        </div>
                            <span>{{ place.socialmedia.snapchat }}</span>
                            
                        </a>
                        <a @click="social_media_logs('instagram')" target="_blank" v-if="place.socialmedia.instagram!=null" :href="place.socialmedia.instagram">
                            <div class="social-icon-circle">
                            <img src="../assets/Icons/instaIcon.svg">
                        </div>
                            <span>{{ place.socialmedia.instagram }}</span>
                        </a>
                        <a @click="social_media_logs('linkedin')" target="_blank" v-if="place.socialmedia.linkedin!=null" :href="place.socialmedia.linkedin">
                            <div class="social-icon-circle">
                            <img src="../assets/Icons/linkdinIcon.svg">
                        </div>
                            <span>{{ place.socialmedia.linkedin }}</span>
                        
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Social Section -->

        <!-- Location Section -->
        <div class="restaurents-section-area section-mt mb-5" id="location">
            <h2 class="section-title-text">{{ $t('location_on_map') }}</h2>

            <div class="hotel-location-area" v-if="place.embed_map_iframe != null">
                <div class="grid m-0" v-html="place.embed_map_iframe">
                    
                </div>
            </div>
            <div v-else class="card-info-area pl-0 pr-0">
                <div class="listing-card-titles">
                    <span class="restaurents-name">{{$t('location_not_available')}}</span>
                </div>
            </div>
        </div>
        <!-- / Location Section -->

        <!-- Restaurants Reviews Section -->
        <div class="restaurents-section-area restaurents-review-area section-mt mb-5" id="reviews">
            <!-- <span v-if="place.ratings != null" class="rating-label"><rating-prime :stars="1" :cancel="false" />{{place.ratings.rating+".0"}}</span> -->
            <h2 class="section-title-text mb-2">{{ $t('rating_and_reviews') }}</h2>

            <div class="hotel-menu-area">
                <div class="grid m-0 align-items-start">
                    <div class="col-12 md:col-6 lg:col-6 pt-0 pb-0">
                        <div class="flex-box">
                            <span class="review-number">{{place.ratings.rating+'.0'}}</span>
                            <span class="review-stars"> <rating-prime  v-model="place.ratings.rating" readonly :cancel="false" /></span>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                        <div class="review-progressbar">
                            <p class="review-progress-text">{{ $t('total_review_count') }}</p>
                            <div class="review-progress-bar-container">
                                <div class="progressBar-row">
                                    <div><span>5</span> {{$t('stars')}}</div>
                                    <div class="progressBar-review"><progressBar-prime :value="each_star_rating[0]/total_count*100"></progressBar-prime></div>
                                    <div>{{ each_star_rating[0] }}</div>
                                </div>
                                <div class="progressBar-row">
                                    <div><span>4</span> {{$t('stars')}}</div>
                                    <div class="progressBar-review"><progressBar-prime :value="each_star_rating[1]/total_count*100"></progressBar-prime></div>
                                    <div>{{ each_star_rating[1] }}</div>
                                </div>
                                <div class="progressBar-row">
                                    <div><span>3</span> {{$t('stars')}}</div>
                                    <div class="progressBar-review"><progressBar-prime :value="each_star_rating[2]/total_count*100"></progressBar-prime></div>
                                    <div>{{ each_star_rating[2] }}</div>
                                </div>
                                <div class="progressBar-row">
                                    <div><span>2</span> {{$t('stars')}}</div>
                                    <div class="progressBar-review"><progressBar-prime :value="each_star_rating[3]/total_count*100"></progressBar-prime></div>
                                    <div>{{ each_star_rating[3] }}</div>
                                </div>
                                <div class="progressBar-row">
                                    <div><span>1</span> {{$t('stars')}}</div>
                                    <div class="progressBar-review"><progressBar-prime :value="each_star_rating[4]/total_count*100"></progressBar-prime></div>
                                    <div>{{ each_star_rating[4] }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid m-0 align-items-start">
                    <!-- <div class="col-12 pt-0 pb-0">
                        <div class="add-review-box">
                            <div class="add-review-ratings-box">
                                <span>Select Rating <rating-prime @change="convert_into_decimal" v-model="rating" :cancel="false" /></span>
                            </div>
                        </div>
                        <div class="add-review-input">
                            <inputText-prime v-model="comment" type="text" placeholder="Leave a feedback about your experience…" />
                        </div>
                        <message-prime severity="error" v-if="validationErrors.length">{{validationErrors[0]}}</message-prime>
                        <message-prime severity="success" v-if="success_message != null">{{success_message}}</message-prime>
                        <div class="submit-review-btn">
                            <button-prime @click="submit_review" :loading="review_button_loader" label="Submit Review" rounded />
                        </div>
                    </div> -->
                    <Reviewlisting></Reviewlisting>
                </div>
                <!-- <div class="card">
                    <paginator-prime v-model:first="first" :rows="10" :totalRecords="50"></paginator-prime>
                </div> -->
            </div>
        </div>
        <!-- / Location Section -->
    
        <!-- / Restaurents Section Area -->
        </main>
    </div>

    <FooterView />
    <div class="card flex justify-content-center text-center">
      <dialog-prime class="login-popup-modal" v-model:visible="login_alert_modal" modal header="" :style="{ width: '50vw', textAlign : 'center' }">
          <p>{{ $t('login_your_ac_first') }}</p>
          <router-link to="/signin"><button-prime :label="$t('signin')" class="" severity="primary" rounded /></router-link>
          <router-link to="/signin"><button-prime :label="$t('signup')" class="ml-2" severity="primary" rounded /></router-link>
      </dialog-prime>
    </div>

</template>
  
  
<script setup>
  import { ref } from "vue";
  
    const activeIndex = ref(0);
    const displayCustom = ref(false);

    const imageClick = (index) => {
        activeIndex.value = index;
        displayCustom.value = true;
    };
</script>

<script>
import HeaderView from '/src/components/Header/HeaderView.vue'
import FooterView from '/src/components/Footer/FooterView.vue'
import Reviewlisting from '/src/components/ReviewListing.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import axios from 'axios'
import $ from 'jquery'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Pagination } from 'swiper';

// import required modules
import { Navigation } from 'swiper';

export default {
    components: {
        HeaderView,
        FooterView,
        Swiper,
        SwiperSlide,
        Reviewlisting
    },
    data() {
        return {
            modules: [Navigation, Pagination],
            responsiveOptions : [{breakpoint: '1199px',numVisible: 1,numScroll: 3},{breakpoint: '991px',numVisible: 1,numScroll: 1},{breakpoint: '767px',numVisible: 1,numScroll: 1}],
            place : {
                allow_booking : 0,
                coverphoto : {image_path : ''},
                category : {name : ''},
                cuisines : {},
                vibes : {},
                ameneties : {},
                galleryphotos : [],
                ratings : {
                    rating : 0
                },
                embed_map_iframe : '',
                totalreviews : {},
                current_day_timings : [],
                events : [],
                socialmedia : null
            },
            rating : '0.0',
            alldays : [],
            login_alert_modal : false,
            each_star_rating : [],
            total_count : 0,
            first : 0,
            comment : '',
            validationErrors : [],
            success_message : null,
            review_button_loader : false,
            bannerImages : [],
            heartValue : false,
            like_value : 0,
            like_event : 0,
            liked_events : [],
            timingsBoxHtml : '',
            liked_place : [],
            heartValuePlace : false,
            like_place_value : 0,
            like_place : 0,
        }
    },
    mounted() {
      this.get_place();
    },
    methods: {
        get_filter_name : function(item) {
            if (item==null) {
                return '';
            }
            var filter_name = item.name;
            if (this.amaken_language == 'AR') {
                filter_name = item.arabic_name;
            }
            if(filter_name == null) {
                filter_name = item.name;
            }
            return filter_name;
        },
        addremoveloveinplace : function(event, place_id) {
            this.like_place = place_id;
            if (event.target.classList.contains('heartActive')) {
                this.like_place_value = 0;
                this.heartValuePlace = false;
            } else {
                this.like_place_value = 1;
                this.heartValuePlace = true;
            }
            axios.get(this.api_url+'submit_like',{
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                },
                params : {
                like : this.like_place_value,
                place : this.like_place
                } 
            }).then((response) => {
                if (response.data.status == false) {
                this.login_alert_modal = true;
                } else {
                if (!this.heartValuePlace) {
                    event.target.classList.remove('heartActive');
                } else {
                    event.target.classList.toggle('heartActive');
                    
                }
                }
            });
            },
        get_liked_place : function() {
            axios.get(this.api_url+'liked_place',{
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                },
                params : {
                    place : this.place.id
                }
            }).then((response) => {
                if (response.data.status == true) {
                    this.liked_place = response.data.data;
                    if(this.liked_place != null) 
                    $('.place-id-'+this.liked_place.place_id).addClass('heartActive');
                }
            });
        },
        convert_am_pm_translation : function(timeString) {
            if (this.amaken_language == 'AR') {
                timeString = timeString.replace('AM', 'صباحًا');
                timeString = timeString.replace('PM', 'مساءً');
            }
            return timeString;
        },
        get_day_name : function(item) {
            var day_name = item.name;
            if (this.amaken_language == 'AR') {
                day_name = item.arabic_name;
            }
            if(day_name == null) {
                day_name = item.name;
            }
            return day_name;
        },
        get_place_type_name : function(item) {
            var place_type_name = item.name;
            if (this.amaken_language == 'AR') {
                place_type_name = item.arabic_name;
            }
            if(place_type_name == null) {
                place_type_name = item.name;
            }
            return place_type_name;
        },
        get_place_name : function(item) {
            var place_name = item.name;
            if (this.amaken_language == 'AR') {
                place_name = item.arabic_name;
            }
            if(place_name == null) {
                place_name = item.name;
            }
            return place_name;
        },
        get_event_name : function(item) {
            var event_name = item.name;
            if (this.amaken_language == 'AR') {
                event_name = item.arabic_name;
            }
            if(event_name == null) {
                event_name = item.name;
            }
            return event_name;
        },
        currency_icons : function(place) {
            var currencyIcons = '<i class="pi pi-dollar"></i><i class="pi pi-dollar"></i><i class="pi pi-dollar"></i>';
            var price = parseFloat(place.average_price);
            if(price < 100) {
            currencyIcons = '<i class="pi pi-dollar"></i><i class="pi pi-dollar"></i><i class="pi pi-dollar" style="color:lightgray !important"></i>';
            }
            if(price < 10) {
            currencyIcons = '<i class="pi pi-dollar"></i><i class="pi pi-dollar" style="color:lightgray !important"></i><i class="pi pi-dollar" style="color:lightgray !important"></i>';
            }
            return currencyIcons;
        },
        social_media_logs : function(name, url) {
            this.place.id
            axios.get(this.api_url+'social_media_logs', {
                params: { 
                    id: this.place.id,
                    social_media : name,
                } 
            })
            window.open(url);
        },
        call_logs : function () {
            this.place.id
            axios.get(this.api_url+'call_logs', {
                params: { 
                    id: this.place.id,
                } 
            })
        },
        book : function(place) {
            // axios.get(this.api_url+'is_user_loggedin', {
            //     headers: { 
            //         'Authorization': 'Bearer '+localStorage.getItem('token')
            //     }
            // }).then((response) => {
            //     if (response.data.status == false) {
            //         this.login_alert_modal = true;
            //     } else {
            //         this.$router.push({
            //             path: '/'+place.parentplace.slug+'/reservation',
            //             query : {
            //                 branch : place.slug
            //             }
            //         });
            //     }
            // }).catch((error) => {
            //     this.review_button_loader = false;
            //     if(error.response.status == 401) {
            //         this.validationErrors.push(error.response.data.errors);
            //     }
            // });
            this.$router.push({
                path: '/'+place.parentplace.slug+'/reservation',
                query : {
                    branch : place.slug
                }
            });
        },
        showTimings : function() {
            var text = '';
            var activeDays = [];
            var inactiveDays = [];
            var matched_day_id = '';
            if (this.place.current_day_timings.length > 0) {
                for (let i = 0; i < this.alldays.length; i++) {
                    for (let j = 0; j < this.place.current_day_timings.length; j++) {
                        if(this.alldays[i].id == this.place.current_day_timings[j].day_id){
                            activeDays.push(this.place.current_day_timings[j]);
                            matched_day_id = this.place.current_day_timings[j].day_id;
                        }
                    }

                    if(matched_day_id != this.alldays[i].id) {
                        inactiveDays.push(this.alldays[i]);
                    }
                }
            }
            

            if(activeDays.length > 0){
                if (this.get_day_name(activeDays[0].currentday) == this.get_day_name(activeDays[activeDays.length-1].currentday)) {
                    text += '<span>'+this.get_day_name(activeDays[0].currentday)+'</span><span>'+this.convert_am_pm_translation(activeDays[0].standard_start_time)+' '+this.$t('to')+' '+this.convert_am_pm_translation(activeDays[0].standard_end_time)+'</span>';
                } else {
                    text += '<span>'+this.get_day_name(activeDays[0].currentday)+' - '+this.get_day_name(activeDays[activeDays.length-1].currentday)+'</span><span>'+this.convert_am_pm_translation(activeDays[0].standard_start_time)+' '+this.$t('to')+' '+this.convert_am_pm_translation(activeDays[0].standard_end_time)+'</span>';
                }
            } else {
                text += '-';
            }
            if(inactiveDays.length > 0) { 
                text += '<span>';
                text += this.$t('closed_on')+' : ';    
                for (let k = 0; k < inactiveDays.length; k++) {
                    
                    text += this.get_day_name(inactiveDays[k]);
                    if (inactiveDays.length-1 != k) {
                        text += ", ";
                    }
                }
                text += '</span>';
            }    

            return text;
        },
        openedOrClosed : function(list) {
            var text = false;
            for (let i = 0; i < list.length; i++) {
                if (list[i].currentday.name == list[i].currentdayname) {
                var startTime = list[i].start_time;
                var endTime = list[i].end_time;
                var currentDate = new Date()   
                var startDate = new Date(currentDate.getTime());
                startDate.setHours(startTime.split(":")[0]);
                startDate.setMinutes(startTime.split(":")[1]);
                startDate.setSeconds(startTime.split(":")[2]);
                var endDate = new Date(currentDate.getTime());
                endDate.setHours(endTime.split(":")[0]);
                endDate.setMinutes(endTime.split(":")[1]);
                endDate.setSeconds(endTime.split(":")[2]);
                var valid = startDate < currentDate && endDate > currentDate;
                if(valid) {
                    text = true;
                    return text;
                }
                }
            }
            return text;
        },
        get_liked_events : function() {
            axios.get(this.api_url+'liked_events_of_place',{
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('token')
            },
            params : {
                place : this.place.id
            }
            }).then((response) => {
            if (response.data.status == true) {
                this.liked_events = response.data.data; 
                $.each(this.liked_events, function(index, value) {
                    $('.event-id-'+value.event_id).addClass('heartActive');
                })
            }
            });
        },
        addremovelove : function(event, event_id) {
            this.like_event = event_id;
            if (event.target.classList.contains('heartActive')) {
            this.like_value = 0;
            this.heartValue = false;
            } else {
            this.like_value = 1;
            this.heartValue = true;
            }
            axios.get(this.api_url+'submit_like_event',{
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('token')
            },
            params : {
                like : this.like_value,
                event : this.like_event
            } 
            }).then((response) => {
            if (response.data.status == false) {
                this.login_alert_modal = true;
            } else {
                if (!this.heartValue) {
                event.target.classList.remove('heartActive');
                } else {
                event.target.classList.toggle('heartActive');
                }
            }
            });
        },
        submit_review : function() {
            this.review_button_loader = true;
            this.validationErrors = [];
            this.success_message = null;
            axios.get(this.api_url+'submit_review', {
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                },
                params: { 
                    place: this.$route.params.id, 
                    comment : this.comment
                } 
            }).then((response) => {
                this.review_button_loader = false;
                if (response.data.status == false) {
                    this.login_alert_modal = true;
                } else {
                    this.success_message = response.data.message;
                }
            }).catch((error) => {
                this.review_button_loader = false;
                if(error.response.status == 401) {
                    this.validationErrors.push(error.response.data.errors);
                }
            });
        },
        get_current_user_rating : function() {
            axios.get(this.api_url+'get_user_rating', {
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                },
                params: { 
                    place: this.$route.params.id 
                } 
            }).then((response) => {
                if (response.data.status == true) {
                    this.rating = response.data.rating+'.0' 
                }
            });
        },
        convert_into_decimal : function() {
            this.rating = this.rating+'.0';
            axios.get(this.api_url+'submit_rating', {
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                },
                params: { 
                    place: this.$route.params.id, 
                    rating : this.rating
                } 
            }).then((response) => {
                if (response.data.status == false) {
                    this.login_alert_modal = true;
                }
            });
        },
        set_cuisine_text : function(cuisinedata) {
            var text = '';
            for (let i = 0; i < cuisinedata.length; i++) {
                if(this.amaken_language == 'AR') {
                text += cuisinedata[i].filtername.arabic_name;
                } else if(this.amaken_language == 'EN') {
                text += cuisinedata[i].filtername.name;
                }
                
                if (cuisinedata.length-1 != i) {
                text += ", ";
                }
                
            }
            return text;
        },
        get_place : function() {
            const searchURL = new URL(window.location);
            var value = searchURL.searchParams.get('search') == null ? '' : searchURL.searchParams.get('search');
            axios.get(this.api_url+'get_place_details', {
                params: { 
                    id: this.$route.params.id,
                    search : value
                } 
            }).then((response) => {
                this.place = response.data.place;
                this.each_star_rating = response.data.ratings;
                this.alldays = response.data.all_days;
                if (this.place.events.length > 0) {
                    this.get_liked_events();
                }
                if (this.place.coverphotos != null) {
                    this.bannerImages.push(this.place.coverphotos)
                }
                if (this.place.galleryphotos != null) {
                    for (let i = 0; i < this.place.galleryphotos.length; i++) {
                        this.bannerImages.push(this.place.galleryphotos[i])
                    }
                } 
                if (this.bannerImages.length == 0) {
                    this.bannerImages.push('/assets/images/sliders/slide-1.png');
                }
                if(this.place.ratings == null) {
                    this.place.ratings = {
                        rating : 0
                    }
                }
                this.total_count = this.each_star_rating[0]+this.each_star_rating[1]+this.each_star_rating[2]+this.each_star_rating[3]+this.each_star_rating[4];
                this.get_liked_place();
                this.get_current_user_rating();
            });
        }
    }
};
</script>

<style>
.widget-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.widget-social-icon {
    margin-top: 0.6rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.widget-social-icon .social-icon-circle {
  color: #000;
    font-size: 16px;
    margin-right: 15px;
    background: #fff !important;
    width: 40px;
    height: 40px;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
}
.widget-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.widget-social-icon a {
    display: flex;
    color: #000;
    align-items: center;
    margin-bottom: 0.5rem;
}

.widget-social-icon a span {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
}

.select-time-chips a {
    color: #fff;
}
</style>
  
  
  