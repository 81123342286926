<template>
    <div class="signin-wrapper">
        <div class="grid m-0">
            <div class="col-12 md:col-6 lg:col-6 signIn-form-leftArea">
                <router-link to="/">
                <div class="logo-area">
                    <img class="site-logo-amaken" src="../assets/images/logo/amaken-logo.svg">
                </div>
                </router-link>

               
                <div class="sign-form-area" id="signIn-from">
                    <message-prime severity="error" v-if="validationErrors.length">{{validationErrors[0]}}</message-prime>
                    <message-prime severity="success" v-if="success_message != null">{{success_message}}</message-prime>
                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                            <inputText-prime class="form-input-fields" v-model="email" :placeholder="$t('email')" />
                        </span>
                    </div>

                    
                    <div class="form-fields-main">
                        <button-prime @click="sendOtp" :loading="loading" :label="$t('send_code')" rounded />
                    </div>
                </div>

                
            </div>
            <div class="col-12 md:col-6 lg:col-6 signIn-Info-rightArea">
                <div class="welcome-text-sign">
                    <h2>{{ $t('welcome_to_amaken') }}</h2>
                    <!-- <p class="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    
    data() {
        return {
           email : null,
           loading : false,
           validationErrors : [],
           success_message : null
        }
    },
    mounted() {
       
    },
    methods : {
        sendOtp : function () {

            this.loading = true;
            this.success_message = null;
            this.validationErrors = [];
            axios.get(this.api_url+'send_otp',{
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                },
                params : {
                    email : this.email,
                    lang : this.amaken_language
                } 
            }).then((response) => {
                this.loading = false;
                if(response.status == 200) {
                    this.success_message = response.data.message;
                    this.$router.push({
                         path : 'verifyotp/'+response.data.id
                    })
                }
            }).catch((error) => {
                this.loading = false;
                if(error.response.status == 401) {
                    this.validationErrors.push(error.response.data.errors);
                }
            });

        }
    }
}
</script>

