<template>
    <div class="reservation-content-container-flexible">

        <reservationHeader />

        <div class="reservation-banner-area">
            <div class="bg-layer"></div>
        </div>

        <div class="reservation-logo-area">
            <!-- <img src="/src/assets/images/reservation-templates/logo-default.svg"> -->
        </div>

        <div class="reservation-content-form-element" v-if="showReservationForm">
            <div class="form-element-fields">
                <div class="form-group">
                    <label class="form-group-label">Full Name</label>
                    <span class="form-field-inner">
                        <span class="form-field-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                        </span>
                        <inputText-prime class="form-element-field" type="text" />
                    </span>
                </div>

                <div class="form-group">
                    <label class="form-group-label">Phone Number</label>
                    <span class="form-field-inner">
                        <span class="form-field-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                            </svg>
                        </span>
                        <inputText-prime class="form-element-field" type="number" />
                    </span>
                </div>
                
                <div class="form-group">
                    <label class="form-group-label form-group-label-flex">
                        Select Date
                        <div class="select-date-dropdown">
                            <span class="time-zone-slot">
                                <dropdown-prime v-model="selectedYear" :options="years" optionLabel="name" placeholder="" class="w-full md:w-14rem" />
                            </span>
                            <span class="icon-area">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-event" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                                <path d="M16 3l0 4" />
                                <path d="M8 3l0 4" />
                                <path d="M4 11l16 0" />
                                <path d="M8 15h2v2h-2z" />
                                </svg>
                            </span>
                        </div>
                    </label>
                    <span class="form-field-inner swiper-outer-container">
                        <swiper 
                            :slides-per-view="5" 
                            :space-between="10" 
                            :modules="modules" 
                            setWrapperSize="ture"
                            :loop="false"
                            
                            :navigation="{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next'
                            }"
                            :pagination="pagination" 
                            :breakpoints="{
                                '@0.00': {slidesPerView: 3,spaceBetween: 10},
                                '@0.75': {slidesPerView: 3,spaceBetween: 10},
                                '@1.00': {slidesPerView: 4,spaceBetween: 10},
                                '@1.50': {slidesPerView: 5,spaceBetween: 10},
                            }"
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                            >
                                <swiper-slide v-for="(day, index) in daysInMonth" :key="index" :class="{'selected': isCurrentDate(day)}">
                                    <div class="selected-date-item">
                                        <h4 class="h4-first" >{{ day.dayName }}</h4>
                                        <h4> {{ day.date }} </h4>
                                    </div>
                                </swiper-slide>
                        </swiper>
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </span>
                </div>

                <div class="form-group">
                    <label class="form-group-label form-group-label-flex">
                        Timings
                        <div class="select-time-zone">
                            <span class="time-zone-slot"><span>AM</span> <span class="selected">PM</span></span>
                            <span class="icon-area">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clock-hour-3" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                <path d="M12 12h3.5" />
                                <path d="M12 7v5" />
                                </svg>
                            </span>
                        </div>
                    </label>

                    <span class="form-field-inner swiper-outer-container">
                        <swiper 
                            :slides-per-view="5" 
                            :space-between="10" 
                            :modules="modules" 
                            setWrapperSize="ture"
                            :loop="false"
                            
                            :navigation="{
                                prevEl: '.swiper-button-prev-two',
                                nextEl: '.swiper-button-next-two'
                            }"
                            :pagination="pagination" 
                            :breakpoints="{
                                '@0.00': {slidesPerView: 3,spaceBetween: 10},
                                '@0.75': {slidesPerView: 3,spaceBetween: 10},
                                '@1.00': {slidesPerView: 4,spaceBetween: 10},
                                '@1.50': {slidesPerView: 5,spaceBetween: 10},
                            }"
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                            >
                                <swiper-slide>
                                    <div class="selected-date-item">
                                        <h4>1:00</h4>
                                        <h4 class="h4-time">PM</h4>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="selected-date-item">
                                        <h4>2:00</h4>
                                        <h4 class="h4-time">PM</h4>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="selected-date-item">
                                        <h4>3:00</h4>
                                        <h4 class="h4-time">PM</h4>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="selected-date-item">
                                        <h4>4:00</h4>
                                        <h4 class="h4-time">PM</h4>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="selected-date-item">
                                        <h4>5:00</h4>
                                        <h4 class="h4-time">PM</h4>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="selected-date-item">
                                        <h4>6:00</h4>
                                        <h4 class="h4-time">PM</h4>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="selected-date-item">
                                        <h4>7:00</h4>
                                        <h4 class="h4-time">PM</h4>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="selected-date-item">
                                        <h4>8:00</h4>
                                        <h4 class="h4-time">PM</h4>
                                    </div>
                                </swiper-slide>
                        </swiper>
                        <div class="swiper-button-prev-two"></div>
                        <div class="swiper-button-next-two"></div>
                    </span>
                </div>
                
                <div class="form-group">
                    <label class="form-group-label">Guests</label>
                    <span class="form-field-inner">
                        <inputnumber-prime v-model="value" showButtons buttonLayout="" style="width: 3rem" :min="0" :max="99">
                            <template #incrementbuttonicon>
                                <span class="pi pi-chevron-right" />
                            </template>
                            <template #decrementbuttonicon>
                                <span class="pi pi-chevron-left" />
                            </template>
                        </inputnumber-prime>
                    </span>
                </div>

                <div class="form-group mb-0">
                    <button-prime class="continue-now-btn" label="Continue" @click="showReservationBookNowStep()" />
                </div>
            </div>
        </div>

        <div class="reservation-content-form-element" v-if="showReservationFormStepTwo">
            <div class="form-element-fields">
                <div class="form-group">
                    <label class="form-group-label">Choose a branch</label>
                    <span class="form-field-inner">
                        <span class="form-field-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-store" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M3 21l18 0" />
                            <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
                            <path d="M5 21l0 -10.15" />
                            <path d="M19 21l0 -10.15" />
                            <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                            </svg>
                        </span>
                        <dropdown-prime v-model="selectedBranch" :options="branch" optionLabel="name" placeholder="" class="w-full" />
                    </span>
                </div>
                
                <div class="form-group">
                    <label class="form-group-label">Sitting</label>
                    
                    <div class="sitting-box-container">
                        <label class="sitting-box-area" for="indoor">
                            <radioButton-prime v-model="sittingRadioValue" inputId="indoor" name="sittingname" value="indoor1" />
                            <div class="ml-2 inner-info-area">
                                <span class="sittingIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-smoking-no" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M8 13l0 4" />
                                    <path d="M16 5v.5a2 2 0 0 0 2 2a2 2 0 0 1 2 2v.5" />
                                    <path d="M3 3l18 18" />
                                    <path d="M17 13h3a1 1 0 0 1 1 1v2c0 .28 -.115 .533 -.3 .714m-3.7 .286h-13a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h9" />
                                    </svg>
                                </span>
                                <div class="sitting-box-info">
                                    <h4 class="m-0">Indoor</h4>
                                    <p class="m-0">No Smoking</p>
                                </div>
                            </div>
                        </label>

                        <label class="sitting-box-area" for="outdoor">
                            <radioButton-prime v-model="sittingRadioValue" inputId="outdoor" name="sittingname" value="outdoor1" />
                            <div class="ml-2 inner-info-area">
                                <span class="sittingIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-smoking" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M3 13m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
                                    <path d="M8 13l0 4" />
                                    <path d="M16 5v.5a2 2 0 0 0 2 2a2 2 0 0 1 2 2v.5" />
                                    </svg>
                                </span>
                                <div class="sitting-box-info">
                                    <h4 class="m-0">Outdoor</h4>
                                    <p class="m-0">Smoking</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-group-label">Special Request</label>
                    <span class="form-field-inner">
                        <textarea-prime v-model="textareaResize" autoResize rows="5" cols="30" />
                    </span>
                </div>

                <div class="form-group mb-0">
                    <button-prime class="continue-now-btn" label="Book Now" @click="showReservationSuccessStep()" />
                </div>
            </div>
        </div>

        <!-- Reservation Successful Step -->
        <div class="reservation-successful-element" v-if="showReservationSuccess">
            <div class="reservation-success-check">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.027 0.905273C13.9925 0.905273 0.924316 13.9735 0.924316 30.008C0.924316 46.0344 13.9925 59.1107 30.027 59.1107C46.0616 59.1107 59.146 46.0263 59.146 30.008C59.146 13.9735 46.0697 0.905273 30.027 0.905273ZM30.027 55.096C16.2112 55.096 4.95531 43.8076 4.95531 30.008C4.95531 16.1759 16.2112 4.95251 30.027 4.95251C43.851 4.95251 55.1151 16.1759 55.1151 30.008C55.1151 43.8076 43.8592 55.096 30.027 55.096Z" fill="#DD3636"/>
                <path d="M22.5501 28.7728C23.1678 29.9269 24.013 31.3247 24.7119 32.6901C25.4271 34.0798 26.4755 36.0059 26.4755 36.0059C26.4755 36.0059 29.5556 29.6018 31.7661 26.2779C37.4063 17.8177 42.6563 15.8103 42.6563 15.8103C42.6563 15.8103 42.1118 20.3289 42.1687 22.1493C42.2337 24.2299 43.0952 26.6273 43.0952 26.6273C43.0952 26.6273 38.2433 29.4474 33.6435 34.413C30.7421 37.55 26.8412 43.7102 26.8412 43.7102C26.8412 43.7102 23.5498 39.7036 20.9572 37.6638C18.0803 35.3963 14.6426 33.5596 14.6426 33.5596C14.6426 33.5596 17.4952 31.6904 18.9418 30.7558C20.3965 29.8293 22.5501 28.7728 22.5501 28.7728Z" fill="#DD3636"/>
                </svg>
            </div>

            <h2 class="form-main-title">Reservation Successful</h2>
            <p class="form-main-para">We are waiting to see you!</p>

            <div class="form-element-fields">
                <div class="form-group">
                    <span class="form-field-inner">
                        <span class="form-field-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                        </span>
                        <inputText-prime class="form-element-field" type="text" value="Syed Asad Ali" readonly />
                    </span>
                </div>

                <div class="form-group">
                    <span class="form-field-inner">
                        <span class="form-field-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                            </svg>
                        </span>
                        <inputText-prime class="form-element-field" type="text" value="+92 3072456219" readonly />
                    </span>
                </div>

                <div class="form-group">
                    <span class="form-field-inner">
                        <span class="form-field-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                        </span>
                        <inputText-prime class="form-element-field" type="text" value="2 Guests" readonly />
                    </span>
                </div>

                <div class="form-group">
                    <span class="form-field-inner">
                        <span class="form-field-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-event" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                            <path d="M16 3l0 4" />
                            <path d="M8 3l0 4" />
                            <path d="M4 11l16 0" />
                            <path d="M8 15h2v2h-2z" />
                            </svg>
                        </span>
                        <inputText-prime class="form-element-field" type="text" value="Friday, 30 Aug, 2024" readonly />
                    </span>
                </div>
                
                <div class="form-group">
                    <span class="form-field-inner">
                        <span class="form-field-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clock-hour-3" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                            <path d="M12 12h3.5" />
                            <path d="M12 7v5" />
                            </svg>
                        </span>
                        <inputText-prime class="form-element-field" type="text" value="10:00 pm" readonly />
                    </span>
                </div>
                
                <div class="form-group">
                    <span class="form-field-inner">
                        <span class="form-field-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-smoking-no" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M8 13l0 4" />
                            <path d="M16 5v.5a2 2 0 0 0 2 2a2 2 0 0 1 2 2v.5" />
                            <path d="M3 3l18 18" />
                            <path d="M17 13h3a1 1 0 0 1 1 1v2c0 .28 -.115 .533 -.3 .714m-3.7 .286h-13a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h9" />
                            </svg>
                        </span>
                        <inputText-prime class="form-element-field" type="text" value="Indoor, No smoking" readonly />
                    </span>
                </div>

                <div class="form-group">
                    <span class="form-field-inner">
                        <span class="form-field-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-store" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M3 21l18 0" />
                            <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
                            <path d="M5 21l0 -10.15" />
                            <path d="M19 21l0 -10.15" />
                            <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                            </svg>
                        </span>
                        <inputText-prime class="form-element-field" type="text" value="Abu Dhabi Branch" readonly />
                    </span>
                </div>

                <div class="form-group mb-0">
                    <p class="note-text">Note* Please make sure to reach on time otherwise your reservation will get canceled after 20 min of time</p>
                </div>
                
                <div class="form-group mb-0">
                    <button-prime class="continue-now-btn" label="Continue Browsing" />
                </div>
            </div>
        </div>
        <!-- / Reservation Successful Step -->

        <reservationFooter />
    </div>
</template>

<script>

</script>

<style>
.reservation-content-container-flexible {
    background-color: #F4F7FE;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 75px;
}

.reservation-content-container-flexible .reservation-banner-area {
    height: 400px;
    /* background: #e9edf6; */
    background-image: url('/src/assets/images/reservation-templates/reservation-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 90%;
    margin-bottom: -260px;
    position: relative;
}

.reservation-content-container-flexible .reservation-logo-area {
    width: 160px;
    height: 160px;
    background: #000;
    border-radius: 50%;
    margin-top: 2rem;
    margin-bottom: -80px;
    z-index: 1;
}

.reservation-content-container-flexible .reservation-content-form-element {
    background: #fff;
    border-radius: 10px;
    width: 500px;
    min-height: 500px;
    padding: 25px;
    margin-bottom: 2rem;
    border-top: 10px solid #DD3636;
    box-shadow: 0 4px 15px rgb(0 0 0 / 7%);
}

.reservation-content-container-flexible h2.form-main-title {
    color: #000;
    font-weight: 600;
}

.reservation-content-container-flexible h2.form-main-title {
    color: #000;
    font-weight: 600;
}

.reservation-content-container-flexible label.form-group-label {
    display: block;
    color: #000;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 0.3rem;
}

.reservation-content-container-flexible .form-element-fields {
    margin-top: 3rem;
}

.reservation-content-container-flexible .form-element-field.p-inputtext {
    margin: 0;
    width: 100%;
    background: transparent !important;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: none !important;
    color: #000;
    outline: none;
    min-height: 50px;
    font-size: 14px;
    padding-left: 40px;
}

.reservation-content-container-flexible span.form-field-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    left: 10px;
    width: 20px;
}

.reservation-content-container-flexible .form-element-fields .form-group {
    margin-bottom: 15px;
}

.reservation-content-container-flexible .form-element-fields .form-group span.p-calendar {
    width: 100%;
}

.reservation-content-container-flexible .form-element-fields .form-group span.p-calendar input.p-inputtext.p-component {
    background: transparent;
    min-height: 50px;
    border-radius: 10px;
    padding-left: 40px;
    font-size: 14px;
    color: #000 !important;
    box-shadow: none !important;
}

.reservation-content-container-flexible .reservation-calendar.p-datepicker table td {
    padding: 0.3rem;
}

.reservation-content-container-flexible .reservation-calendar.p-datepicker table td > span {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
    font-size: 13px;
    font-weight: 500;
}

.reservation-content-container-flexible .reservation-calendar.p-datepicker table th {
    padding: 0.3rem;
}

.reservation-content-container-flexible .reservation-calendar.p-datepicker table th > span {
    width: 1.6rem;
    height: 1.6rem;
    font-size: 13px;
    font-weight: 600;
}

.reservation-content-container-flexible .reservation-calendar.p-datepicker .p-timepicker span {
    font-size: 14px;
    font-weight: 500;
}

.reservation-content-container-flexible .form-element-fields .form-group {
    margin-bottom: 15px;
}

.reservation-content-container-flexible .form-element-fields .form-group span.p-calendar {
    width: 100%;
}

.reservation-content-container-flexible .form-element-fields .form-group span.p-calendar input.p-inputtext.p-component {
    background: transparent;
    min-height: 50px;
    border-radius: 10px;
    padding-left: 40px;
    font-size: 14px;
    color: #000 !important;
    box-shadow: none !important;
}

.reservation-content-container-flexible .form-element-fields .form-group input.p-inputtext.p-component.p-inputnumber-input {
    /* padding-left: 40px; */
    background: transparent;
    box-shadow: none;
    color: #000;
    font-size: 14px;
    border: 0 !important;
    order: 2;
    text-align: center;
}

.reservation-content-container-flexible .form-element-fields span.p-inputnumber.p-component.p-inputwrapper.p-inputwrapper-filled {
    width: 100% !important;
    border: 1px solid #ced4da;
    border-radius: 10px;
    padding-right: 5px;
    padding-left: 5px;
    min-height: 50px;
}

.reservation-content-container-flexible .form-element-fields .form-group button.p-button.p-component.p-button-icon-only.p-inputnumber-button.p-inputnumber-button-up {
    background: transparent;
    border: 0;
    box-shadow: none;
    order: 3;
}

.reservation-content-container-flexible .form-element-fields .form-group button.p-button.p-component.p-button-icon-only.p-inputnumber-button.p-inputnumber-button-down {
    background: transparent;
    border: 0;
    box-shadow: none;
}

.reservation-content-container-flexible .form-element-fields .form-group button.p-button.p-component.p-button-icon-only .pi {
    font-size: 13px;
}

.reservation-content-container-flexible .form-element-fields .form-group button.p-button.p-component.p-button-icon-only {
    width: 30px;
    color: #000;
}

.reservation-content-container-flexible .form-element-fields .form-group .p-dropdown {
    background: transparent;
    border: 1px solid #ced4da;
    border-radius: 10px;
    color: #000;
    min-height: 50px;
    box-shadow: none;
}

.reservation-content-container-flexible .form-element-fields .form-group .p-dropdown span.p-dropdown-label.p-inputtext {
    color: #000;
    padding-left: 40px;
    font-size: 14px;
}

.reservation-content-container-flexible .form-element-fields .form-group textarea.p-inputtextarea.p-inputtext {
    background: transparent !important;
    border: 1px solid #ced4da !important;
    border-radius: 10px;
    color: #000;
    font-size: 14px !important;
}

.reservation-content-container-flexible button.p-button.p-component.book-now-btn {
    background: #FE724E;
    border: 0;
    width: 100%;
    border-radius: 10px;
    min-height: 50px;
    box-shadow: none;
}

.reservation-content-container-flexible button.p-button.p-component.book-now-btn span.p-button-label {
    font-size: 16px;
    font-weight: 500;
}

.reservation-content-container-flexible button.p-button.p-component.continue-now-btn {
    background: #DD3636;
    border: 0;
    width: 100%;
    border-radius: 10px;
    min-height: 50px;
    box-shadow: none;
}

.reservation-content-container-flexible button.p-button.p-component.continue-now-btn span.p-button-label {
    font-size: 16px;
    font-weight: 500;
}

.reservation-content-container-flexible label.sitting-box-area {
    border: 1px solid #fff;
    width: 140px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 12px 15px;
    -moz-column-gap: 5px;
    column-gap: 5px;
    height: 120px;
    background: rgb(221 54 54 / 10%);
}

.reservation-content-container-flexible label.sitting-box-area:has(.p-radiobutton-checked) {
    background: #DD3636 !important
}

.reservation-content-container-flexible .sitting-box-container {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 15px;
}

.reservation-content-container-flexible .sitting-box-info h4 {
    color: #000;
    line-height: normal;
    padding-bottom: 0.2rem;
    font-size: 15px;
    text-align: center;
    font-weight: 600;
}

.reservation-content-container-flexible .sitting-box-info p {
    color: #000;
    font-size: 13px;
    line-height: normal;
    text-align: center;
}

.reservation-content-container-flexible label.sitting-box-area:has(.p-radiobutton-checked) .sitting-box-info h4 {
    color: #fff;
}

.reservation-content-container-flexible label.sitting-box-area:has(.p-radiobutton-checked) .sitting-box-info p {
    color: #fff;
}

.reservation-content-container-flexible label.sitting-box-area:has(.p-radiobutton-checked) .sittingIcon svg {
    stroke: #fff;
}

.reservation-content-container-flexible .inner-info-area {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    column-gap: 12px;
    margin-left: 0 !important;
}

.reservation-content-container-flexible label.sitting-box-area .p-radiobutton {
    display: none;
}

.reservation-content-container-flexible .sittingIcon svg.icon.icon-tabler {
    width: 30px;
}

.reservation-content-container-flexible .sitting-box-area .p-radiobutton .p-radiobutton-box {
    background: transparent;
    box-shadow: none !important;
    width: 20px;
    height: 20px;
}

.reservation-content-container-flexible .reservation-successful-element {
    background: #fff;
    border-radius: 10px;
    width: 500px;
    min-height: 500px;
    padding: 25px;
    margin-bottom: 2rem;
    border-top: 10px solid #DD3636;
    box-shadow: 0 4px 15px rgb(0 0 0 / 7%);
}

.reservation-content-container-flexible p.note-text {
    color: #8A8888;
    font-size: 13px;
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: 1.5;
}

.reservation-content-container-flexible .reservation-successful-element .form-element-fields .form-group {
    margin-bottom: 0;
}

.reservation-content-container-flexible .reservation-successful-element .form-element-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    padding: 0;
    margin-top: 25px;
}

.reservation-content-container-flexible .reservation-successful-element .form-element-fields .form-group:nth-child(7) {
  grid-column-end: span 2;
}

.reservation-content-container-flexible .reservation-successful-element .form-element-fields .form-group:nth-child(8) {
  grid-column-end: span 2;
}

.reservation-content-container-flexible .reservation-successful-element .form-element-fields .form-group:nth-child(9) {
  grid-column-end: span 2;
}

.reservation-content-container-flexible .reservation-successful-element h2.form-main-title {
    text-align: center;
    margin-bottom: 0px;
}

.reservation-content-container-flexible p.form-main-para {
    color: #000;
    text-align: center;
    margin-bottom: 0px;
}

.reservation-content-container-flexible .reservation-success-check {
    text-align: center;
    margin-bottom: 5px;
    margin-top: 60px;
}

.selected-date-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    padding: 0;
}

.selected-date-item {
    background: rgb(221 54 54 / 10%);
    border-radius: 10px;
    height: 85px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 0.3rem;
}

.selected-date-item.selected {
    background:#DD3636 !important;
}

.selected-date-item.selected h4 {
    color: #fff !important;
}

h4.h4-time {
    font-size: 13px !important;
}

h4.h4-first {
    font-size: 13px !important;
}

.selected-date-item h4 {
    color: #000;
    font-weight: 500;
    font-size: 15px;
    margin: 0;
    line-height: normal;
}

@media only screen and (max-width: 767px) { 
    .reservation-content-container-flexible .reservation-content-form-element {
        width: 94% !important;
    }
    
    .reservation-content-container-flexible .reservation-successful-element {
        width: 94% !important;
    }

    .reservation-content-container-flexible .reservation-template-header-tabs {
        column-gap: 0.6rem !important;
    }
}
</style>

<script>
  import reservationHeader from '/src/components/Reservation-templates/Flexible-template/ReservationHeader.vue'
  import reservationFooter from '/src/components/Reservation-templates/Flexible-template/ReservationFooter.vue'

// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation } from 'swiper';
import { Pagination } from 'swiper';
import { Autoplay } from 'swiper';


  export default {
    components: {
        Swiper,
        SwiperSlide,
        reservationHeader,
        reservationFooter
    },
    data() {
      const today = new Date();
      return {
        modules: [Navigation, Pagination, Autoplay],
        date: null,
        time: null,
        value: 1,
        textareaResize: '',
        sittingRadioValue: '',
        showReservationForm: true,
        showReservationFormStepTwo: false,
        showReservationSuccess: false,

        selectedYear: { name: 'Sep', id: '9' },
        years: [
            { name: 'Jan', id: '1' },
            { name: 'Feb', id: '2' },
            { name: 'Mar', id: '3' },
            { name: 'Apr', id: '4' },
            { name: 'May', id: '5' },
            { name: 'Jun', id: '6' },
            { name: 'Jul', id: '7' },
            { name: 'Aug', id: '8' },
            { name: 'Sep', id: '9' },
            { name: 'Oct', id: '10' },
            { name: 'Nov', id: '11' },
            { name: 'Dec', id: '12' },
        ],

        year: new Date().getFullYear(),   // Current year
        month: new Date().getMonth() + 1, // Current month (0-indexed, so +1)
        currentDay: today.getDate(),        // Current date (day of the month)
        currentWeekday: today.getDay(),     // Current day of the week (0=Sunday, 6=Saturday)
        weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

        
        selectedBranch: null,
        branch: [
            { name: 'Branch 1' },
            { name: 'Branch 2' },
        ]
      }
    },
    computed: {
        daysInMonth() {
        const days = [];
        const totalDays = new Date(this.year, this.month, 0).getDate(); // Total days in the month

        for (let day = 1; day <= totalDays; day++) {
            const currentDate = new Date(this.year, this.month - 1, day);
            const dayName = this.weekDays[currentDate.getDay()].slice(0, 3); // Get day name (0=Sunday, 1=Monday, etc.)
            days.push({
            date: day,      // Day number (1, 2, 3...)
            dayName: dayName // Corresponding day name (Sunday, Monday...)
            });
        }

        return days;
        },
        monthName() {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'Novemer', 'December'
        ];b
        return months[this.month - 1];
        }
    },
    methods: {
        showReservationBookNowStep() {
            this.showReservationForm = false;
            this.showReservationFormStepTwo = true;
            scrollTo(0,0);
        },
       
        showReservationSuccessStep() {
            this.showReservationFormStepTwo = false;
            this.showReservationSuccess = true;
            scrollTo(0,0);
        },

        isCurrentDate(day) {
          return day.date === this.currentDay && this.year === new Date().getFullYear() && this.month === (new Date().getMonth() + 1);
        }
    },
    mounted(){
    } 
  }
</script>