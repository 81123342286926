<template>
    <div class="main-content booking-seating-view" :style="{background : customization.second_box_color}">
        <ReservationHeaderView :customization="customization" />

        <main>
            <div class="container">
                <div class="col-12 md:col-12 lg:col-12">
                    <div v-if="customization.banner!=null" class="restaurent-img-thumbnail" :style="{backgroundImage : 'url('+customization.banner+')'}"></div>
                    <div v-else class="restaurent-img-thumbnail" :style="{background : customization.banner_background_color}"></div>

                    <div v-if="customization.logo!=null" class="logo-main-box-container" :style="{backgroundImage : 'url('+customization.logo+')'}"></div>
                    <div v-else class="logo-main-box-container"></div>
                </div>   
            </div>
            <div class="container first-box-element" :style="{background : customization.first_box_color}">
                <div class="reservation-container grid m-0 second-box-element reservation-thankYou-container" :style="{background : customization.second_box_color}">
                    <div class="col-12 md:col-12 lg:col-12">
                        <h4 :style="{color : customization.text_box_color}">Thank you for reserving with us</h4>  

                        <div class="content-box">
                            <img class="thankYouEmailImg" src="../assets/images/thankYouEmail.png">
                        </div>

                        <div class="content-box">
                            <p :style="{color : customization.text_box_color}">Thanks for reserving with us, You Should receive a confirmation email soon.</p>
                        </div>

                        <div class="content-box contact-content-box">
                            <h4 :style="{color : customization.text_box_color}">For more information contact us</h4>
                            <p :style="{color : customization.text_box_color}">contact@amakenapp.com</p>
                            <p :style="{color : customization.text_box_color}">111 235 655</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <ReservationFooterView :customization="customization" />
    </div>
</template>


<script>
// import axios from 'axios'
// import moment from 'moment'
import ReservationHeaderView from '/src/components/reservationHeader.vue'
import ReservationFooterView from '/src/components/reservationFooter.vue'
import axios from 'axios'
export default {
    components: {
        ReservationHeaderView,
        ReservationFooterView,
    },
    data() {
        return {
            customization : {
                banner_background_color : '#313131',
                header_color : '#000000',
                footer_color : '#000000',
                first_box_color : '#212121',
                second_box_color : '#2C2C2C',
                text_box_color : '#FFFFFF',
                field_box_color : '#000000',
                button_box_color : '#884192',
                banner : null,
                logo : null
            }
        }
    },
    mounted() {
        this.get_customization_settings();
    },
    methods: { 
        get_customization_settings : function() {
            axios.get(this.api_url+'get_customization', {
                params: { 
                    slug: this.$route.params.slug,
                    type : 'branch'
                } 
            }).then((response) => {
                if (response.data.data.customization != '') {
                    this.customization = response.data.data.customization;
                }
            });
        }
    }
};
</script>
  
<style scoped>
.reservation-summary-info-list {
    display: flex;
    align-items: flex-start;
    column-gap: 0.8rem;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
}

.reservation-summary-info-list .info-icon {
    display: flex;
    align-items: center;
    transform: translateY(3px);
}

.reservation-summary-info-list .info-icon i.pi {
    color: #fff;
}

.reservation-summary-info-list .info-text p {
    color: #fff;
    font-size: 14px;
}

.reservation-summary-container {
    margin-top: 1.5rem;
}

img.thankYouEmailImg {
    width: 50%;
    margin: auto;
    display: flex;
}

.content-box {
    margin-top: 2.5rem;
}

.reservation-thankYou-container .content-box p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

.reservation-thankYou-container .content-box h4 {
    margin-top: 0;
    font-size: 16px !important;
    margin-bottom: 0.5rem;
}

.reservation-thankYou-container .content-box.contact-content-box p {
    margin-bottom: 0.5rem;
}
</style>