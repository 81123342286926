<template>
    <div class="page-wrapper">
        <div class="grid m-0">
            <div class="col-12 md:col-6 lg:col-6 signIn-form-leftArea">
                <div class="verify-otp-area">
                    <router-link to="/">
                        <div class="logo-area">
                            <img class="site-logo-amaken" src="../assets/images/logo/amaken-logo.svg">
                        </div>
                    </router-link>
                    <div class="sign-form-area" id="verify-otp">
                        <message-prime severity="success" v-if="resend_email_success_message!=null">{{resend_email_success_message}}</message-prime>
                        <h1 class="otp-title">{{ $t('verify_email') }}</h1>
                        <p class="otp-label">{{ $t('please_check_your_account_part1') }} <span>{{ email }}</span> {{ $t('please_check_your_account_part2') }}</p>

                        <div class="form-fields-main">
                            <button-prime @click="resendEmail" :loading="loading" class="btn-transparent" :label="$t('resend_link')" rounded />
                        </div>

                        <!-- <div class="form-fields-main">
                            <button-prime class="btn-transparent" label="Change Email" rounded />
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-6 signIn-Info-rightArea">
                <div class="info-right-image">
                    <img class="verify-infoRight-img" src="../assets/Icons/authenticaionIcon.svg">
                </div>
                <div class="welcome-text-sign">
                    <h2>{{ $t('welcome_to_amaken') }}</h2>
                    <!-- <p class="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    
    data() {
        return {
            token : null,
            email : null,
            resend_email_success_message : null,
            loading : false
        }
    },
    methods : {
        getUserDetails : function() {
            axios.get(this.api_url+'get_user_detail_for_verification',{
            headers: { 
                'Authorization': 'Bearer '+this.token
            } 
            }).then((response) => {
                console.log(response);
                this.email = response.data.user.email
            }).catch((error) => {
                console.log(error)
            });
        },
        resendEmail : function() {
            this.loading = true;
            axios.get(this.api_url+'resend_email',{
            headers: { 
                'Authorization': 'Bearer '+this.token
            },
            params : {
                lang : this.amaken_language
            } 
            }).then((response) => {
                this.loading = false; 
                this.resend_email_success_message = response.data.message
            }).catch((error) => {
                this.loading = false;
                console.log(error)
            });
        }
    },
    mounted() {
        this.token = this.$route.query.token;
        this.getUserDetails()
    }
}
</script>

<style>
.verify-otp-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
}

.form-fields-main.form-otp-inputs input.p-inputtext.form-input-fields {
    padding: 0.75rem 0.75rem;
    border-radius: 10px;
    text-align: center;
}

.form-fields-main.form-otp-inputs {
    display: flex;
    column-gap: 0.5rem;
}

h1.otp-title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 5px;
}

p.otp-label span {
    color: #0091f6;
    font-weight: 500;
}

p.otp-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}
</style>
