<template>
    Loading...!
</template>
<script>
// import axios from 'axios'
export default {
    
    data() {
        return {
            
        }
    },
    mounted() {
        this.get_and_set_token();
    },
    methods : {
        get_and_set_token : function() {
            localStorage.removeItem('token');
            localStorage.setItem('token', this.$route.params.id);  
            this.$router.push({name : 'home'});
        },
    }
}
</script>