<template>
    <HeaderView />

    <div class="main-content">

      <!-- Main Slides Area -->
      <div class="main-slide-container inner-sec-bg">
      <div class="slides-bg">

          <div class="section-title">
          <h2 id="mess-info">{{ $t('become_a_partner') }}</h2>
          </div>
      </div>
      </div>
      <!-- / Main Slides Area -->

      <div class="profile-setting-middleArea pb-6">
        <div class="grid m-0 profile-setting-reverse">
          <div class="col-12 md:col-12 lg:col-6">
            <!-- <h2 class="setting-profileTitle"></h2> -->
            <message-prime severity="error" v-if="validationsError.length">{{validationsError[0]}}</message-prime>
              <message-prime severity="success" v-if="success_message != null">{{success_message}}</message-prime>
            <div class="grid mt-3 profile-setting-fieldsSetting-left">
              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                          <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                          <inputText-prime class="form-input-fields" v-model="first_name" :placeholder="$t('first_name')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                          <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                          <inputText-prime class="form-input-fields" v-model="last_name" :placeholder="$t('last_name')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                        <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                        <inputText-prime class="form-input-fields" v-model="email" :placeholder="$t('email')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                        <i class="pi pi-mobile" style="font-size: 1.2rem"></i>
                        <inputText-prime class="form-input-fields" maxlength="13" onkeypress='return event.charCode >= 48 && event.charCode <= 57' v-model="phone" :placeholder="$t('phone')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left nationality-dropdown">
                        <i class="pi pi-id-card" style="font-size: 1.2rem"></i>
                        <dropdown-prime filter @change="get_cities" name="country" v-model="country" :options="countries_list" optionLabel="name" :placeholder="$t('country')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left nationality-dropdown">
                        <i class="pi pi-id-card" style="font-size: 1.2rem"></i>
                        <dropdown-prime filter v-model="city" name="city" :options="cities_list" optionLabel="name" :placeholder="$t('city')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main mb-0">
                      <span class="p-input-icon-left">
                        <textarea-prime v-model="description" autoResize rows="5" cols="30" :placeholder="$t('write_desc_here')" />
                      </span>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="addFilter-item mb-4">
            <div class="flex align-items-center addFilter-itemCheckbox">
                <checkbox-prime v-model="filtercheck" inputId="ingredient1" name="filtercheck" value="1" />
                <label for="ingredient1" class="ml-2"> I agree to the terms and conditions as set out by the user agreement </label>
            </div>
        </div> -->

        <button-prime :label="$t('submit')" @click="submit" :loading="loading" class="savechanges-btn" severity="success" rounded />
      </div>

    </div>

    <FooterView />
</template>
<script>
import HeaderView from '/src/components/Header/HeaderView.vue'
import FooterView from '/src/components/Footer/FooterView.vue'
import axios from 'axios'
/* eslint-disable global-require */
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      first_name : null,
      last_name : null,
      email : null,
      phone : null,
      company : null,
      country : null,
      city : null,
      description : null,
      cities_list : [],
      countries_list : [],
      loading : false,
      validationsError : [],
      success_message : null
    }
  },
  mounted() {
    this.get_countries_list();
  },
  methods : {
    submit : function() {
      this.validationsError = [];
      this.loading = true;
      this.success_message = null;
      axios.get(this.api_url+'submit_become_partner_request',{
        params : {
          first_name : this.first_name,
          last_name : this.last_name,
          email : this.email,
          phone : this.phone,
          country : this.country,
          city : this.city,
          description : this.description
        } 
      }).then((response) => {
          this.loading = false;
          if(response.status == 200) {
              if (response.data.status == true) {
                  this.success_message = response.data.message;
                  document.getElementById('mess-info').scrollIntoView();
              } 
          }
      }).catch((error) => {
          this.loading = false;
          if(error.response.status == 401) {
              this.validationsError.push(error.response.data.errors);
              document.getElementById('mess-info').scrollIntoView();
          }
      });
    },
    get_countries_list : function() {
      axios.get(this.api_url+'get_countries',).then((response) => {
        this.countries_list = response.data.countries  
      });
    },
    get_cities : function() {
      axios.get(this.api_url+'get_cities',{
        params : {
          country : this.country
        } 
      }).then((response) => {
          if(response.status == 200) {
              if (response.data.status == true) {
                  this.cities_list = response.data.cities;
              } 
          }
      }).catch((error) => {
          console.log('error', error);
      });
    },
  }
}
/* eslint-disable global-require */
</script>

<style>
.main-slide-container.inner-sec-bg {
    background: url('~@/assets/images/sliders/slide-1.png');
    height: 350px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.main-slide-container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000059;
    width: 100%;
    height: 100%;
}

.section-title h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.4;
}

.section-title {
    position: absolute;
    bottom: 60px;
    left: 40px;
}

.slides-bg {
    position: relative;
    height: 100%;
}

.content-area {
    height: 100vh;
}

.cover-bg-profile.inner-sec-bg {
    /* background: url('src/assets/images/sliders/slide-1.png'); */
    height: 350px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.profile-top-header {
    box-shadow: 0 3px 10px #00000029;
    height: 150px;
    width: 100%;
    border-radius: 10px;
}

.user-profile-image .p-avatar.p-component.p-avatar-image.p-avatar-circle.p-avatar-xl {
    width: 8rem;
    height: 8rem;
}

.profileInner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  transform: translateY(-25px);
}

.profile-top-right {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.profile-top-right button.p-button.p-component.p-button-rounded span.p-button-label {
  font-size: 16px;
  font-weight: 400;
}

.profile-top-left {
    display: flex;
    align-items: center;
}



.user-profile-topInfo {
    /* transform: translateY(-10px); */
    padding-left: 0.6rem;
}

.user-name {
    font-size: 25px;
    font-weight: 500;
}

.user-address {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.profile-setting-middleArea {
    margin-top: 1rem;
}

h2.setting-profileTitle {
    font-weight: 500;
}

/* .profile-setting-fieldsSetting-left {
  padding-right: 2rem;
} */

.profile-setting-fieldsSetting-right {
  padding-left: 1rem;
}


.profile-setting-cover-bg {
    display: none;
}

button.p-button.p-component.p-button-rounded.savechanges-btn {
  display: flex;
  box-shadow: none;
}

button.p-button.p-component.p-button-rounded.upload-image-btn {
  display: flex;
  background: #0091fb;
  border: 1px solid #0091fb;
  box-shadow: none;
}

button.p-button.p-component.p-button-rounded.savechanges-btn span.p-button-label {
  font-weight: 500;
  font-size: 16px;
}

.form-fields-main .p-password.form-input-fields.p-input-icon-right > svg:last-of-type {
    right: 1.5rem;
    color: #6c757d;
}

textarea.p-inputtextarea.p-inputtext.p-component.p-inputtextarea-resizable:not(.bookingRequestMessage):not(.make-review-textarea) {
  border: 0;
    width: 100%;
    border-radius: x;
    background: #f4f4f4;
    box-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 150px !important;
    padding-left: 1rem;
}

.addFilter-item .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #0091fb;
    background: #0091fb;
}

.addFilter-item .p-checkbox .p-checkbox-box {
    border-radius: 4px;
    width: 20px;
    height: 20px;
    box-shadow: none;
}

</style>