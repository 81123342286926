<template>
    <HeaderView />

    <div class="main-content">
        <main>
        <!-- Main Slides Area -->
        <div class="main-slide-container home-slider">
            <!-- <i class="pi pi-heart-fill love-sign-restaurent" />
            <i class="pi pi-share-alt share-sign" /> -->
            <swiper 
                :slides-per-view="1" 
                :space-between="0" 
                :modules="modules" 
                setWrapperSize="ture"
                :loop="true"
                :autoplay="{
                  delay: 2000,
                  disableOnInteraction: false,
                }"
                :pagination="{
                  clickable: true,
                }"
                :breakpoints="{
                  '@0.00': {slidesPerView: 1,spaceBetween: 0},
                  '@0.75': {slidesPerView: 1,spaceBetween: 0},
                  '@1.00': {slidesPerView: 1,spaceBetween: 0},
                  '@1.50': {slidesPerView: 1,spaceBetween: 0},
                }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
              >
                <swiper-slide  v-for="item in eventdetail.photos" :key="item">  
                  <div class="main-slide-container" v-bind:style="{'background-image': 'url('+item.image_path+')'}" >
                    <div class="section-title">
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
        </div>
        <!-- / Main Slides Area -->
    
        <!-- Category Section Area -->
        <div class="restaurents-section-area mt-5"> 
            <div class="grid m-0">
                <div class="col-12 restaurent-logo-detail">
                    <div class="restaurent-logo">
                        <img v-bind:src="require('/src/assets/images/logo/amaken-logo.svg')">
                    </div>
                    <div class="section-title-area flex align-items-center justify-content-between detail-view-top-titleBtn-header">
                        <h2 class="section-title-text m-0">{{ get_event_name(eventdetail) }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Category Section Area -->
        
        <div class="restaurent-detail-info mt-3">
            <div class="grid m-0">
                <div class="col-6 md:col-4 lg:col-4">
                    <div class="detail-info-box">
                        <div class="detail-info-boxIcon">
                            <i class="pi pi-map" style="font-size: 1.8rem;color: #0091fb;"></i>
                        </div>
                        <h4 class="detail-box-titles">{{ $t('event_type') }}</h4>
                        <p class="detail-info-boxIcon placetype-box">
                            <span>{{get_event_type_name(eventdetail.events_type)}}</span>
                        </p>
                    </div>
                </div>
                <div class="col-6 md:col-4 lg:col-4">
                    <div class="detail-info-box">
                        <div class="detail-info-boxIcon">
                            <svg width="30" height="30" viewBox="0 0 26.248 37.497">
                                <path id="Icon_material-location-on" data-name="Icon material-location-on" d="M20.624,3A13.114,13.114,0,0,0,7.5,16.124C7.5,25.967,20.624,40.5,20.624,40.5s13.124-14.53,13.124-24.373A13.114,13.114,0,0,0,20.624,3Zm0,17.811a4.687,4.687,0,1,1,4.687-4.687A4.689,4.689,0,0,1,20.624,20.811Z" transform="translate(-7.5 -3)" fill="#3591ff"/>
                            </svg>
                        </div>
                        <h4 class="detail-box-titles">{{ $t('location') }}</h4>
                        <p class="detail-info-boxIcon">
                            <span>{{ eventdetail.place.area == null ? "" : eventdetail.place.area.name+", " }} {{ eventdetail.place.city == null ? "" : eventdetail.place.city.name }} {{ eventdetail.place.country == null ? "" : eventdetail.place.country.name }}</span>
                        </p>
                    </div>
                </div>
                <div class="col-6 md:col-4 lg:col-4">
                    <div class="detail-info-box">
                        <div class="detail-info-boxIcon">
                            <svg width="30" height="30" viewBox="0 0 37.56 37.497">
                            <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                <path id="Path_3688" data-name="Path 3688" d="M45.249,43.749H39V47.5h8.124A1.875,1.875,0,0,0,49,45.624V35h-3.75Z" transform="translate(-27.058 -25.626)" fill="#0091f7"/>
                                <path id="Path_3689" data-name="Path 3689" d="M38.707,57.5a18.748,18.748,0,0,0,17.1-26.429A6.249,6.249,0,0,1,52.325,32.5a15,15,0,1,1-6.687-7.018,6.249,6.249,0,0,1,1.575-3.406A18.748,18.748,0,1,0,38.707,57.5Z" transform="translate(-19.891 -20)" fill="#0091f7"/>
                                <ellipse id="Ellipse_13" data-name="Ellipse 13" cx="2.172" cy="3.258" rx="2.172" ry="3.258" transform="translate(28.848 3.646)" fill="#0091f7"/>
                            </g>
                            </svg>
                        </div>
                        <h4 class="detail-box-titles">{{ $t('opening_time') }}</h4>
                        <p class="detail-info-boxIcon">
                            <!-- <span>Monday - Friday</span> 
                            <span>8:00 AM To 12:00 AM</span> -->
                            <span class="event-time-details">{{ eventdetail.start_date }} {{ eventdetail.start_time }} - {{ eventdetail.end_date }} {{ eventdetail.end_time }}</span> 
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Overview Section -->
        <div class="restaurents-section-area restaurentsDetail-overview-area section-mt mb-5">
            <h2 class="section-title-text">{{ $t('description') }}</h2>

            <p class="overview-ino-text" v-html="eventdetail.description">
               
            </p>
        </div>
        <!-- / Description Section -->
        </main>
    </div>

    <FooterView />
  </template>
  


<script>
import HeaderView from '/src/components/Header/HeaderView.vue'
import FooterView from '/src/components/Footer/FooterView.vue'

// Import Swiper styles
import 'swiper/css';
import axios from 'axios'
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import { Pagination } from 'swiper';
import { Autoplay } from 'swiper';


export default {
    components: {
    HeaderView,
    FooterView,
    Swiper,
    SwiperSlide,
    },
    data() {
        return {
            modules: [Navigation, Pagination, Autoplay],
            eventdetail : {
                name : '',
                events_type : {
                    name : ''
                },
                place : {

                },
                photos : []
            }
        }
    },
    mounted() {
      this.get_event();
    },
    methods: {
        get_event_name : function(item) {
            var event_name = item.name;
            if (this.amaken_language == 'AR') {
                event_name = item.arabic_name;
            }
            if(event_name == null) {
                event_name = item.name;
            }
            return event_name;
        },
        get_event_type_name : function(item) {
            var event_type_name = item.name;
            if (this.amaken_language == 'AR') {
                event_type_name = item.arabic_name;
            }
            if(event_type_name == null) {
                event_type_name = item.name;
            }
            return event_type_name;
        },
      get_event : function() {
        axios.get(this.api_url+'get_event_details', {
            params: { 
                id: this.$route.params.id 
            } 
        }).then((response) => {
          this.eventdetail = response.data.eventdetail;
        });
      }
    }
    
};
</script>

<style>


</style>
  
  
  