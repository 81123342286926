<template>
    <HeaderView />

    <div class="main-content place-detail-view">        
        <main>
           
           <div class="favriouts">
              <tabView-prime>
                <tabPanel-prime :header="$t('places')">

                  <div class="no-content-box hidden">
                    <i class="pi pi-heart-fill"></i>
                    <p>{{$t('no_favorites_yet')}}</p>
                    <p class="sub-text-fav">{{$t('tap')}} <i class="pi pi-heart"></i> {{$t('to_save_your_favorite')}}</p>
                  </div>

                  <!-- Restaurents Section Area -->
                  <div class="restaurens-box restaurents-section-area mt-3">
                      <div class="grid m-0">

                          <div class="all-restaurents-leftBox col-12 md:col-12 lg:col-12">
                              <div class="flex align-items-center justify-content-between mb-5">
                                  <h2 class="section-title-text mb-0 margin-top-0">{{ $t('my_favorites') }}</h2>

                                  <div class="switch-restaurents-layout">
                                      <div class="addFilter-itemsArea mr-1">
                                        
                                      </div>
                                      <span class="grid-layout-icon" v-on:click="isHidden = !isHidden">
                                        <i class="pi pi-bars" style="font-size: 1rem"></i>
                                      </span>
                                      <!-- <span class="list-layout-icon" v-on:click="isHidden = !isHidden">
                                        <i class="pi pi-th-large" style="font-size: 1rem"></i>
                                      </span> -->
                                  </div>
                              </div>

                              <div v-bind:class="{ 'grid ml-0 mr-0 restaurents-grid-layout': !isHidden, 'grid ml-0 mr-0 restaurents-list-layout' : isHidden}">
                                  <div v-bind:class="{ 'col-12 md:col-3 lg:col-3': !isHidden, 'col-12 md:col-12 lg:col-12' : isHidden}" v-for="item in liked_places" :key="item">
                                      <div class="restaurents-box-items">
                                          <div class="restaurents-box-image">
                                            <i :class="'pi pi-heart-fill love-sign-restaurent heartActive place-id-'+item.id"  @click="addremovelove(item.id)" />
                                            <swiper
                                              :slides-per-view="4"
                                              :space-between="10"
                                              :modules="modules"
                                              :navigation="true"
                                              :breakpoints="{
                                                '@0.00': { slidesPerView: 1, spaceBetween: 0,},
                                                '@0.75': { slidesPerView: 1, spaceBetween: 0,},
                                                '@1.00': { slidesPerView: 1, spaceBetween: 0,},
                                                '@1.50': { slidesPerView: 1, spaceBetween: 0,},
                                              }"
                                              @swiper="onSwiper"
                                              @slideChange="onSlideChange"
                                              :pagination="{ clickable: true}"
                                            >
                                              <swiper-slide v-show="item.galleryphotos.length > 0" v-for="placeimage in item.galleryphotos" :key="placeimage">
                                                <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                                    <div class="mb-3">
                                                      <router-link :to="'/place-detail/' + item.id">   
                                                        <img :src="placeimage.image_path" class="w-6 shadow-2" />
                                                      </router-link>
                                                    </div>
                                                </div>
                                              </swiper-slide>
                                              <swiper-slide v-show="item.galleryphotos.length==0">
                                                <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                                    <div class="mb-3">
                                                      <router-link :to="'/place-detail/' + item.id">   
                                                        <img v-bind:src="require('/src/assets/images/restaurent-sliders/slider2.jpg')"  class="w-6 shadow-2" />
                                                      </router-link>  
                                                    </div>
                                                </div>
                                              </swiper-slide>
                                              <swiper-slide v-show="item.galleryphotos.length==0">
                                                <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                                    <div class="mb-3">
                                                      <router-link :to="'/place-detail/' + item.id">   
                                                        <img v-bind:src="require('/src/assets/images/restaurent-sliders/slider3.jpg')"  class="w-6 shadow-2" />
                                                      </router-link>
                                                    </div>
                                                </div>
                                              </swiper-slide>
                                            </swiper>

                                            <div class="restaurent-logo">
                                              <img v-bind:src="require('/src/assets/images/logo/amaken-logo.svg')">
                                            </div>
                                          </div>
                                          <router-link :to="'/place-detail/' + item.id">   
                                          <div class="card-info-area card-info-restaurent-area" v-if="!isHidden"><!--Grid view-->
                                            <div class="restaurent-info-right">
                                              <div class="listing-card-subtitle restaurents-opening-time">
                                                <span class="closing-time" v-if="!openedOrClosed(item.current_day_timings)">{{ $t('closed') }}</span>
                                                <span class="opening-time" v-else>{{ $t('open_now') }}</span>
                                              </div>
                                              <div class="listing-card-title">
                                                <span class="restaurents-name">{{ get_place_name(item) }}</span>
                                                  <!-- <span v-if="item.ratings != null" class="rating-label"><rating-prime :stars="1" :cancel="false" />{{ item.ratings.rating+'.0'}}</span> -->
                                              </div>
                                              <div class="listing-card-subtitle">
                                                  <span class="food-item">{{ set_cuisine_text(item.famouscuisine) }}</span>
                                                  <!-- <span class="review-label"><span>no reviews!</span></span> -->
                                                  <!-- <span class="review-label">504 <span>reviews</span></span> -->
                                              </div>
                                              <div class="listing-card-subtitle">
                                                <span class="food-item">{{ item.area == null ? "" : item.area.name+", " }} {{ item.city == null ? "" : item.city.name }} {{ item.country == null ? "" : item.country.name }}</span>
                                              </div>
                                              <div class="listing-card-subtitle home-review-label" style="min-height: 40px;text-align: left !important;border-top: 1px solid rgb(221 221 221);margin-top: 0.6rem;padding-top: 0.6rem;">
                                                <span class="review-label restaurents-review-label" style="text-align: left;"><span class="rating-box-area"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star-filled" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z" stroke-width="0" fill="currentColor" /></svg> {{(item.ratings != null ? item.ratings.rating+'.0' : $t('no_ratings_yet') )}} <span class="currency-icons" v-html="currency_icons(item)"></span></span></span><span class="review-label restaurents-review-label" style="text-align: left;text-align: right;"><span v-if="item.allow_booking==1" style="background: #dd3636;color: #fff;padding: 0.5rem;border-radius: 4px;font-size: 12px;cursor: pointer;" @click="book(item)">{{ $t('book_now') }}</span></span>
                                              </div>
                                              <!-- <div class="listing-card-subtitle">
                                                  <span class="review-label restaurents-review-label"><span>{{item.totalreviews != null ? item.totalreviews.total : 'no' }} reviews!</span></span>
                                              </div> -->
                                            </div>
                                          </div>

                                          <div v-else class="card-info-area card-info-restaurent-area"><!--list view-->
                                            <div class="restaurent-info-right">
                                              <div class="listing-card-subtitle restaurents-opening-time">
                                                <span class="closing-time" v-if="!openedOrClosed(item.current_day_timings)">{{ $t('closed') }}</span>
                                                <span class="opening-time" v-else>{{ $t('open_now') }}</span>
                                              </div>
                                              <div class="listing-card-title">
                                                <span class="restaurents-name">{{ get_place_name(item) }}</span>
                                                  <!-- <span v-if="item.ratings != null" class="rating-label"><rating-prime :stars="1" :cancel="false" />{{ item.ratings.rating+'.0'}}</span> -->
                                              </div>
                                              <div class="listing-card-subtitle">
                                                  <span class="food-item">{{ set_cuisine_text(item.famouscuisine) }}</span>
                                                  <!-- <span class="review-label"><span>no reviews!</span></span> -->
                                              </div>
                                              <div class="listing-card-subtitle">
                                                  <span class="food-item">{{ item.area == null ? "" : item.area.name+", " }} {{ item.city == null ? "" : item.city.name }} {{ item.country == null ? "" : item.country.name }}</span>
                                              </div>
                                              <div class="listing-card-subtitle home-review-label" style="min-height: 40px;text-align: left !important;border-top: 1px solid rgb(221 221 221);margin-top: 0.6rem;padding-top: 0.6rem;">
                                                <span class="review-label restaurents-review-label" style="text-align: left;"><span class="rating-box-area"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star-filled" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z" stroke-width="0" fill="currentColor" /></svg> {{(item.ratings != null ? item.ratings.rating+'.0' : $t('no_ratings_yet') )}} <span class="currency-icons" v-html="currency_icons(item)"></span></span></span><span class="review-label restaurents-review-label" style="text-align: left;text-align: right;"><span v-if="item.allow_booking==1" style="background: #dd3636;color: #fff;padding: 0.5rem;border-radius: 4px;font-size: 12px;cursor: pointer;" @click="book(item)">{{ $t('book_now') }}</span></span>
                                              </div>
                                              <!-- <div class="listing-card-subtitle">
                                                  <span class="review-label restaurents-review-label"><span>{{item.totalreviews != null ? item.totalreviews.total : 'no' }} reviews!</span></span>
                                              </div> -->
                                            </div>
                                          </div>
                                          </router-link>
                                        </div>
                                    </div>
                                </div>
                              
                              <!-- <div class="mt-3 mb-2">
                                  <paginator-prime :rows="5" :totalRecords="120"></paginator-prime>
                              </div> -->
                          </div>
                      </div>
                  </div>
                  <!-- / Restaurents Section Area -->
                </tabPanel-prime>
                <tabPanel-prime :header="$t('events')">
                  <div class="no-content-box">
                    <i class="pi pi-heart-fill"></i>
                    <p>{{$t('no_favorites_yet')}}</p>
                    <p class="sub-text-fav">{{$t('tap')}} <i class="pi pi-heart"></i> {{$t('to_save_your_favorite')}}</p>
                  </div>
                </tabPanel-prime>
              </tabView-prime>
           </div>

            <!-- <div class="main-slide-container home-slider no-bg-image grid-slider-swiper grid-slider-swiperPlace">
              <div>
                <div class="grid m-0">
                  <div class="col-12 md:col-6 md:col-6 p-0">
                    <div class="slider-left-content-place-area">
                      <div class="section-title">
                        Search For Your Favorite Restaurents Easily. Book Instantly. Get Top Offers.
                      </div>
                      <div class="leftBannerSec-bottom-text">
                        <p>5000+ Restaurents</p>
                        <p>1 Million Happy Customer</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 md:col-6 md:col-6 p-0">
                    <div class="main-slide-container place-bg">
                      <div class="section-title">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- / Main Slides Area -->

            <!-- <FilterPopup></FilterPopup> -->
        </main>
    </div>

    <FooterView />
    <div class="card flex justify-content-center text-center">
      <dialog-prime class="login-popup-modal" v-model:visible="login_alert_modal" modal header="" :style="{ width: '50vw', textAlign : 'center' }">
          <p>{{ $t('login_your_ac_first') }}</p>
          <router-link to="/signin"><button-prime :label="$t('signin')" class="" severity="primary" rounded /></router-link>
          <router-link to="/signin"><button-prime :label="$t('signup')" class="ml-2" severity="primary" rounded /></router-link>
      </dialog-prime>
    </div>
</template>
  

<script>
import HeaderView from '/src/components/Header/HeaderView.vue'
import FooterView from '/src/components/Footer/FooterView.vue'
// import FilterPopup from '/src/components/FilterPopup.vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import axios from 'axios'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation } from 'swiper';
import { Pagination } from 'swiper';
import { Autoplay } from 'swiper';

export default {
    components: {
        HeaderView,
        FooterView,
        Swiper,
        SwiperSlide,
        // FilterPopup
    },
    data() {
        return {
          bannersImage : [],
          places : [],
          modules: [Navigation, Pagination, Autoplay],
          isHidden: false,
          heartValue : false,
          liked_places : [],
          login_alert_modal : false
        }
        
    },
    mounted() {
      this.my_liked_places();
    },
    methods: {
      get_place_name : function(item) {
        var place_name = item.name;
        if (this.amaken_language == 'AR') {
          place_name = item.arabic_name;
        }
        if(place_name == null) {
          place_name = item.name;
        }
        return place_name;
      },
      currency_icons : function(place) {
        if (place.ratings != null) {
          var currencyIcons = '<i class="pi pi-dollar"></i><i class="pi pi-dollar"></i><i class="pi pi-dollar"></i>';
          var price = parseFloat(place.average_price);
          if (price < 100) {
            currencyIcons = '<i class="pi pi-dollar"></i><i class="pi pi-dollar"></i><i class="pi pi-dollar" style="color:lightgray !important"></i>';
          }
          if (price < 10) {
            currencyIcons = '<i class="pi pi-dollar"></i><i class="pi pi-dollar" style="color:lightgray !important"></i><i class="pi pi-dollar" style="color:lightgray !important"></i>';
          }
          return currencyIcons;
        } else {
          return '';
        }
      },
      book : function(place) {
        // axios.get(this.api_url+'is_user_loggedin', {
        //     headers: { 
        //         'Authorization': 'Bearer '+localStorage.getItem('token')
        //     }
        // }).then((response) => {
        //     if (response.data.status == false) {
        //         this.login_alert_modal = true;
        //     } else {
        //         this.$router.push({
        //             path: '/'+place.parentplace.slug+'/reservation',
        //             query : {
        //                 branch : place.slug
        //             }
        //         });
        //     }
        // }).catch((error) => {
        //     console.log(error);
        // });
        
        this.$router.push({
            path: '/'+place.parentplace.slug+'/reservation',
            query : {
                branch : place.slug
            }
        });
    },
      openedOrClosed : function(list) {
        var text = false;
        for (let i = 0; i < list.length; i++) {
          if (list[i].currentday.name == list[i].currentdayname) {
            var startTime = list[i].start_time;
            var endTime = list[i].end_time;
            var currentDate = new Date()   
            var startDate = new Date(currentDate.getTime());
            startDate.setHours(startTime.split(":")[0]);
            startDate.setMinutes(startTime.split(":")[1]);
            startDate.setSeconds(startTime.split(":")[2]);
            var endDate = new Date(currentDate.getTime());
            endDate.setHours(endTime.split(":")[0]);
            endDate.setMinutes(endTime.split(":")[1]);
            endDate.setSeconds(endTime.split(":")[2]);
            var valid = startDate < currentDate && endDate > currentDate;
            if(valid) {
              text = true;
              return text;
            }
          }
        }
        return text;
      },
      addremovelove : function(place_id) {
          axios.get(this.api_url+'submit_like',{
          headers: { 
              'Authorization': 'Bearer '+localStorage.getItem('token')
          },
          params : {
              like : 0,
              place : place_id
          } 
          }).then((response) => {
          if (response.data.status == true) {
              this.my_liked_places();
          }
          });
      },
      set_cuisine_text : function(cuisinedata) {
        var text = '';
        if(cuisinedata.length > 2){
          cuisinedata.length = 2;
        }
        for (let i = 0; i < cuisinedata.length; i++) {
          if(this.amaken_language == 'AR') {
            text += cuisinedata[i].filtername.arabic_name;
          } else if(this.amaken_language == 'EN') {
            text += cuisinedata[i].filtername.name;
          }
          
          if (cuisinedata.length-1 != i) {
            text += ", ";
          }
          
        }
        return text;
      },
      my_liked_places : function() {
          axios.get(this.api_url+'my_liked_places', {
              headers: { 
                  'Authorization': 'Bearer '+localStorage.getItem('token')
              },
          }).then((response) => {
              this.liked_places = response.data.places;
          });
      }
    }
    
};
</script>
  
<style>

</style>
  
  
  