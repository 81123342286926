<template>
        <div class="main-content booking-seating-view" :style="{background : customization.second_box_color}">

            <div class="reservation-page-header" :style="{backgroundColor : customization.header_color, color : customization.text_box_color}">
                <!-- <i :style="{color : customization.text_box_color}" @click="back" class="pi pi-angle-left back-btn-arrow" style="font-size: 1.5rem"></i> -->
                <div class="reservation-header-tabs">
                    <div @click="open_first_view" class="reservation-header-tabContent">
                        <i :style="{color : customization.text_box_color}" class="pi pi-calendar"></i>
                        <p :style="{color : customization.text_box_color}">{{$t('reserve')}}</p>
                    </div>
                    <div class="reservation-header-tabContent">
                        <router-link :to="'/'+$route.params.slug+'/reviews/'">
                            <i :style="{color : customization.text_box_color}" class="pi pi-star-fill"></i>
                            <p :style="{color : customization.text_box_color}">{{$t('reviews')}}</p>
                        </router-link>
                    </div>
                    <div class="reservation-header-tabContent">
                        <router-link :to="'/'+$route.params.slug+'/contact/'">
                            <i :style="{color : customization.text_box_color}" class="pi pi-phone"></i>
                            <p :style="{color : customization.text_box_color}">{{$t('contact')}}</p>
                        </router-link>
                    </div>
                </div>
            </div>
            
            <main>
                <div class="container">
                    <div class="col-12 md:col-12 lg:col-12">
                        <div v-if="customization.banner!=null" class="restaurent-img-thumbnail" :style="{backgroundImage : 'url('+customization.banner+')'}"></div>
                        <div v-else class="restaurent-img-thumbnail" :style="{background : customization.banner_background_color}"></div>

                        <div v-if="customization.logo!=null" class="logo-main-box-container" :style="{backgroundImage : 'url('+customization.logo+')'}"></div>
                        <div v-else class="logo-main-box-container"></div>
                    </div>   
                </div>
                
                <!--First View Start-->
                <div v-if="view=='first'" class="container first-box-element" :style="{background : customization.first_box_color}">
                    <div class="reservation-container second-box-element" id="top" :style="{background : customization.second_box_color}">
                        <h4 :style="{color : customization.text_box_color}">{{$t("find_a_table")}}</h4>
                        
                        <message-prime severity="error" v-if="validationError.length">{{validationError[0]}}</message-prime>
                        <div class="grid m-0">
                            
                            <div class="col-12 md:col-12 lg:col-12 fields-form-row">
                                <label class="reservation-label" :style="{color : customization.text_box_color}">{{$t("guest")}}</label>
                                <div class="reservation-container-guest">
                                    <inputnumber-prime v-model="persons" inputId="horizontal-buttons" showButtons buttonLayout="horizontal" decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"  mode="decimal" :min="1" :max="100" />
                                </div>
                            </div>

                            <div class="col-12 md:col-12 lg:col-12 fields-form-row">
                                <label class="reservation-label" :style="{color : customization.text_box_color}">{{$t("date")}}</label>
                                <calendar-prime class="reservation-calendar" v-model="datetime" />
                            </div>

                            <div class="col-12 md:col-12 lg:col-12 fields-form-row">
                                <label class="reservation-label" :style="{color : customization.text_box_color}">{{$t("select_a_time")}}</label>
                                <calendar-prime id="calendar-timeonly" v-model="datetime" timeOnly showTime hourFormat="12" />
                            </div>
                            
                            <div class="col-12 md:col-12 lg:col-12 fields-form-row reservation-tableDropdown">
                                <label class="reservation-label" :style="{color : customization.text_box_color}">{{$t
                                ("choose_branch")}}</label>
                                <dropdown-prime v-model="selectedBranch" :options="branches" optionLabel="name" class="w-full" />
                            </div>
                            <div class="col-12 md:col-12 lg:col-12">
                                <button-prime :loading="loading" class="find-a-time" @click="get_available_table_details" label="Confirm" severity="success" rounded :style="{color : customization.text_box_color, background : customization.button_box_color}">{{$t("find_a_table")}}</button-prime>
                            </div>
                        </div>
                    </div>
                </div>
                <!--First View End-->

                <!--Second View Start-->
                <div v-if="view=='second'" class="container first-box-element" :style="{background : customization.first_box_color}">
                    <div class="reservation-container grid m-0 second-box-element" :style="{background : customization.second_box_color}">
                        <i @click="back" :style="{color : customization.text_box_color}" class="pi pi-angle-left back-btn-arrow" style="font-size: 1rem;top: 12px;left: 20px;transform: translate(0px);cursor: pointer;z-index: 1;background: #00000094;border-radius: 4px;width: 22px;height: 22px;display: flex;align-items: center;justify-content: center;"></i>
                        <div class="col-12 md:col-12 lg:col-12">
                            <h4 :style="{color : customization.text_box_color}">{{$t("available_time")}}</h4>
                            <div class="select-restaurent-bookingContainer booking-seating-container">
                                <div class="restaurent-book-info">
                                    <!-- <h2 class="restaurantName">{{ place.name }}</h2> -->
                                    <ul>
                                        <li :style="{background : customization.button_box_color}">
                                            <i class="pi pi-calendar" style="font-size: 1rem"></i>
                                            <span :style="{color : customization.text_box_color}">{{formatted_date}}</span>
                                        </li>
                                        <li class="formatted-time" :style="{background : customization.button_box_color}">
                                            <i class="pi pi-clock" style="font-size: 1rem"></i>
                                            <span :style="{color : customization.text_box_color}">{{ formatted_time }}</span>
                                        </li>
                                        <li :style="{background : customization.button_box_color}">
                                            <i class="pi pi-user" style="font-size: 1rem"></i>
                                            <!-- <span>{{ persons }} people (Standard seating)</span> -->
                                            <span :style="{color : customization.text_box_color}">{{ persons }} {{$t('people')}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>   
                            
                            <div class="bookingSeatingContainer" v-if="seating_areas.length>0">
                                <label class="reservation-label" :style="{color : customization.text_box_color}">{{$t('choose_seating')}}</label>
                                <ul class="seating-area-ul">
                                    <li :style="{background : customization.header_color}" class="seating-area-li" v-for="item in seating_areas" :key="item">
                                        <panel-prime v-bind:header="item.name" toggleable collapsed>
                                            <!-- <p class="seating-area-name">{{ item.name }}</p> -->
                                            <div class="seating-area-table" v-if="item.time_slots.length>0">
                                                <div class="select-time-chips-box">
                                                    <div :style="{background : customization.button_box_color}" class="select-time-chips" v-for="itemtimeslot in item.time_slots" :key="itemtimeslot">
                                                        <!-- <router-link :to="'/' +place.slug+'/booking-details?datetime='+reset_date_time(datetime, itemtimeslot)+'&persons='+persons+'&area='+item.id"> -->
                                                            <span @click="select_date_time(itemtimeslot, item)" :style="{color : customization.text_box_color}">{{itemtimeslot}}</span>
                                                        <!-- </router-link> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="seating-area-table" v-else>
                                                <span :style="{color : customization.text_box_color}">{{ $t('no_tables_available') }}</span>
                                            </div>
                                        </panel-prime>
                                    </li>
                                </ul>
                            </div>

                            <div class="bookingSeatingContainer" v-else>
                                <h3 class="detail-text-title" :style="{color : customization.text_box_color}">{{$t('opening_closing_hours')}}</h3>
                            </div>    

                            <div v-if="seating_areas.length>0" class="col-12 md:col-12 lg:col-12 p-0">
                                <button-prime class="find-a-time"  label="Confirm" severity="success" rounded :style="{color : customization.text_box_color, background : customization.button_box_color}">{{$t('find_a_table')}}</button-prime>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Second View End-->

                <!--Third View Start-->
                <div v-if="view=='third'" class="container container first-box-element" :style="{background : customization.first_box_color}">
                    <div class="reservation-container grid m-0 second-box-element content-detail-center" :style="{background : customization.second_box_color}">
                        <i @click="back" :style="{color : customization.text_box_color}" class="pi pi-angle-left back-btn-arrow" style="font-size: 1rem;top: 12px;left: 20px;transform: translate(0px);cursor: pointer;z-index: 1;background: #00000094;border-radius: 4px;width: 22px;height: 22px;display: flex;align-items: center;justify-content: center;"></i>
                        <h4 id="top">{{$t('your_information')}}</h4>
                        <div class="col-12 md:col-12 lg:col-12 pt-0 pb-0">
                            <div class="select-restaurent-bookingDetailContainer">
                                <message-prime severity="error" v-if="validationError.length">{{validationError[0]}}</message-prime>
                                <div class="grid booking-form-area">
                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">{{$t('first_name')}}</label>
                                        <span class="p-input-icon-left">
                                            <inputText-prime class="form-input-fields" v-model="customer.first_name" />
                                        </span>
                                    </div>
                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">{{$t('last_name')}}</label>
                                        <span class="p-input-icon-left">
                                            <inputText-prime class="form-input-fields" v-model="customer.last_name" />
                                        </span>
                                    </div>
                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">{{$t('email')}}</label>
                                        <span class="p-input-icon-left">
                                            <inputText-prime class="form-input-fields" v-model="customer.email" />
                                        </span>
                                    </div>
                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">{{$t('phone_number')}}</label>
                                        <span class="p-input-icon-left">
                                            <vue-tel-input v-model="customer.mobile" mode="international" :inputOptions="options"></vue-tel-input>
                                        </span>
                                    </div>
                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">{{ $t('special_occasions') }}</label>
                                        <span class="p-input-icon-left">
                                            <span class="nationality-dropdown">
                                                <dropdown-prime v-model="customer.selected_ocassion" :options="ocassions" optionLabel="name" class="w-full reservation-dropdown" />
                                            </span>
                                        </span>
                                    </div>
                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">{{ $t('reservation_notes') }}</label>
                                        <span class="p-input-icon-left">
                                            <textarea-prime class="bookingRequestMessage" v-model="customer.special_request" autoResize cols="30" />
                                        </span>
                                    </div>
                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0 reservation-form-continueBtn">
                                        <button-prime :loading="loading" @click="fill_details" :label="$t('continue')" rounded :style="{color : customization.text_box_color, background : customization.button_box_color}"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Third View End-->

                <!--Fourth View Start-->
                <div v-if="view=='fourth'" class="container first-box-element" :style="{background : customization.first_box_color}">
                    <div class="reservation-container grid m-0 second-box-element" :style="{background : customization.second_box_color}">
                        <i @click="back" :style="{color : customization.text_box_color}" class="pi pi-angle-left back-btn-arrow" style="font-size: 1rem;top: 12px;left: 20px;transform: translate(0px);cursor: pointer;z-index: 1;background: #00000094;border-radius: 4px;width: 22px;height: 22px;display: flex;align-items: center;justify-content: center;"></i>
                        <div class="col-12 md:col-12 lg:col-12">
                            <h4 :style="{color : customization.text_box_color}">{{$t('reservation_summary')}}</h4>  

                            <div class="reservation-summary-container">
                                <div class="reservation-summary-info">
                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i class="pi pi-user" :style="{color : customization.text_box_color}"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ customer.first_name }} {{ customer.last_name }}</p></div>
                                    </div>
                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i class="pi pi-envelope" :style="{color : customization.text_box_color}"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ customer.email }}</p></div>
                                    </div>
                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-phone"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ customer.mobile }}</p></div>
                                    </div>
                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-user"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ persons }} {{$t('guest')}}</p></div>
                                    </div>

                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-calendar"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ day }}, {{ date }}</p></div>
                                    </div>

                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-clock"></i></div>
                                        <div class="info-text summery-phone-text"><p :style="{color : customization.text_box_color}">{{ start_time }} - {{ end_time }}</p></div>
                                    </div>
                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-book"></i></div>
                                        <div class="info-text">
                                            <p class="pb-1" :style="{color : customization.text_box_color}">{{$t('reservation_notes')}}</p>
                                            <p :style="{color : customization.text_box_color}">{{ customer.special_request == null ? '-' : customer.special_request }}</p>
                                        </div>
                                    </div>
                                    <div class="reservation-summary-info-list" v-if="customer.selected_ocassion.name!=null">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-star"></i></div>
                                        <div class="info-text">
                                            <p :style="{color : customization.text_box_color}">{{ $t('special_occasions') }}</p>
                                            <p :style="{color : customization.text_box_color}">{{ customer.selected_ocassion.name }}</p>
                                        </div>
                                    </div>
                                    <div class="reservation-summary-info-list reservation-summary-info-listInfo">
                                        <div class="information-text">
                                            <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-info-circle"></i></div>
                                            <div class="info-text">
                                                <p class="pb-1" :style="{color : customization.text_box_color}">{{$t('important_dining_info')}}</p>
                                                <p :style="{color : customization.text_box_color}">{{$t('dinig_info_textFirst')}}<br><br>{{$t('dinig_info_textSecond')}}</p>
                                            </div>
                                        </div>

                                        <div class="information-text">
                                            <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-book"></i></div>
                                            <div class="info-text">
                                                <p class="pb-1" :style="{color : customization.text_box_color}">{{$t('note_from_restaurant')}}</p>
                                                <p :style="{color : customization.text_box_color}">{{ customization.note }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0 checkbox-reservationSummary">
                                    <checkbox-prime v-model="privacyPolicy" inputId="privacyPolicyCheck" name="privacyPolicy" value="" />
                                    <label for="privacyPolicyCheck" class="ml-1" :style="{color : customization.text_box_color}"> {{$t('reservation_summery_Check_TextFirst')}} </label>
                                </div>

                                <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-1 checkbox-reservationSummary">
                                    <checkbox-prime v-model="receiveNews" inputId="receiveNewsCheck" name="receiveNews" value="" />
                                    <label for="receiveNewsCheck" class="ml-1" :style="{color : customization.text_box_color}"> {{$t('reservation_summery_Check_TextSecond')}} </label>
                                </div>
                            </div>

                            <div class="col-12 md:col-12 lg:col-12 p-0">
                                <button-prime class="find-a-time" :loading="loading" @click="reserve" label="Confirm" severity="success" rounded :style="{color : customization.text_box_color, background : customization.button_box_color}">{{$t('continue')}}</button-prime>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Fourth View End-->

                <!--Fifth View Start-->
                <div v-if="view=='fifth'" class="container first-box-element" :style="{background : customization.first_box_color}">
                    <div class="reservation-container grid m-0 second-box-element reservation-thankYou-container" :style="{background : customization.second_box_color}">
                        <div class="col-12 md:col-12 lg:col-12">
                            <h4 :style="{color : customization.text_box_color}">{{$t('thank_you_title')}}</h4>  

                            <div class="content-box">
                                <img class="thankYouEmailImg" src="../assets/images/thankYouEmail.png">
                            </div>

                            <div class="content-box">
                                <p :style="{color : customization.text_box_color}">{{ $t('thanks_you_messege') }}</p>
                            </div>

                            <div class="content-box contact-content-box">
                                <h4 :style="{color : customization.text_box_color}">{{ $t('more_info_contact_us') }}</h4>
                                <p :style="{color : customization.text_box_color}">{{selectedBranch.email}}</p>
                                <p :style="{color : customization.text_box_color}">{{ selectedBranch.mobile }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Fifth View End-->
            </main>
             <div class="reservation-page-footer" :style="{backgroundColor : customization.header_color, color : customization.text_box_color}">
                <p>powered by Amakenapp</p>
            </div>
        </div>

        <!--  -->
        <dialog-prime v-model:visible="cancelReservationPopup" modal header="Cancel Reservation" class="cancelationReserPopup">
            <div class="cancelReser-box">
                <h3 class="font-500">Are you sure you want to cancel this reservation?</h3>

                <h3 class="mt-3 font-500">{{ place.name }}</h3>

                <div class="flex-box-iconText">
                    <span><i class="pi pi-user" style="font-size: 1rem"></i> <span>{{ persons }}</span></span>
                    <span><i class="pi pi-calendar" style="font-size: 1rem"></i> <span>{{ cancel_popup_info.day }}, {{ cancel_popup_info.date }} at {{ cancel_popup_info.time }}</span></span>
                </div>
            </div>

            <div class="message-box">
                <h3 class="font-500">{{ cancelled_message }}</h3>
            </div>

            <template #footer>
                <button-prime label="Nevermind" icon="pi pi-times" class="nevermind" @click="nevermind" text />
                <button-prime label="Confirm cancellation" icon="pi pi-check" :loading="cancelLoader" class="confirmCancel" @click="cancelReservation()" autofocus />
            </template>
        </dialog-prime>
       
        
        
        <div class="card flex justify-content-center">
        

        <dialog-prime
            v-model:visible="validate_booking"
            modal
            :closable = false
            :pt="{
                mask: {
                    style: 'backdrop-filter: blur(2px)'
                }
            }"
        >
                    <h2 style="text-align:center"><b>ALERT</b>!</h2>
                    <p>Booking Management is not enabled for this Place! </p>
               
            
        </dialog-prime>
    </div>

       


</template>

  

<script>
import moment from 'moment'
import axios from 'axios'
import $ from 'jquery'

export default {
    data() {
        return {
            favicon : null,
            validate_booking : false,
            // closeCallback : false,
            cancelled_message : null,
            cancelLoader : false,
            cancel_popup_info : {
                day : null,
                date : null,
                time : null
            },
            branch_slug : null,
            place_slug : null,
            cancelReservationPopup : false,
            unique_id : null,
            options: { placeholder: this.$t('phone_number') },
            view : 'first',
            persons : 1,
            datetime : new Date,
            day : null,
            date : null,
            start_time : null,
            end_time : null,
            selected_date_time : null,
            loading : false,
            validationError : [],
            branches : [],
            selectedBranch : null,
            formatted_date : null,
            formatted_time : null,
            place : {
                name : null
            },
            seating_areas : [],
            selected_seating_area : null,
            customization : {
                banner_background_color : '#313131',
                header_color : '#000000',
                footer_color : '#000000',
                first_box_color : '#212121',
                second_box_color : '#2C2C2C',
                text_box_color : '#FFFFFF',
                field_box_color : '#000000',
                button_box_color : '#884192',
                banner : null,
                logo : null,
                note : null
            },
            ocassions : [
                { name: 'Birthday', code: '1' },
                { name: 'Business Meal', code: '2' },
                { name: 'Celebration', code: '3' }
            ],
            customer : {
                first_name : null,
                last_name : null,
                mobile : null,
                email : null,
                selected_ocassion : {
                    id : null,
                    name : null
                },
                special_request : null,
            },
            login_alert_modal : false,
            from_where : 'socialmedia'
        }
    },
    created() {
        $("body").on("contextmenu", function() {
            return false;
        });
    },
    mounted() {
        this.get_branches();
        this.get_customization_settings();
        this.check_user_login();
        if(this.$route.query.res_id != undefined) {
            this.unique_id = this.$route.query.res_id;
            this.get_reservation_details(this.$route.query.res_id);    
        }
        if(this.$route.query.cancel != undefined && this.$route.query.cancel == "yes") {
            this.cancelReservationPopup = true;
        }
    },

    methods: { 
        nevermind : function() {
            this.cancelReservationPopup = false;
            // this.$router.push({
            //     path: '/'+this.place_slug+'/reservation',
            //     query : {
            //         branch : this.branch_slug
            //     }
            // });
            // location.reload();
            window.location.href = "/"+this.place_slug+"/reservation?branch="+this.branch_slug;
        },
        get_reservation_details : function(unique_id) {
            axios.get(this.api_url+'get_reservation_details', {
                params: { 
                    unique_id: unique_id,
                } 
            }).then((response) => {
                this.customer.first_name = response.data.data.details.customer.first_name;
                this.customer.last_name = response.data.data.details.customer.last_name;
                this.customer.email = response.data.data.details.customer.email;
                this.customer.mobile = response.data.data.details.customer.mobile;
                this.special_request = response.data.data.details.description;
                this.persons = response.data.data.details.persons;
                this.datetime = new Date(response.data.data.details.start_date_time);
                this.place.name = response.data.data.details.place.name;
                this.cancel_popup_info.day = response.data.data.details.dayname;
                this.cancel_popup_info.date = response.data.data.details.date;
                this.cancel_popup_info.time = response.data.data.details.time;
                this.branch_slug = response.data.data.details.place.slug;
                this.place_slug = response.data.data.details.place.parentplace.slug;
            });
        },
        check_user_login : function() {
            axios.get(this.api_url+'is_user_loggedin', {
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                }
            }).then((response) => {
                if (response.data.status == true) {
                    axios.get(this.api_url+'loggedin_user_detail',{
                        headers: { 
                            'Authorization': 'Bearer '+localStorage.getItem('token')
                        } 
                    }).then((response) => {
                        if(response.status == 200) {
                            if (response.data.status == true) {
                                console.log(response.data);
                                this.customer.first_name = response.data.user.first_name;
                                this.customer.last_name = response.data.user.last_name;
                                this.customer.email = response.data.user.email;
                                this.customer.mobile = response.data.user.phone_number;
                            }
                    }
                    }).catch((error) => {
                        console.log('error', error);
                    });
                }
            }).catch((error) => {
                console.log('err', error)
            });
        },
        get_branches : function() {
            axios.get(this.api_url+'get_allowed_booking_branches', {
                params: { 
                    id: this.$route.params.slug,
                } 
            }).then((response) => {
                this.branches = response.data.data.place.branches;
                $('title').text(response.data.data.place.name);
                if (response.data.data.place.favicon != null) {
                    $('link[rel="icon"]').attr('href', response.data.data.place.favicon)
                }
                
                if (this.branches.length==0) {
                    this.validate_booking = true;
                }
                if (this.$route.query.branch != undefined) {
                    for (let i = 0; i < this.branches.length; i++) {
                        if (this.branches[i].slug == this.$route.query.branch) {
                            this.from_where = 'amaken';
                            this.selectedBranch = this.branches[i];
                        }
                    }
                }
            });
        },
        get_customization_settings : function() {
            axios.get(this.api_url+'get_customization', {
                params: { 
                    slug: this.$route.params.slug,
                    type : 'place'
                } 
            }).then((response) => {
                if (response.data.data.customization != '') {
                    this.customization = response.data.data.customization;
                }
                
                this.apply_colors_on_first_view();

            });
        },
        get_available_table_details : function () {
            this.validationError = [];
            if (this.persons==null) {
                this.validationError.push('Please Fill Number of Guests!');
                document.getElementById('top').scrollIntoView();
                return false;
            } 
            if (this.selectedBranch == null) {
                this.validationError.push('Please select branch!');
                document.getElementById('top').scrollIntoView();
                return false;
            }
            this.loading = true;
            this.datetime = moment(new Date(this.datetime));
            var remainder = 15 - (this.datetime.minute() % 15);
            this.datetime = moment(this.datetime).add(remainder, "minutes").format("YYYY-MM-DD HH:mm")+':00';
            axios.get(this.api_url+'get_available_table_details', {
                params: { 
                    branch: this.selectedBranch.id,
                    datetime : this.datetime,
                    persons : this.persons,
                } 
            }).then((response) => {
                this.loading = false;
                this.datetime = moment(this.datetime).format("YYYY-MM-DD HH:mm:ss");
                this.place = response.data.data.place;
                this.seating_areas = response.data.data.seating_areas;
                this.view = 'second';
                this.apply_colors_on_second_view();
                this.loading = false;
                this.formatted_date = moment(this.datetime).format('ddd, MMMM DD');
                this.formatted_time = moment(this.datetime).format('HH:mm A');
            });
        },
        select_date_time : function(timeslot, seating_area) {
            this.selected_seating_area = seating_area.id; 
            this.datetime = moment(this.datetime.split(' ')[0]+' '+timeslot).format('YYYY-MM-DD HH:mm:ss');
            this.view = 'third';
            this.apply_colors_on_third_view();
        },
        fill_details : function() {
            this.validationError = [];
            console.log('this.customer', this.customer);
            if (this.customer.first_name == null || this.customer.first_name == '') {
                this.validationError.push('First name is required!');
                document.getElementById('top').scrollIntoView();
                return false;
            }
            if (this.customer.last_name == null || this.customer.last_name == '') {
                this.validationError.push('Last name is required!');
                document.getElementById('top').scrollIntoView();
                return false;   
            }
            if (this.customer.email == null || this.customer.email == '') {
                this.validationError.push('Email is required!');
                document.getElementById('top').scrollIntoView();
                return false;
            }
            if (this.customer.mobile == null || this.customer.mobile == '') {
                this.validationError.push('Mobile is required!');
                document.getElementById('top').scrollIntoView();
                return false;
            }
            // if (this.customer.selected_ocassion == null || this.customer.selected_ocassion == '') {
            //     this.validationError.push('Ocassion is required!');
            //     document.getElementById('top').scrollIntoView();
            //     return false;
            // }
            this.day = moment(this.datetime).format('dddd');
            this.date = moment(this.datetime).format('DD MMMM YYYY');
            this.start_time = moment(this.datetime).format('hh:mm A');
            this.end_time = moment(moment(this.datetime).add(2, 'hours')).format('hh:mm A');
            this.view = 'fourth';

        },
        reserve : function() {
            this.loading = true;
            this.validationError = [];
            this.success_message = null;
            axios.get(this.api_url+'reserve', {
                // headers: { 
                //     'Authorization': 'Bearer '+localStorage.getItem('token')
                // },
                params: { 
                    branch: this.selectedBranch.id,
                    datetime : this.datetime,
                    persons : this.persons,
                    area : this.selected_seating_area,
                    first_name : this.customer.first_name,
                    last_name : this.customer.last_name,
                    email : this.customer.email,
                    mobile : this.customer.mobile,
                    special_request : this.customer.special_request,
                    from_where : this.from_where,
                    unique_id : this.unique_id
                } 
            }).then((response) => {
                this.loading = false;
                // if (response.data.status == false) {
                //     this.login_alert_modal = true;
                // }
                if(response.data.status == true) {
                    this.view = 'fifth';
                }
            }).catch((error) => {
                this.loading = false;
                if(error.response.status == 401) {
                    this.validationError.push(error.response.data.errors);
                }
            });
        },
        back : function() {
            if (this.view == 'fifth') {
                this.view = 'first';
                this.apply_colors_on_first_view();
                this.datetime = new Date;
            } else if (this.view == 'fourth') {
                this.view = 'third';
                this.apply_colors_on_third_view();
            } else if (this.view == 'third') {
                this.view = 'second';
                this.apply_colors_on_second_view();
            } else if (this.view == 'second') {
                this.datetime = new Date(this.datetime);
                this.view = 'first';
                this.apply_colors_on_first_view();
            } else if (this.view == 'first') {
                this.view = 'first';
                this.apply_colors_on_first_view();
            }
        }, 
        open_first_view : function () {
            this.view = 'first';
            this.datetime = new Date;
        },
        apply_colors_on_first_view : function() {
            setTimeout(() => {
                $('.p-inputtext').css('color', this.customization.text_box_color);
                $('.p-inputtext').css('background', this.customization.field_box_color);
                $('.p-dropdown').css('background', this.customization.field_box_color);
                $('.reservation-container-guest').css('background', this.customization.field_box_color);
                $('.pi-plus').css('color', this.customization.text_box_color);
                $('.pi-minus').css('color', this.customization.text_box_color);
            }, "500");
        },
        apply_colors_on_second_view : function() {
            setTimeout(() => {
                $('.p-panel-title').css('color', this.customization.text_box_color)
                $('.pi-calendar').css('color', this.customization.text_box_color);
                $('.pi-clock').css('color', this.customization.text_box_color);
                $('.pi-user').css('color', this.customization.text_box_color);   
            }, "500");
        },
        apply_colors_on_third_view : function() {
            setTimeout(() => {
                $('.p-inputtext').css('color', this.customization.text_box_color);
                $('.p-inputtext').css('background', this.customization.field_box_color);
                $('.p-dropdown').attr('style', 'background:'+this.customization.field_box_color+' !important;');  
                $('.vue-tel-input').css('background', this.customization.field_box_color);    
                $('.vti__input').css('color', this.customization.text_box_color)  
            }, "500");
        },
        cancelReservation : function() {
            this.cancelLoader = true;
            axios.get(this.api_url+'cancel_reservation', {
                params: { 
                    unique_id: this.unique_id
                } 
            }).then((response) => {
                this.cancelLoader = false;
                this.cancelled_message = response.data.message;
                $('.cancelReser-box').hide();
                $('.message-box').show();
                $('.confirmCancel').hide();
                $('.nevermind').hide();
                setTimeout(() => {
                    // this.$router.push({
                    //     path: '/'+this.place_slug+'/reservation',
                    //     query : {
                    //         branch : this.branch_slug
                    //     }
                    // });
                    window.location.href = "/"+this.place_slug+"/reservation?branch="+this.branch_slug;
                }, "1500");
                
                
            });
            
        }
    }
};
</script>

  
<style scoped>
.bookingSeatingContainer {
    margin-top: 1rem;
}

.bookingSeatingContainer h3.detail-text-title {
    margin-bottom: 1rem;
}

.seating-area-ul li.seating-area-li {
    display: block;
}

p.seating-area-name {
    margin-bottom: 0.3rem;
    font-size: 14px;
    font-weight: 500;
}

.bookingSeatingContainer .select-time-chips-box {
    margin-bottom: 1rem;
}
.booking-seating-view h2.restaurantName {
    margin-top: 0;
    font-size: 30px;
    font-weight: 500;
}
.reservation-summary-info-list {
    display: flex;
    align-items: flex-start;
    column-gap: 0.8rem;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
}

.reservation-summary-info-list .info-icon {
    display: flex;
    align-items: center;
    transform: translateY(3px);
}

.reservation-summary-info-list .info-icon i.pi {
    color: #fff;
}

.reservation-summary-info-list .info-text p {
    color: #fff;
    font-size: 14px;
}

.reservation-summary-container {
    margin-top: 1.5rem;
}
.reservation-summary-info-list {
    display: flex;
    align-items: flex-start;
    column-gap: 0.8rem;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
}

.reservation-summary-info-list .info-icon {
    display: flex;
    align-items: center;
    transform: translateY(3px);
}

.reservation-summary-info-list .info-icon i.pi {
    color: #fff;
}

.reservation-summary-info-list .info-text p {
    color: #fff;
    font-size: 14px;
}

.reservation-summary-container {
    margin-top: 1.5rem;
}

img.thankYouEmailImg {
    width: 50%;
    margin: auto;
    display: flex;
}

.content-box {
    margin-top: 2.5rem;
}

.reservation-thankYou-container .content-box p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

.reservation-thankYou-container .content-box h4 {
    margin-top: 0;
    font-size: 16px !important;
    margin-bottom: 0.5rem;
}

.reservation-thankYou-container .content-box.contact-content-box p {
    margin-bottom: 0.5rem;
}
</style>