<template>
    <HeaderView />

    <div class="main-content">
      <div class="profile-setting-middleArea pb-6">
        <div class="grid m-0 profile-setting-reverse">
          <div class="col-12 md:col-12 lg:col-6">
            <h2 class="setting-profileTitle">{{$t('profile')}}</h2>
            <message-prime severity="error" v-if="profileValidationErrors.length">{{profileValidationErrors[0]}}</message-prime>
            <message-prime severity="success" v-if="success_profile_save_message != null">{{success_profile_save_message}}</message-prime>
            <div class="grid mt-3 profile-setting-fieldsSetting-left">
              <div class="col-12 md:col-12 lg:col-6">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                          <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                          <inputText-prime class="form-input-fields" v-model="profile.first_name" :placeholder="$t('first_name')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-6">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                          <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                          <inputText-prime class="form-input-fields" v-model="profile.last_name" :placeholder="$t('last_name')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-6">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                          <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                          <inputText-prime class="form-input-fields" v-model="profile.email" :placeholder="$t('email')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-6">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                          <i class="pi pi-calendar" style="font-size: 1.2rem"></i>
                          <calendar-prime class="form-input-fields"  placeholder="Date of Birth" @selected="dateSelect" v-model="profile.date_of_birth" dateFormat="dd MM yy"></calendar-prime>
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-6">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left nationality-dropdown">
                        <i class="pi pi-id-card" style="font-size: 1.2rem"></i>
                        <!-- <inputText-prime class="form-input-fields" v-model="value1" placeholder="Nationality" /> -->
                        <dropdown-prime @change="get_cities" v-model="profile.country" filter :options="countries_list" optionLabel="name" :placeholder="$t('country')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-6">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left nationality-dropdown">
                        <i class="pi pi-id-card" style="font-size: 1.2rem"></i>
                        <!-- <inputText-prime class="form-input-fields" v-model="value1" placeholder="Nationality" /> -->
                        <dropdown-prime v-model="profile.city" :options="cities_list" filter optionLabel="name" :placeholder="$t('city')" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-6">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                        <i class="pi pi-map-marker" style="font-size: 1.2rem"></i>
                        <inputText-prime class="form-input-fields" v-model="profile.address" :placeholder="$t('address')" />
                      </span>
                  </div>
              </div>
          </div>
          <button-prime :label="$t('save_changes')" class="savechanges-btn" :loading="loading_profile_save" @click="save_profile_details" severity="success" rounded />
          <br/>
            <h2 class="setting-profileTitle">{{$t('password')}}</h2>
            <message-prime severity="error" v-if="passwordValidationErrors.length">{{passwordValidationErrors[0]}}</message-prime>
            <message-prime severity="success" v-if="success_password_save_message != null">{{success_password_save_message}}</message-prime>
            <div class="grid mt-3">
              <div class="col-12 md:col-12 lg:col-6">
                <div class="form-fields-main">
                    <span class="p-input-icon-left">
                        <img class="inputFields-icons" src="../assets/Icons/password-icon.svg">
                        <password-prime class="form-input-fields" v-model="profile.current_password" toggleMask :feedback="false" :placeholder="$t('current_pass')" />
                    </span>
                </div>
              </div>
            </div>

            <div class="grid">
              <div class="col-12 md:col-12 lg:col-6">
                <div class="form-fields-main">
                    <span class="p-input-icon-left">
                        <img class="inputFields-icons" src="../assets/Icons/password-icon.svg">
                        <password-prime class="form-input-fields" v-model="profile.new_password" toggleMask :feedback="false" :placeholder="$t('new_pass')" />
                    </span>
                </div>
              </div>

              <div class="col-12 md:col-12 lg:col-6">
                <div class="form-fields-main">
                    <span class="p-input-icon-left">
                        <img class="inputFields-icons" src="../assets/Icons/password-icon.svg">
                        <password-prime class="form-input-fields" v-model="profile.confirm_new_password" toggleMask :feedback="false" :placeholder="$t('confirm_new_pass')" />
                    </span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-12 lg:col-6">
            <message-prime severity="error" v-if="uploadValidationErrors.length">{{uploadValidationErrors[0]}}</message-prime>
            <message-prime severity="success" v-if="uploadSuccessMessage != null">{{uploadSuccessMessage}}</message-prime>
            <div class="profile-top-left mt-6 profile-setting-fieldsSetting-right">
                <!-- <div class="user-profile-image">
                  <avatar-prime v-if="profile.profile_image!=null" :image="profile.profile_image" class="mr-2" size="xlarge" shape="circle" />
                  <avatar-prime v-else  class="mr-2" size="xlarge" shape="circle" />
                </div> -->

                <div class="user-profile-image">
                  <div class="user-profile-imageBox" v-if="profile.profile_image!=null" v-bind:style="{'background-image': 'url('+profile.profile_image+'?version='+profile.profile_image_id+')'}"></div>
                  <div class="user-profile-imageBox" v-else></div>
                </div>
                
                <div class="user-profile-topInfo">
                  <div class="user-address">

                    <!-- <input type="file" class="upload-image-btn mr-2"> -->
                    <label class="upload-image-btn mr-2" for="uploadProfile-image">{{ $t('upload_image') }}</label>
                    <input type="file" id="uploadProfile-image" @change="handleFileUpload( $event )"/>
                  </div>
                  <label class="mt-2 block">{{$t('allowed_img_ext')}}</label>
                </div>
              </div>
          </div>
        </div>

        <button-prime :label="$t('save_changes')" :loading=loading_password_save @click="change_password" class="savechanges-btn" severity="success" rounded />
      </div>

    </div>

    <FooterView />
</template>

<script>
  import HeaderView from '/src/components/Header/HeaderView.vue'
  import FooterView from '/src/components/Footer/FooterView.vue'
  import axios from 'axios'
  import $ from 'jquery'
  // import Datepicker from 'vue3-datepicker'
/* eslint-disable global-require */
export default {
  components: {
    HeaderView,
    FooterView,
    // Datepicker
  },
  data() {
    return {
      profile : {
        first_name : null,
        last_name : null,
        email : null,
        date_of_birth : null,
        country : null,
        city :  null,
        address : null,
        profile_image : null,
        profile_image_id : Math.random(1000),
        current_password : null,
        new_password : null,
        confirm_new_password : null
      },
      countries_list : [],
      cities_list : [],
      profileValidationErrors : [],
      success_profile_save_message : null,
      loading_profile_save : false,
      passwordValidationErrors : [],
      success_password_save_message : null,
      loading_password_save : false,
      upload_image_url : this.api_url+'upload_profile_image',
      uploadValidationErrors : [],
      uploadSuccessMessage : null,
      upload_file : null
    }
  },
  beforeMount() {
    
  },
  mounted() {
    this.get_profile_detail();
  },
  methods : {
    handleFileUpload(event){
      this.uploadValidationErrors = [];
      this.uploadSuccessMessage = null;
      this.profile.upload_file = this.file = event.target.files[0];
      let formData = new FormData();
      formData.append('file', this.profile.upload_file);
      axios.post(this.upload_image_url,
        formData,
        {
          headers: { 
            'Authorization': 'Bearer '+localStorage.getItem('token')
          },
        }
      ).then((response) => {
        this.profile.profile_image = response.data.image_url;
        this.profile.profile_image_id = response.data.id
        this.uploadSuccessMessage = response.data.message;
        $('.user-profile-imageBox').css('background-image', 'url('+this.profile.profile_image+'?version='+this.profile.profile_image_id+')')
      }).catch((error) => {
          if(error.response.status == 401) {
              this.uploadValidationErrors.push(error.response.data.errors);
          }
      });

    },  
    change_password : function() {
      this.loading_password_save = true;
      this.passwordValidationErrors = [];
      axios.get(this.api_url+'change_password',{
        headers: { 
            'Authorization': 'Bearer '+localStorage.getItem('token')
        },
        params : {
          current_password : this.profile.current_password,
          new_password : this.profile.new_password,
          confirm_new_password : this.profile.confirm_new_password,
          lang : this.amaken_language
        } 
      }).then((response) => {
          this.loading_password_save = false;
          if(response.status == 200) {
              this.success_password_save_message = response.data.message;
          }
      }).catch((error) => {
          this.loading_password_save = false;
          if(error.response.status == 401) {
              this.passwordValidationErrors.push(error.response.data.errors);
          }
      });
    },
    save_profile_details : function() {
      this.loading_profile_save = true;
      this.profileValidationErrors = [];
      this.success_profile_save_message = null;
      axios.get(this.api_url+'save_profile_details',{
        headers: { 
            'Authorization': 'Bearer '+localStorage.getItem('token')
        },
        params : {
          first_name : this.profile.first_name,
          last_name : this.profile.last_name,
          email : this.profile.email,
          date_of_birth : this.profile.date_of_birth,
          country : this.profile.country,
          city : this.profile.city,
          address : this.profile.address,
          lang : this.amaken_language
        } 
      }).then((response) => {
          this.loading_profile_save = false;
          if(response.status == 200) {
              this.success_profile_save_message = response.data.message;
          }
      }).catch((error) => {
          this.loading_profile_save = false;
          if(error.response.status == 401) {
              this.profileValidationErrors.push(error.response.data.errors);
          }
      });
    },
    get_cities : function() {
      axios.get(this.api_url+'get_cities',{
        headers: { 
            'Authorization': 'Bearer '+localStorage.getItem('token')
        },
        params : {
          country : this.profile.country
        } 
      }).then((response) => {
          if(response.status == 200) {
              if (response.data.status == true) {
                  this.cities_list = response.data.cities;
              } 
          }
      }).catch((error) => {
          console.log('error', error);
      });
    },
    get_profile_detail : function() {
      axios.get(this.api_url+'profile_details',{
        headers: { 
            'Authorization': 'Bearer '+localStorage.getItem('token')
        } 
      }).then((response) => {
          if(response.status == 200) {
              if (response.data.status == true) {
                  this.profile.first_name = response.data.user.first_name;
                  this.profile.last_name = response.data.user.last_name;
                  this.profile.email = response.data.user.email;
                  this.profile.date_of_birth = new Date(response.data.user.birth_date);
                  this.profile.profile_image = response.data.user.profile_image;
                  this.profile.country = response.data.user.country;
                  this.profile.city = response.data.user.city;
                  this.profile.address = response.data.user.address;
                  this.countries_list = response.data.countries;
                  this.get_cities();
              } else {
                this.$router.push({
                  name : 'signin'
                })
              }
          }
      }).catch((error) => {
          console.log('error', error);
      });
    }
  }
}
/* eslint-disable global-require */
</script>

<style>
.cover-bg-profile {
    background: url('~@/assets/images/sliders/slide-1.png');
    height: 350px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.profile-top-header {
    box-shadow: 0 3px 10px #00000029;
    height: 150px;
    width: 100%;
    border-radius: 10px;
}

.user-profile-image .p-avatar.p-component.p-avatar-image.p-avatar-circle.p-avatar-xl {
    width: 8rem;
    height: 8rem;
    overflow: hidden;
}

.user-profile-image .p-avatar.p-component.p-avatar-image.p-avatar-circle.p-avatar-xl img {
    max-width: 100%;
    width: initial;
    height: initial;
}

.profileInner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  transform: translateY(-25px);
}

.profile-top-right {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.profile-top-right button.p-button.p-component.p-button-rounded span.p-button-label {
  font-size: 16px;
  font-weight: 400;
}

.profile-top-left {
    display: flex;
    align-items: center;
}

.user-profile-topInfo {
    padding-left: 0.6rem;
}

.user-name {
    font-size: 25px;
    font-weight: 500;
}

.user-address {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.profile-setting-middleArea {
    margin-top: 1rem;
}

h2.setting-profileTitle {
    font-weight: 500;
}

.profile-setting-fieldsSetting-right {
  padding-left: 1rem;
}

.profile-setting-cover-bg {
    display: none;
}

button.p-button.p-component.p-button-rounded.savechanges-btn {
  display: flex;
  box-shadow: none;
}

button.p-button.p-component.p-button-rounded.upload-image-btn {
  display: flex;
  background: #0091fb;
  border: 1px solid #0091fb;
  box-shadow: none;
}

button.p-button.p-component.p-button-rounded.upload-image-btn span.p-button-label {
    font-weight: 500;
}

button.p-button.p-component.p-button-rounded.savechanges-btn span.p-button-label {
  font-weight: 500;
  font-size: 16px;
}

.form-fields-main .p-password.form-input-fields.p-input-icon-right > svg:last-of-type {
    right: 1.5rem;
    color: #6c757d;
}

label.upload-image-btn {
    color: #ffffff;
    background: #3B82F6;
    border: 1px solid #3B82F6;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 1000px;
    cursor: pointer;
}

#uploadProfile-image {
  visibility: hidden;
}

.user-profile-image {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    overflow: hidden;
}

.user-profile-imageBox {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
</style>