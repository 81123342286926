<template>
        <div class="main-content booking-seating-view" :style="{background : customization.second_box_color}">
            <ReservationHeaderView :customization="customization" />

            <main>
                <div class="container">
                    <div class="col-12 md:col-12 lg:col-12">
                        <div v-if="customization.banner!=null" class="restaurent-img-thumbnail" :style="{backgroundImage : 'url('+customization.banner+')'}"></div>
                        <div v-else class="restaurent-img-thumbnail" :style="{background : customization.banner_background_color}"></div>

                        <div v-if="customization.logo!=null" class="logo-main-box-container" :style="{backgroundImage : 'url('+customization.logo+')'}"></div>
                        <div v-else class="logo-main-box-container"></div>
                    </div>   
                </div>
                <div class="container container first-box-element" :style="{background : customization.first_box_color}">
                    <div class="reservation-container grid m-0 second-box-element content-detail-center" :style="{background : customization.second_box_color}">
                        <h4>Your Information</h4>
                        <!-- <div class="col-12 md:col-8 lg:col-8">
                            <div class="select-restaurent-bookingContainer">
                                <div class="restaurent-img-thumbnail"></div>

                                <div class="restaurent-book-info">
                                    <h2 class="restaurantName">{{ place.name }}</h2>
                                    <ul>
                                        <li>
                                            <i class="pi pi-calendar" style="font-size: 1rem"></i>
                                            <span>{{formatted_date}}</span>
                                        </li>
                                        <li>
                                            <i class="pi pi-clock" style="font-size: 1rem"></i>
                                            <span>{{ formatted_time }}</span>
                                        </li>
                                        <li>
                                            <i class="pi pi-user" style="font-size: 1rem"></i>
                                            <span>{{ persons }} people (Standard seating)</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="">
                                <aside class="booking-policies-section">
                                    <h1 class="info-box-title">What to know before you go</h1>
                                    <section class="info-box-sec">
                                        <h1 class="info-box-subTitle">Important dining information</h1>
                                        <p>We have a 15 minute grace period. Please call us if you are running later than 15 minutes after your reservation time.<br><br>Your table will be reserved for 2 hours for parties of up to 4; 2 hours 30 minutes for parties of up to 6; and 3 hours for parties of 7+.</p>
                                    </section>
                                    <section class="info-box-sec">
                                        <h1 class="info-box-subTitle">A note from the restaurant</h1>
                                        <p>Families with children below 12yrs are welcome to dine with us until 9PM.
                                            Smoking: Not permitted indoors (including e-cigarettes), only at the terrace.
                                            Dress code: Smart casual (no flip flops, no tank tops, no gym nor beach wear).
                                            Discounts are not applicable during Public Holidays, special offers, promotions, set menus and brunches.
                                        </p>
                                    </section>
                                </aside>
                            </div>
                        </div> -->

                        <div class="col-12 md:col-12 lg:col-12 pt-0 pb-0">
                            <div class="select-restaurent-bookingDetailContainer">
                                <!-- <h3 class="booking-detail-title">You’re almost done!</h3>
                                <h3 class="detail-text-title">Details</h3> -->
                                <message-prime severity="error" v-if="validationError.length">{{validationError[0]}}</message-prime>
                                <message-prime severity="success" v-if="success_message != null">{{success_message}}</message-prime>
                                <div class="grid booking-form-area">
                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">First Name</label>
                                        <span class="p-input-icon-left">
                                            <inputText-prime class="form-input-fields" v-model="first_name" />
                                        </span>
                                    </div>

                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">Last Name</label>
                                        <span class="p-input-icon-left">
                                            <inputText-prime class="form-input-fields" v-model="last_name" />
                                        </span>
                                    </div>

                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">Email</label>
                                        <span class="p-input-icon-left">
                                            <inputText-prime class="form-input-fields" v-model="email" />
                                        </span>
                                    </div>

                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">Phone Number</label>
                                        <span class="p-input-icon-left">
                                            <inputText-prime class="form-input-fields" v-model="mobile" />
                                        </span>
                                    </div>

                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">Special Occasions</label>
                                        <span class="p-input-icon-left">
                                            <span class="nationality-dropdown">
                                                <dropdown-prime  :options="ocassions" optionLabel="name" class="w-full reservation-dropdown" />
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0">
                                        <label class="reservation-label" :style="{color : customization.text_box_color}">Reservation Notes</label>
                                        <span class="p-input-icon-left">
                                            <textarea-prime class="bookingRequestMessage" v-model="special_request" autoResize cols="30" />
                                        </span>
                                    </div>
                                    
                                    <!-- <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0 mt-1 checkbox-booking-page ">
                                        <checkbox-prime v-model="signIn" inputId="signInCheck" name="signIn" value="" />
                                        <label for="signInCheck" class="ml-1"> Sign me up to receive dining offers and news from this restaurant by email. </label>
                                    </div>

                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0 checkbox-booking-page ">
                                        <checkbox-prime v-model="signUp" inputId="signUpCheck" name="signUp" value="" />
                                        <label for="signUpCheck" class="ml-1"> Sign me up to receive dining offers and news from Amaken by email. </label>
                                    </div>

                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-1 checkbox-booking-page ">
                                        <checkbox-prime v-model="reminder" inputId="reminderCheck" name="reminder" value="" />
                                        <label for="reminderCheck" class="ml-1"> Yes, I want to get text updates and reminders about my reservations. </label>
                                    </div> -->

                                    <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0 reservation-form-continueBtn">
                                        <!-- <router-link to="/reservation-summary"> -->
                                            <button-prime :loading="loading" @click="reserve" label="Continue" rounded :style="{color : customization.text_box_color, background : customization.button_box_color}"/>
                                        <!-- </router-link> -->
                                    </div>
                                    
                                    <!-- <div class="form-fields-main col-12 md:col-12 lg:col-12">
                                        <p class="booking-detail-footerText">By clicking “Complete reservation” you agree to the Amaken <router-link to="/">Terms of Use</router-link> and <router-link to="/">Privacy Policy</router-link>. Message & data rates may apply. You can opt out of receiving text messages at any time in your account settings or by replying STOP.</p>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

           <ReservationFooterView :customization="customization" />
        </div>

    <div class="card flex justify-content-center text-center">
      <dialog-prime class="login-popup-modal" v-model:visible="login_alert_modal" modal header="" :style="{ width: '50vw', textAlign : 'center' }">
          <p>{{ $t('login_your_ac_first') }}</p>
          <router-link to="/signin"><button-prime :label="$t('signin')" class="" severity="primary" rounded /></router-link>
          <router-link to="/signin"><button-prime :label="$t('signup')" class="ml-2" severity="primary" rounded /></router-link>
      </dialog-prime>
    </div>
</template>
  
  


<script>
import ReservationHeaderView from '/src/components/reservationHeader.vue'
import ReservationFooterView from '/src/components/reservationFooter.vue'
import axios from 'axios'
import moment from 'moment'
import $ from 'jquery'

export default {
    components: {
        ReservationHeaderView,
        ReservationFooterView,
    },
    data() {
        return {
            ocassions : [
                { name: 'Birthday', code: '1' },
                { name: 'Business Meal', code: '2' },
                { name: 'Celebration', code: '3' }
            ],
            persons : 1,
            area : null,
            datetime : null,
            formatted_date : null,
            formatted_time : null,
            place : {
                name : null
            },
            first_name : null,
            last_name : null,
            mobile : null,
            email : null,
            special_request : null,
            success_message : null,
            validationError : [],
            loading : false,
            login_alert_modal : false,
            customization : {
                banner_background_color : '#313131',
                header_color : '#000000',
                footer_color : '#000000',
                first_box_color : '#212121',
                second_box_color : '#2C2C2C',
                text_box_color : '#FFFFFF',
                field_box_color : '#000000',
                button_box_color : '#884192',
                banner : null,
                logo : null
            }
        }
    },
    mounted() {
        this.get_customization_settings();
        this.datetime = this.$route.query.datetime;
        this.formatted_date = moment(this.$route.query.datetime).format('ddd, MMMM DD');
        this.formatted_time = moment(this.$route.query.datetime).format('HH:mm A');
        this.persons = this.$route.query.persons;
        this.area = this.$route.query.area;
        this.get_place_name();
    },
    methods: { 
        reserve : function() {
            this.loading = true;
            this.validationError = [];
            this.success_message = null;
            axios.get(this.api_url+'reserve', {
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                },
                params: { 
                    slug: this.$route.params.slug,
                    datetime : this.$route.query.datetime,
                    persons : this.$route.query.persons,
                    area : this.$route.query.area,
                    first_name : this.first_name,
                    last_name : this.last_name,
                    email : this.email,
                    mobile : this.mobile,
                    special_request : this.special_request,
                } 
            }).then((response) => {
                this.loading = false;
                if (response.data.status == false) {
                    this.login_alert_modal = true;
                }
                if(response.data.status == true) {
                    this.success_message = response.data.message;
                    this.$router.push({
                        path: '/'+this.$route.params.slug+'/reservation-summary', 
                        query: {
                            id : btoa(response.data.id)
                        } 
                    });
                }
            }).catch((error) => {
                this.loading = false;
                if(error.response.status == 401) {
                    this.validationError.push(error.response.data.errors);
                }
            });
        },
        get_place_name : function() {
            axios.get(this.api_url+'get_place_name', {
                params: { 
                    slug: this.$route.params.slug,
                } 
            }).then((response) => {
                this.place = response.data.data.place;
            });
        },
        get_customization_settings : function() {
            axios.get(this.api_url+'get_customization', {
                params: { 
                    slug: this.$route.params.slug,
                    type : 'branch'
                } 
            }).then((response) => {
                if (response.data.data.customization != '') {
                    this.customization = response.data.data.customization;
                }
                $('.p-inputtext').css('color', this.customization.text_box_color);
                $('.p-inputtext').css('background', this.customization.field_box_color);
                $('.p-dropdown').attr('style', 'background:'+this.customization.field_box_color+' !important;');
            });
        }
    }
};
</script>

<style>
.widget-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.widget-social-icon {
    margin-top: 0.6rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.widget-social-icon .social-icon-circle {
  color: #000;
    font-size: 16px;
    margin-right: 15px;
    background: #fff !important;
    width: 40px;
    height: 40px;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
}
.widget-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.widget-social-icon a {
    display: flex;
    color: #000;
    align-items: center;
    margin-bottom: 0.5rem;
}

.widget-social-icon a span {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
}

/* Booking Detail Page Css Start */
.booking-detail-view.main-content {
    width: 100%;
    /* height: 100vh; */
    padding: 0 20px;
    padding-top: 100px;
    padding-bottom: 2rem;
}

h3.booking-detail-title {
    font-weight: 500;
}

.booking-detail-view h2.restaurantName {
    margin-top: 0;
    font-size: 30px;
    font-weight: 500;
}

.select-restaurent-bookingContainer {
    /* margin-top: 1rem; */
    /* display: flex;
    align-items: flex-start;
    column-gap: 1rem; */
    padding-bottom: 1rem;
    padding-top: 1rem;
    /* flex-direction: column; */
}

h3.detail-text-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.info-box-title {
    margin: 25px 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 15px;
}

.info-box-subTitle {
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.25rem;
    margin-bottom: 0.3rem;
}

.info-box-sec {
    margin: 16px 0 0;
    white-space: pre-line;
}

aside.booking-policies-section {
    /* padding-left: 1.5rem; */
}

.select-restaurent-bookingDetailContainer {
    /* padding: 1.5rem 2rem; */
    /* border: 1px solid #ededed; */
    /* border-radius: 6px; */
    padding-bottom: 0;
}

.select-restaurent-bookingDetailContainer input.p-inputtext.p-component.form-input-fields {
    border-radius: 6px !important;
    padding-left: 1rem;
    min-height: 45px;
    height: 45px;
    text-align: left;
    font-weight: 400;
}

.booking-form-area button.p-button.p-component.p-button-rounded {
    border-radius: 6px;
    border: 0;
}

.select-restaurent-bookingDetailContainer .p-checkbox-box {
    border-radius: 3px;
}

.select-restaurent-bookingDetailContainer .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #0091f6 !important;
}

.select-restaurent-bookingDetailContainer .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #0091f6 !important;
    background: #0091f6 !important;
}

.select-restaurent-bookingDetailContainer textarea.p-inputtextarea.p-inputtext.p-component.p-inputtextarea-resizable.bookingRequestMessage {
    width: 100% !important;
    min-height: 100px;
    resize: auto;
    border: 0;
    width: 100%;
    border-radius: 6px;
    background: #000000;
    box-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding-left: 1rem;
    color: #fff;
}

.select-restaurent-bookingDetailContainer a {
    color: #0091fb;
}

p.booking-detail-footerText {
    font-size: 14px;
}

.checkbox-booking-page {
    display: flex;
    align-items: flex-start;
    column-gap: 0.3rem;
}

.select-restaurent-bookingDetailContainer .nationality-dropdown .p-dropdown {
    padding-left: 1rem;
    border-radius: 6px !important;
    align-items: center !important;
    background: #000000 !important;
    font-size: 14px;
    height: 45px !important;
}

.select-restaurent-bookingDetailContainer .nationality-dropdown .p-dropdown span.p-dropdown-label.p-inputtext.p-placeholder {
    padding: 0.75rem 0.75rem;
    padding-left: 0;
    font-size: 14px;
}

.select-restaurent-bookingDetailContainer .reservation-dropdown.p-dropdown .p-dropdown-trigger {
    justify-content: center !important;
}

.select-restaurent-bookingDetailContainer .reservation-dropdown.p-dropdown .p-dropdown-label {
    padding: 0.75rem 0.75rem !important;
    padding-left: 0 !important;
    font-size: 14px;
    color: #fff;
}

.details-box-right-inner {
    padding-left: 2rem;
}

.grid.booking-form-area {
    row-gap: 0.3rem;
    margin-top: 0.2rem;
    padding-bottom: 0 !important;
}

.content-detail-center {
    justify-content: center !important;
}
</style>
  
  
  