<template>
    <HeaderView />

    <div class="main-content">

      <!-- Main Slides Area -->
      <div class="main-slide-container inner-sec-bg">
      <div class="slides-bg">

          <div class="section-title">
          <h2>Advertise With Us</h2>
          </div>
      </div>
      </div>
      <!-- / Main Slides Area -->

      <div class="profile-setting-middleArea pb-6">
        <div class="grid m-0 profile-setting-reverse">
          <div class="col-12 md:col-12 lg:col-6">
            <!-- <h2 class="setting-profileTitle"></h2> -->

            <div class="grid mt-3 profile-setting-fieldsSetting-left">
              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                          <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                          <inputText-prime class="form-input-fields" v-model="value1" placeholder="Full Name" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                          <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                          <inputText-prime class="form-input-fields" v-model="value1" placeholder="Last Name" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                        <i class="pi pi-map-marker" style="font-size: 1.2rem"></i>
                        <inputText-prime class="form-input-fields" v-model="value1" placeholder="Address" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main">
                      <span class="p-input-icon-left">
                        <i class="pi pi-home" style="font-size: 1.2rem"></i>
                        <inputText-prime class="form-input-fields" v-model="value1" placeholder="Hometown" />
                      </span>
                  </div>
              </div>

              <div class="col-12 md:col-12 lg:col-8">
                  <div class="form-fields-main mb-0">
                      <span class="p-input-icon-left">
                        <textarea-prime v-model="value" autoResize rows="5" cols="30" placeholder="Write Description here" />
                      </span>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="addFilter-item mb-4">
            <div class="flex align-items-center addFilter-itemCheckbox">
                <checkbox-prime v-model="filtercheck" inputId="ingredient1" name="filtercheck" value="1" />
                <label for="ingredient1" class="ml-2"> I agree to the terms and conditions as set out by the user agreement </label>
            </div>
        </div>

        <button-prime label="Submit" class="savechanges-btn" severity="success" rounded />
      </div>

    </div>

    <FooterView />
</template>

<script setup>
import { ref } from "vue";
const value = ref(null);
const filtercheck = ref(false);
</script>

<script>
import HeaderView from '/src/components/Header/HeaderView.vue'
import FooterView from '/src/components/Footer/FooterView.vue'

/* eslint-disable global-require */
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
    }
  },
}
/* eslint-disable global-require */
</script>

<style>
.main-slide-container.inner-sec-bg {
    background: url('~@/assets/images/sliders/slide-1.png');
    height: 350px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.main-slide-container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000059;
    width: 100%;
    height: 100%;
}

.section-title h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.4;
}

.section-title {
    position: absolute;
    bottom: 60px;
    left: 40px;
}

.slides-bg {
    position: relative;
    height: 100%;
}

.content-area {
    height: 100vh;
}

.cover-bg-profile.inner-sec-bg {
    /* background: url('src/assets/images/sliders/slide-1.png'); */
    height: 350px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.profile-top-header {
    box-shadow: 0 3px 10px #00000029;
    height: 150px;
    width: 100%;
    border-radius: 10px;
}

.user-profile-image .p-avatar.p-component.p-avatar-image.p-avatar-circle.p-avatar-xl {
    width: 8rem;
    height: 8rem;
}

.profileInner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  transform: translateY(-25px);
}

.profile-top-right {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.profile-top-right button.p-button.p-component.p-button-rounded span.p-button-label {
  font-size: 16px;
  font-weight: 400;
}

.profile-top-left {
    display: flex;
    align-items: center;
}

.user-profile-image {
    /* transform: translateY(-15px); */
}

.user-profile-topInfo {
    /* transform: translateY(-10px); */
    padding-left: 0.6rem;
}

.user-name {
    font-size: 25px;
    font-weight: 500;
}

.user-address {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.profile-setting-middleArea {
    margin-top: 1rem;
}

h2.setting-profileTitle {
    font-weight: 500;
}

/* .profile-setting-fieldsSetting-left {
  padding-right: 2rem;
} */

.profile-setting-fieldsSetting-right {
  padding-left: 1rem;
}

.profile-setting-cover-bg {
    display: none;
}

button.p-button.p-component.p-button-rounded.savechanges-btn {
  display: flex;
  box-shadow: none;
}

button.p-button.p-component.p-button-rounded.upload-image-btn {
  display: flex;
  background: #0091fb;
  border: 1px solid #0091fb;
  box-shadow: none;
}

button.p-button.p-component.p-button-rounded.savechanges-btn span.p-button-label {
  font-weight: 500;
  font-size: 16px;
}

.form-fields-main .p-password.form-input-fields.p-input-icon-right > svg:last-of-type {
    right: 1.5rem;
    color: #6c757d;
}

textarea.p-inputtextarea.p-inputtext.p-component.p-inputtextarea-resizable:not(.bookingRequestMessage):not(.make-review-textarea) {
  border: 0;
    width: 100%;
    border-radius: x;
    background: #f4f4f4;
    box-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 150px !important;
    padding-left: 1rem;
}

.addFilter-item .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #0091fb;
    background: #0091fb;
}

.addFilter-item .p-checkbox .p-checkbox-box {
    border-radius: 4px;
    width: 20px;
    height: 20px;
    box-shadow: none;
}

</style>