<template>
    <div class="reservation-page-header" :style="{backgroundColor : customization.header_color, color : customization.text_box_color}">
        <i class="pi pi-angle-left back-btn-arrow" style="font-size: 1.5rem"></i>
        <div class="reservation-header-tabs">
            <div class="reservation-header-tabContent">
                <i class="pi pi-calendar"></i>
                <p>Reserve</p>
            </div>
            <div class="reservation-header-tabContent">
                <router-link to="/reviews">
                    <i class="pi pi-star-fill"></i>
                    <p>Reviews</p>
                </router-link>
            </div>
            <div class="reservation-header-tabContent">
                <router-link to="/contact">
                    <i class="pi pi-phone"></i>
                    <p>Contact</p>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['customization'],
    data() {
        return {
            
        }
    },

    mounted() {
        
    },

    methods: { 
        
    }
};
</script>