<template>
    <div class="verifyotp-wrapper">
        <div class="grid m-0">
            <div class="col-12 md:col-6 lg:col-6 signIn-form-leftArea">
                <div class="verify-otp-area">
                    <router-link to="/">
                        <div class="logo-area">
                            <img class="site-logo-amaken" src="../assets/images/logo/amaken-logo.svg">
                        </div>
                    </router-link>

                    <div class="sign-form-area" id="verify-otp">
                        <h1 class="otp-title">{{ $t('verify_otp') }}</h1>
                        <p class="otp-label">{{ $t('enter_otp_label') }} <span>{{ email }}</span></p>
                        <message-prime severity="error" v-if="validationErrors.length">{{validationErrors[0]}}</message-prime>
                        <message-prime severity="success" v-if="success_message != null">{{success_message}}</message-prime>
                        <div class="form-fields-main form-otp-inputs">
                            <!-- <inputText-prime maxlength="1" v-model="value1" class="form-input-fields" />
                            <inputText-prime maxlength="1" v-model="value2" class="form-input-fields" />
                            <inputText-prime maxlength="1" v-model="value3" class="form-input-fields" />
                            <inputText-prime maxlength="1" v-model="value4" class="form-input-fields" /> -->
                            <div class="form-otp-inputs-inner">
                                <v-otp-input
                                    ref="otpInput"
                                    v-model:value="otp_value"
                                    input-classes="otp-input"
                                    separator=" "
                                    :num-inputs="4"
                                    :should-auto-focus="true"
                                    input-type="letter-numeric"
                                    :conditionalClass="['one', 'two', 'three', 'four']"
                                    :placeholder="['', '', '', '']"
                                    @on-change="handleOnChange"
                                    @on-complete="handleOnComplete"
                                />
                                </div>
                                <!-- <button @click="clearInput()">Clear Input</button> -->
                                <!-- <button @click="fillInput('2929')">Fill Input</button> -->
                        </div>
                        <div class="form-fields-main">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../assets/Icons/password-icon.svg">
                                <password-prime class="form-input-fields" v-model="new_password" toggleMask :feedback="false" :placeholder="$t('new_pass')" />
                            </span>
                        </div>
                        <div class="form-fields-main">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../assets/Icons/password-icon.svg">
                                <password-prime class="form-input-fields" v-model="confirm_new_password" toggleMask :feedback="false" :placeholder="$t('confirm_new_pass')" />
                            </span>
                        </div>
                        <div class="form-fields-main">
                            <button-prime @click="confirm" :loading="loading_confirm" :label="$t('confirm')" rounded />
                        </div>

                        <div class="form-fields-main">
                            <button-prime @click="resend" :loading="loading_resend" class="btn-transparent" :label="$t('resend_otp')" rounded />
                        </div>

                        <!-- <div class="form-fields-main">
                            <button-prime class="btn-transparent" label="Change Number" rounded />
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-6 signIn-Info-rightArea">
                <div class="info-right-image">
                    <img class="verify-infoRight-img" src="../assets/Icons/verify-otpIcon.svg">
                </div>
                <div class="welcome-text-sign">
                    <h2>{{ $t('welcome_to_amaken') }}</h2>
                    <!-- <p class="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    components: {

    },
    data() {
        return {
            email : null,
            loading_confirm : false,
            loading_resend : false,
            validationErrors : [],
            success_message : null,
            value1 : '',
            value2 : '',
            value3 : '',
            value4 : '',
            confirm_new_password : '',
            new_password : '',
            otp_value : ''
        }
    },
    mounted() {
        console.log('this.$route.params.id', this.$route.params.id)
        this.get_email();
    }, 
    methods : {
        get_email : function() {
            axios.get(this.api_url+'get_email',{
                params : {
                    id: this.$route.params.id 
                } 
            }).then((response) => {
                if(response.status == 200) {
                    this.email = response.data.data.email;
                }
            }).catch((error) => {
                console.log('err', error);
            });
        },
        confirm : function() {
            this.validationErrors = [];
            this.loading_confirm = true;
            this.success_message = null;
            axios.get(this.api_url+'confirm_otp',{
                params : {
                    id: this.$route.params.id,
                    otp : this.otp_value,
                    new_password : this.new_password,
                    confirm_new_password : this.confirm_new_password,
                    lang : this.amaken_language
                } 
            }).then((response) => {
                this.loading_confirm = false;
                if(response.status == 200) {
                    this.success_message = response.data.message;
                    this.$router.push({
                         name : 'signin',
                         query : {
                            password : 'changed'
                         }
                    })
                }
            }).catch((error) => {
                this.loading_confirm = false;
                if(error.response.status == 401) {
                    this.validationErrors.push(error.response.data.errors);
                }
            });
        },
        resend : function() {
            this.validationErrors = [];
            this.loading_resend = true;
            this.success_message = null;
            axios.get(this.api_url+'resend_otp',{
                params : {
                    id: this.$route.params.id ,
                    lang : this.amaken_language
                } 
            }).then((response) => {
                this.loading_resend = false;
                if(response.status == 200) {
                    this.success_message = response.data.message;
                }
            }).catch((error) => {
                this.loading_resend = false;
                if(error.response.status == 401) {
                    this.validationErrors.push(error.response.data.errors);
                }
            });
        }
    }
}
</script>

<style>
.verify-otp-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
}

.form-fields-main.form-otp-inputs input.p-inputtext.form-input-fields {
    padding: 0.75rem 0.75rem;
    border-radius: 10px;
    text-align: center;
}

.form-fields-main.form-otp-inputs {
    display: flex;
    column-gap: 0.5rem;
}

h1.otp-title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 5px;
}

p.otp-label span {
    color: #0091f6;
    font-weight: 500;
}

p.otp-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.otp-input {
  width: 100%;
  height: 50px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}

.form-fields-main.form-otp-inputs {
    width: 100%;
}
</style>
