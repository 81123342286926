<template>
    <div class="grid m-0">
    <div class="col-12">
        <div class="search-box-area">
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <autoComplete-prime @item-select="onSelect" v-model="selectedValue" optionLabel="name" :suggestions="filtered_place_and_events" @complete="search" :placeholder="$t('where_to_go_today')" />
            </span>
            <span class="p-input-icon-left filter-icon-box filter-icon-box-inview" @click="visible = true">
                <i class="pi pi-sliders-h" />
                <span>{{ $t('filters') }}</span>
            </span>
        </div>
    </div>
    </div>
    <dialog-prime v-model:visible="visible" class="filter-popup-modal" modal :header="$t('filters')" :style="{ width: '50vw' }" :draggable="false">
    <div class="filter-popup-tab-view">
        <tabView-prime class="tabview-custom">
        <tabPanel-prime>
            <template #header>
                <span>{{ $t('sort_by') }}</span>
                <!-- <span class="filter-select-label">Rating: High to Low</span> -->
            </template>
            <div class="flex flex-wrap sort-radio-filter">
            <div class="flex align-items-center">
                <radioButton-prime v-model="sortby" inputId="ingredient1" name="pizza" value="1" />
                <label for="ingredient1" class="ml-2">{{ $t('popularity') }}</label>
            </div>
            <div class="flex align-items-center">
                <radioButton-prime v-model="sortby" inputId="ingredient2" name="pizza" value="2" />
                <label for="ingredient2" class="ml-2">{{ $t('rating') }}: {{ $t('high_to_low') }}</label>
            </div>
            <div class="flex align-items-center">
                <radioButton-prime v-model="sortby" inputId="ingredient3" name="pizza" value="3" />
                <label for="ingredient3" class="ml-2">{{ $t('cost') }}: {{ $t('low_to_high') }}</label>
            </div>
            <div class="flex align-items-center">
                <radioButton-prime v-model="sortby" inputId="ingredient4" name="pizza" value="4" />
                <label for="ingredient4" class="ml-2">{{ $t('cost') }}: {{ $t('high_to_low') }}</label>
            </div>
        </div>
        </tabPanel-prime>
        <tabPanel-prime>
            <template #header>
                <span>{{ $t('places') }}</span>
            </template>
            <div class="filter-popup-container">
            <div class="filtersItemBox">
                <div class="search-box-area mt-0 mb-5">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <inputText-prime :placeholder="$t('search')" @keyup="searchPlaceTypes" v-model="searchplacetypes" />
                </span>
                </div>

                <div class="addFilter-itemsArea">
                    <div class="addFilter-item">
                    <div v-for="place_types in filter_place_types" :key="place_types" class="flex align-items-center addFilter-itemCheckbox placetypes-filter">
                        <checkbox-prime :inputId="'place-type-' + place_types.id" v-model="selected_filter_place_types" name="filtercheck" :value="place_types.id" />
                        <label :for="'place-type-' + place_types.id" class="ml-2"> {{ get_filter_name(place_types) }} </label>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </tabPanel-prime>
        <tabPanel-prime>
            <template #header>
                <span>{{ $t('cuisine') }}</span>
            </template>
            <div class="filter-popup-container">
            <div class="filtersItemBox">
                <div class="search-box-area mt-0 mb-5">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <inputText-prime @keyup="searchCuisine" v-model="searchcuisine" :placeholder="$t('search')" />
                </span>
                </div>

                <div class="addFilter-itemsArea">
                    <div class="addFilter-item">
                    <div v-for="cuisine in filter_cuisine" :key="cuisine" class="flex align-items-center addFilter-itemCheckbox cuisine-filter">
                        <checkbox-prime :inputId="cuisine.id" v-model="selected_filter_cuisine" name="filtercheck" :value="cuisine.id" />
                        <label :for="cuisine.id" class="ml-2"> {{ get_filter_name(cuisine) }} </label>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </tabPanel-prime>
        <tabPanel-prime>
            <template #header>
                <span>{{ $t('menu') }}</span>
            </template>
            <div class="filter-popup-container">
            <div class="filtersItemBox">
                <div class="search-box-area mt-0 mb-5">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <inputText-prime @keyup="searchMenu" v-model="searchmenu" :placeholder="$t('search')" />
                </span>
                </div>
                <div class="addFilter-itemsArea">
                    <div class="addFilter-item">
                    <div v-for="menu in filter_menu" :key="menu" class="flex align-items-center addFilter-itemCheckbox menu-filter">
                        <checkbox-prime :inputId="menu.id" v-model="selected_filter_menu" name="filtercheck" :value="menu.id" />
                        <label :for="menu.id" class="ml-2"> {{ get_filter_name(menu) }} </label>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </tabPanel-prime>
        <tabPanel-prime>
            <template #header>
                <span>{{ $t('amenities') }}</span>
            </template>
            <div class="filter-popup-container">
            <div class="filtersItemBox">
                <div class="search-box-area mt-0 mb-5">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <inputText-prime @keyup="searchAmeneties" v-model="searchamenity" :placeholder="$t('search')" />
                </span>
                </div>

                <div class="addFilter-itemsArea">
                    <div class="addFilter-item">
                        <div v-for="ameneties in filter_ameneties" :key="ameneties" class="flex align-items-center addFilter-itemCheckbox ameneties-filter">
                            <checkbox-prime :inputId="ameneties.id" v-model="selected_filter_ameneties" name="filtercheck" :value="ameneties.id" />
                            <label :for="ameneties.id" class="ml-2"> {{ get_filter_name(ameneties) }} </label>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </tabPanel-prime>
        <tabPanel-prime>
            <template #header>
                <span>{{ $t('vibes') }}</span>
            </template>
            <div class="filter-popup-container">
            <div class="filtersItemBox">
                <div class="search-box-area mt-0 mb-5">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <inputText-prime @keyup="searchVibe" v-model="searchvibe" :placeholder="$t('search')" />
                </span>
                </div>
                <div class="addFilter-itemsArea">
                    <div class="addFilter-item">
                    <div v-for="vibe in filter_vibes" :key="vibe" class="flex align-items-center addFilter-itemCheckbox vibe-filter">
                        <checkbox-prime :inputId="vibe.id" v-model="selected_filter_vibes" name="filtercheck" :value="vibe.id" />
                        <label :for="vibe.id" class="ml-2"> {{ get_filter_name(vibe) }} </label>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </tabPanel-prime>

        <!-- <tabPanel-prime>
            <template #header>
                <span>Events</span>
            </template>
            <div class="filter-popup-container">
            <div class="filtersItemBox">
                <div class="search-box-area mt-0 mb-5">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <inputText-prime :placeholder="$t('search')" @keyup="searchEvents" v-model="searchevents" />
                </span>
                </div>

                <div class="addFilter-itemsArea">
                    <div class="addFilter-item">
                    <div v-for="events in filter_events" :key="events" class="flex align-items-center addFilter-itemCheckbox events-filter">
                        <checkbox-prime :inputId="'event-' + events.id" v-model="selected_filter_events" name="filtercheck" :value="events.id" />
                        <label :for="'event-' + events.id" class="ml-2"> {{ events.name }} </label>
                    </div>
                    </div>

                </div>
            </div>
            </div>
        </tabPanel-prime> -->
        
        <tabPanel-prime>
            <template #header>
                <span>{{ $t('price') }}</span>
            </template>
            <div class="filter-popup-container price-range-box">
            <div class="addFilter-itemsArea">
                <h6>{{ $t('cost_per_person') }}</h6>
                <h3>QAR {{ selected_cost_per_person_range[0] }} - {{selected_cost_per_person_range[1]}}</h3>
                <div class="card flex justify-content-center px-2">
                    <slider-prime :range="true" :max="10000" v-model="selected_cost_per_person_range" class="" style="width:90%;" />
                </div>
            </div>
            </div>
        </tabPanel-prime>
        <tabPanel-prime>
            <template #header>
                <span>{{ $t('location') }}</span>
            </template>
            <div class="filter-popup-container">
                
            <div class="addFilter-itemsArea">
                <div class="grid">
                    <div class="col-12 md:col-4 lg:col-4">
                        <dropdown-prime filter @change="get_cities" v-model="selected_country" :options="countries_list" optionLabel="name" :placeholder="$t('select_country')" class="w-full filter-dropdown-popup" />
                    </div>
                    <div class="col-12 md:col-4 lg:col-4">
                        <dropdown-prime filter @change="get_areas" v-model="selected_city" :options="cities_list" optionLabel="name" :placeholder="$t('select_city')" class="w-full filter-dropdown-popup" />
                    </div>
                    <div class="col-12 md:col-4 lg:col-4">
                        <dropdown-prime filter v-model="selected_area" :options="areas_list" optionLabel="name" :placeholder="$t('select_area')" class="w-full filter-dropdown-popup" />
                   </div>
                </div>
            </div>
            </div>
        </tabPanel-prime>
    </tabView-prime>
    </div>
        <template #footer>
            <button-prime class="footer-btn-left" :label="$t('clear_all')" text @click="clear_all()"/>
            <button-prime class="footer-btn-right" :label="$t('apply')" autofocus @click="apply_filters()"/>
        </template>
    </dialog-prime>
</template>

<script>
import axios from 'axios'
import $ from 'jquery'
export default {
  data() {
    return {
      searchvalue : '',
      filter_ameneties : [],
      filter_vibes : [],
      filter_cuisine : [],
      filter_menu : [],
    //   filter_events : [],
      filter_place_types : [],
      selected_filter_ameneties : [],
      selected_filter_vibes : [],
      selected_filter_cuisine : [],
      selected_filter_menu : [],
    //   selected_filter_events : [],
      selected_filter_place_types : [],
      selected_rating_high_to_low : '',
      cost_per_person_range : [0, 10000],
      selected_cost_per_person_range : [0, 10000],
      visible : false,
      places : [],
      searchamenity : '',
      searchmenu : '',
      searchvibe : '',
      searchcuisine : '',
      searchevents : '',
      searchplacetypes : '',
      sortby : 0,
      place_and_events : [],
      selectedValue : '',
      filtered_place_and_events : '',
      countries_list : [],
      cities_list : [],
      areas_list : [],
      selected_country :  0,
      selected_city : 0,
      selected_area : 0
    }
  },
  mounted() {
    $.expr[":"].contains = $.expr.createPseudo(function(arg) {
        return function( elem ) {
            return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
        };
    });
    this.get_filters();
  },
  created() {
    this.visible = this.filter_modal_visibility;
  },
  methods: {
    get_filter_name : function(item) {
        var filter_name = item.name;
        if (this.amaken_language == 'AR') {
            filter_name = item.arabic_name;
        }
        if(filter_name == null) {
            filter_name = item.name;
        }
        return filter_name;
    },
    get_cities : function() {
      axios.get(this.api_url+'get_cities',{
        params : {
          country : this.selected_country
        } 
      }).then((response) => {
          if(response.status == 200) {
              if (response.data.status == true) {
                  this.cities_list = response.data.cities;
              } 
          }
      }).catch((error) => {
          console.log('error', error);
      });
    },
    get_areas : function() {
      axios.get(this.api_url+'get_areas',{
        params : {
          city : this.selected_city
        } 
      }).then((response) => {
          if(response.status == 200) {
              if (response.data.status == true) {
                  this.areas_list = response.data.areas;
              } 
          }
      }).catch((error) => {
          console.log('error', error);
      });
    },
    onSelect : function() {
        if (this.selectedValue.type == 'place') {
            this.$router.push({
                path : '/place-detail/'+this.selectedValue.id,
                query: {
                    search : this.selectedValue.place_name
                }
            });
        } else if (this.selectedValue.type == 'event') {
            this.$router.push({
                path : '/event-detail/'+this.selectedValue.id,
                query: {
                    search : this.selectedValue.event_name
                }
            })
        }
    },
    search : function(event) {
        setTimeout(() => {
            if (!event.query.trim().length) {
                this.filtered_place_and_events= [...this.place_and_events];
            } else {
                
                this.filtered_place_and_events = this.place_and_events.filter((item) => {
                    // if (this.amaken_language == 'AR') {
                    //     return item.arabic_name.toLowerCase().startsWith(event.query.toLowerCase());
                    // } else if (this.amaken_language == 'EN') { 
                        return item.name.toLowerCase().startsWith(event.query.toLowerCase());
                    // }
                    
                });
            }
        }, 250);
    },
    searchAmeneties : function() {
        $('.ameneties-filter').removeClass('d-hide');
        $('.ameneties-filter:not(:contains('+ this.searchamenity +'))').addClass('d-hide');
    },
    searchCuisine : function() {
        $('.cuisine-filter').removeClass('d-hide');
        $('.cuisine-filter:not(:contains('+ this.searchcuisine +'))').addClass('d-hide');
    },
    searchEvents : function() {
        $('.events-filter').removeClass('d-hide');
        $('.events-filter:not(:contains('+ this.searchevents +'))').addClass('d-hide');
    },
    searchMenu : function() {
        $('.menu-filter').removeClass('d-hide');
        $('.menu-filter:not(:contains('+ this.searchmenu +'))').addClass('d-hide');
    },
    searchVibe : function() {
        $('.vibe-filter').removeClass('d-hide');
        $('.vibe-filter:not(:contains('+ this.searchvibe +'))').addClass('d-hide');
    },
    searchPlaceTypes : function() {
        $('.placetypes-filter').removeClass('d-hide');
        $('.placetypes-filter:not(:contains('+ this.searchplacetypes +'))').addClass('d-hide');
    },
    apply_filters : function () {
        this.gotoPlacesPage();
        this.visible = false;
    },
    gotoPlacesPage : function () {
        this.$router.push({
            name: 'places', 
            query: {
                sortby : this.sortby,
                ameneties: JSON.stringify(this.selected_filter_ameneties),
                cuisine: JSON.stringify(this.selected_filter_cuisine),
                menu: JSON.stringify(this.selected_filter_menu),
                vibes: JSON.stringify(this.selected_filter_vibes),
                // events: JSON.stringify(this.selected_filter_events),
                place_types: JSON.stringify(this.selected_filter_place_types),
                price_range: JSON.stringify(this.selected_cost_per_person_range),
                country : (this.selected_country == 0 ? 0 : this.selected_country.id),
                city : (this.selected_city == 0 ? 0 : this.selected_city.id),
                area : (this.selected_area == 0 ? 0 : this.selected_area.id),
            } 
        });
        axios.get(this.api_url+'get_places',{
            params: { 
                sortby : this.sortby,
                ameneties: JSON.stringify(this.selected_filter_ameneties),
                cuisine: JSON.stringify(this.selected_filter_cuisine),
                menu: JSON.stringify(this.selected_filter_menu),
                vibes: JSON.stringify(this.selected_filter_vibes),
                // events: JSON.stringify(this.selected_filter_events),
                place_types: JSON.stringify(this.selected_filter_place_types),
                price_range: JSON.stringify(this.selected_cost_per_person_range),
                country : (this.selected_country == 0 ? 0 : this.selected_country.id),
                city : (this.selected_city == 0 ? 0 : this.selected_city.id),
                area : (this.selected_area == 0 ? 0 : this.selected_area.id),
            } 
        }).then((response) => {
            this.places = response.data.places;
            this.$emit('places', this.places);
        });
    },
    clear_all : function () {
        this.selected_filter_ameneties = [];
        this.selected_filter_cuisine = [];
        this.selected_filter_menu = [];
        this.selected_filter_vibes = [];
        // this.selected_filter_events = [];
        this.selected_filter_place_types = [];
        this.selected_cost_per_person_range = [0, 10000];
        this.sortby = 0;
        this.selected_country = 0;
        this.selected_city = 0;
        this.selected_area = 0;
        this.gotoPlacesPage();
        this.visible = false;
    },
    get_filters : function() {
      axios.get(this.api_url+'get_filters_data', {
        params : {
            lang : this.amaken_language
        }
      }).then((response) => {
        this.filter_ameneties = response.data.data.amenity.filters;
        this.filter_vibes = response.data.data.vibe.filters;
        this.filter_cuisine = response.data.data.cuisine.filters;
        this.filter_menu = response.data.data.menu.filters;
        // this.filter_events = response.data.data.events;
        this.filter_place_types = response.data.data.placetytpe;
        this.place_and_events = response.data.data.places_and_events;
        this.countries_list = response.data.data.countries
      });
    }
  }
  
};
</script>

