<template>
    <div class="grid m-0">
    <div class="col-12">
        <div class="search-box-area">
        <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <autoComplete-prime @item-select="onSelect" v-model="selectedValue" optionLabel="name" :suggestions="filtered_events" @complete="search" />
            
        </span>
        <span class="p-input-icon-left filter-icon-box" @click="visible = true">
            <i class="pi pi-sliders-h" />
            <span>{{ $t('filters') }}</span>
        </span>
        </div>
    </div>
    </div>
    <dialog-prime v-model:visible="visible" class="filter-popup-modal" modal :header="$t('filters')" :style="{ width: '50vw' }" :draggable="false">
    <div class="filter-popup-tab-view">
        <tabView-prime class="tabview-custom">
        <tabPanel-prime>
            <template #header>
                <span>{{ $t('event_types') }}</span>
            </template>
            <div class="filter-popup-container">
            <div class="filtersItemBox">
                <div class="search-box-area mt-0 mb-5">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <inputText-prime :placeholder="$t('search')" @keyup="search_events_type" v-model="searcheventstype" />
                </span>
                </div>

                <div class="addFilter-itemsArea">
                    <div class="addFilter-item">
                    <div v-for="eventtype in filter_event_types" :key="eventtype" class="flex align-items-center addFilter-itemCheckbox eventstype-filter">
                        <checkbox-prime :inputId="'eventtype-' + eventtype.id" v-model="selected_filter_event_types" name="filtercheck" :value="eventtype.id" />
                        <label :for="'eventtype-' + eventtype.id" class="ml-2"> {{ get_filter_name(eventtype) }} </label>
                    </div>
                    </div>

                </div>
            </div>
            </div>
        </tabPanel-prime>
        <tabPanel-prime>
            <template #header>
                <span>{{ $t('price') }}</span>
            </template>
            <div class="filter-popup-container price-range-box">
            <div class="addFilter-itemsArea">
                <h6>{{ $t('price') }}</h6>
                <h3>QAR {{ selected_cost_per_person_range[0] }} - {{selected_cost_per_person_range[1]}}</h3>
                <div class="card flex justify-content-center px-2">
                    <slider-prime :range="true" :max="10000" v-model="selected_cost_per_person_range" class="" style="width:90%;" />
                </div>
            </div>
            </div>
        </tabPanel-prime>
    </tabView-prime>
    </div>
        <template #footer>
            <button-prime class="footer-btn-left" :label="$t('clear_all')" text @click="clear_all()"/>
            <button-prime class="footer-btn-right" :label="$t('apply')" autofocus @click="apply_filters()"/>
        </template>
    </dialog-prime>
</template>
<script>

import axios from 'axios'
import $ from 'jquery'
export default {
  data() {
    return {
      searchvalue : '',
      filter_event_types : [],
      selected_filter_event_types : [],
      cost_per_person_range : [1, 10000],
      selected_cost_per_person_range : [1, 10000],
      visible : false,
      events : [],
      searcheventstype : '',
      selectedValue : '',
      filtered_events : '',
      events_filter_list : []
    }
  },
  mounted() {
    $.expr[":"].contains = $.expr.createPseudo(function(arg) {
        return function( elem ) {
            return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
        };
    });
    this.get_events_filters();
  },
  created() {
    this.visible = this.filter_modal_visibility;
  },
  methods: {
    get_filter_name : function(item) {
        var filter_name = item.name;
        if (this.amaken_language == 'AR') {
            filter_name = item.arabic_name;
        }
        if(filter_name == null) {
            filter_name = item.name;
        }
        return filter_name;
    },
    onSelect : function() {
        this.$router.push({
            path : '/event-detail/'+this.selectedValue.id
        })
    },
    search : function(event) {
        setTimeout(() => {
            if (!event.query.trim().length) {
                this.filtered_events= [...this.events_filter_list];
            } else {
                this.filtered_events = this.events_filter_list.filter((item) => {
                    return item.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        }, 250);
    },
    search_events_type : function() {
        $('.eventstype-filter').removeClass('d-hide');
        $('.eventstype-filter:not(:contains('+ this.searcheventstype +'))').addClass('d-hide');
    },
    apply_filters : function () {
        this.gotoEventsPage();
        this.visible = false;
    },
    gotoEventsPage : function () {
        this.$router.push({
            name: 'events', 
            query: {
                event_types: JSON.stringify(this.selected_filter_event_types),
                price_range: JSON.stringify(this.selected_cost_per_person_range)
            } 
        });
        axios.get(this.api_url+'get_events',{
            params: { 
                search : this.searchvalue,
                event_types: JSON.stringify(this.selected_filter_event_types),
                price_range: JSON.stringify(this.selected_cost_per_person_range)
            } 
        }).then((response) => {
            this.events = response.data.events;
            this.$emit('events', this.events);
        });
    },
    clear_all : function () {
        this.selected_filter_event_types = [];
        this.selected_cost_per_person_range = [];
        this.gotoEventsPage();
        this.visible = false;
    },
    get_events_filters : function() {
      axios.get(this.api_url+'get_filters_data_for_events_page', {
        params : {
            lang : this.amaken_language
        }
      }).then((response) => {
        this.filter_event_types = response.data.data.eventtypes;
        this.events_filter_list = response.data.data.eventslist;
      });
    }
  }
  
};
</script>
