<template>
        <div class="main-content booking-seating-view" :style="{background : customization.second_box_color}">
            <ReservationHeaderView :customization="customization" />
            
            <main>
                <div class="container">
                    <div class="col-12 md:col-12 lg:col-12">
                        <div v-if="customization.banner!=null" class="restaurent-img-thumbnail" :style="{backgroundImage : 'url('+customization.banner+')'}"></div>
                        <div v-else class="restaurent-img-thumbnail" :style="{background : customization.banner_background_color}"></div>

                        <div v-if="customization.logo!=null" class="logo-main-box-container" :style="{backgroundImage : 'url('+customization.logo+')'}"></div>
                        <div v-else class="logo-main-box-container"></div>
                    </div>   
                </div>
                <div class="container first-box-element" :style="{background : customization.first_box_color}">
                    <div class="reservation-container grid m-0 second-box-element" :style="{background : customization.second_box_color}">
                        <div class="col-12 md:col-12 lg:col-12">
                            <h4 :style="{color : customization.text_box_color}">Available Time</h4>
                            <div class="select-restaurent-bookingContainer booking-seating-container">
                                <div class="restaurent-book-info">
                                    <!-- <h2 class="restaurantName">{{ place.name }}</h2> -->
                                    <ul>
                                        <li :style="{background : customization.button_box_color}">
                                            <i class="pi pi-calendar" style="font-size: 1rem"></i>
                                            <span :style="{color : customization.text_box_color}">{{formatted_date}}</span>
                                        </li>
                                        <li class="formatted-time" :style="{background : customization.button_box_color}">
                                            <i class="pi pi-clock" style="font-size: 1rem"></i>
                                            <span :style="{color : customization.text_box_color}">{{ formatted_time }}</span>
                                        </li>
                                        <li :style="{background : customization.button_box_color}">
                                            <i class="pi pi-user" style="font-size: 1rem"></i>
                                            <!-- <span>{{ persons }} people (Standard seating)</span> -->
                                            <span :style="{color : customization.text_box_color}">{{ persons }} people</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>   
                            
                            <div class="bookingSeatingContainer" v-if="seating_areas.length>0">
                                <label class="reservation-label" :style="{color : customization.text_box_color}">Choose seating</label>
                                <ul class="seating-area-ul">
                                    <li :style="{background : customization.header_color}" class="seating-area-li" v-for="item in seating_areas" :key="item">
                                        <panel-prime v-bind:header="item.name" toggleable collapsed>
                                            <!-- <p class="seating-area-name">{{ item.name }}</p> -->
                                            <div class="seating-area-table" v-if="item.time_slots.length>0">
                                                <div class="select-time-chips-box">
                                                    <div :style="{background : customization.button_box_color}" class="select-time-chips" v-for="itemtimeslot in item.time_slots" :key="itemtimeslot">
                                                        <router-link :to="'/' +place.slug+'/booking-details?datetime='+reset_date_time(datetime, itemtimeslot)+'&persons='+persons+'&area='+item.id">
                                                            <span :style="{color : customization.text_box_color}">{{itemtimeslot}}</span>
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="seating-area-table" v-else>
                                                <span :style="{color : customization.text_box_color}">No tables available.</span>
                                            </div>
                                        </panel-prime>
                                    </li>
                                </ul>
                            </div>

                            <div class="bookingSeatingContainer" v-else>
                                <h3 class="detail-text-title">Opening & Closing Hours are not set!</h3>
                            </div>    

                            <div class="col-12 md:col-12 lg:col-12 p-0">
                                <!-- <router-link to="/booking-seating"> -->
                                    <button-prime class="find-a-time"  label="Confirm" severity="success" rounded :style="{color : customization.text_box_color, background : customization.button_box_color}">Find a table</button-prime>
                                <!-- </router-link> -->
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <ReservationFooterView :customization="customization" />
        </div>
</template>
  

<script>
import ReservationHeaderView from '/src/components/reservationHeader.vue'
import ReservationFooterView from '/src/components/reservationFooter.vue'
import axios from 'axios'
import moment from 'moment'
import $ from 'jquery'

export default {
    components: {
        ReservationHeaderView,
        ReservationFooterView,
    },
    data() {
        return {
            persons : 1,
            datetime : null,
            formatted_date : null,
            formatted_time : null,
            place : {
                name : null
            },
            seating_areas : [],
            customization : {
                banner_background_color : '#313131',
                header_color : '#000000',
                footer_color : '#000000',
                first_box_color : '#212121',
                second_box_color : '#2C2C2C',
                text_box_color : '#FFFFFF',
                field_box_color : '#000000',
                button_box_color : '#884192',
                banner : null,
                logo : null
            }
        }
    },
    mounted() {
        this.get_customization_settings();
        this.datetime = this.$route.query.datetime;
        this.formatted_date = moment(this.$route.query.datetime).format('ddd, MMMM DD');
        this.formatted_time = moment(this.$route.query.datetime).format('HH:mm A');
        this.persons = this.$route.query.persons;
        this.get_available_table_details();
    },
    methods: { 
        get_available_table_details : function () {
            axios.get(this.api_url+'get_available_table_details', {
                params: { 
                    slug: this.$route.params.slug,
                    datetime : this.$route.query.datetime,
                    persons : this.$route.query.persons,
                } 
            }).then((response) => {
                this.place = response.data.data.place;
                this.seating_areas = response.data.data.seating_areas;
            });
        },
        reset_date_time : function (datetime, time) {
            var dateTime = datetime.split(' ');
            var Time = moment(dateTime[0]+' '+time).format('YYYY-MM-DD HH:mm:ss');
            return Time
        },
        get_customization_settings : function() {
            axios.get(this.api_url+'get_customization', {
                params: { 
                    slug: this.$route.params.slug,
                    type : 'branch'
                } 
            }).then((response) => {
                if (response.data.data.customization != '') {
                    this.customization = response.data.data.customization;
                }
                console.log('col', this.customization.text_box_color);
                setTimeout(() => {
                    $('.p-panel-title').css('color', this.customization.text_box_color)
                }, "1000");
                $('.pi-calendar').css('color', this.customization.text_box_color);
                $('.pi-clock').css('color', this.customization.text_box_color);
                $('.pi-user').css('color', this.customization.text_box_color);
            });
        }
    }
};
</script>
  
<style scoped>
.bookingSeatingContainer {
    margin-top: 1rem;
}

.bookingSeatingContainer h3.detail-text-title {
    margin-bottom: 1rem;
    color: #fff;
    font-weight:500;
    font-size: 20px;
}

p.seating-area-name {
    margin-bottom: 0.3rem;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}

.seating-area-table span {
    color: #fff;
}
.bookingSeatingContainer .select-time-chips-box {
    /* margin-bottom: 1rem; */
}
.booking-seating-view h2.restaurantName {
    margin-top: 0;
    font-size: 30px;
    font-weight: 500;
}

.restaurent-img-thumbnail.booking-seating-image {
    width: 110px;
    height: 80px;
    background: #ccc;
    border-radius: 6px;
    margin-bottom: 1rem;
    background: url(https://admin.amakenapp.com/storage/place/2/gallery/647774c473676.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
</style>