<template>
        <header class="header-container">
            <div class="header-left">
                <router-link to="/">
                <div class="logo">
                    <img class="site-logo-amaken" src="../../assets/images/logo/amaken-logo.svg">
                </div>
                </router-link>

                <!-- <div class="header-search">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="value1" placeholder="Search" />
                    </span>
                </div> -->

                <div class="header-left-menu">
                    <ul class="header-left-menuUl">
                        <li class="header-left-menuItems">
                            <a>
                                <router-link to="/">
                                    <img class="site-logo-amaken header-home-icon" src="../../assets/Header-menu-icons/home.svg">
                                    <p>{{ $t('header_home') }}</p>
                                </router-link>
                            </a>
                        </li>
                        <li class="header-left-menuItems">
                            <a>
                                <router-link to="/places">
                                    <img class="site-logo-amaken header-restaurents-icon" src="../../assets/Header-menu-icons/service.svg">
                                    <p>{{ $t('header_restaurant') }}</p>
                                </router-link>
                            </a>
                        </li>
                        <li class="header-left-menuItems">
                            <a>
                                <router-link to="/events">
                                    <img class="site-logo-amaken header-events-icon" src="../../assets/Header-menu-icons/events.svg">
                                    <p>{{ $t('header_events') }}</p>
                                </router-link>
                            </a>
                        </li>
                        <li class="header-left-menuItems">
                            <a>
                                <router-link to="/offers">
                                    <img class="site-logo-amaken header-events-icon" src="../../assets/Header-menu-icons/offer.svg">
                                    <p>{{ $t('header_offers') }}</p>
                                </router-link>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="header-right">
                <ul class="header-right-menuUl">
                    <!-- <li class="header-right-menuItems">
                        <a href="#"><i class="pi pi-bell" aria-hidden="true"></i></a>
                    </li> -->
                    <!-- <li class="header-right-menuItems">
                        <a>
                            <router-link to="/profile-setting">
                                <a href="#"><i class="pi pi-cog" aria-hidden="true"></i></a>
                            </router-link>
                        </a>
                    </li> -->
                    <li class="header-left-menuItems">
                        <p class="language-switcher" @click="toggleLang" aria-haspopup="true" aria-controls="overlay_tmenuLang">
                            <span>
                                <i class="pi pi-globe" style="transform: translateY(3px);font-size: 1.2rem;padding-right: 0.1rem;"></i>
                                {{selected_language}}
                            </span>
                            <i class="pi pi-angle-down" style="transform: translateY(2px);font-size: 0.8rem;padding-left: 0.1rem;"></i>
                        </p>
                        <div class="card flex justify-content-center profile-setting-dropdown">
                            <tieredMenu-prime ref="menuLang" id="overlay_tmenuLang" :model="items_lang" popup class="" />
                        </div>
                    </li>

                    <li class="header-right-menuItems" @click="toggle" aria-haspopup="true" aria-controls="overlay_tmenu">
                        <!-- <avatar-prime v-if="profile_image!=null" :image="profile_image" class="mr-2" size="xlarge" shape="circle" /> -->

                        <div class="user-profile-imageBox" v-if="profile_image!=null" v-bind:style="{'background-image': 'url('+profile_image+')'}"></div>

                        <div class="userIconDefault" v-else>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="user"><path fill="#000" d="M12 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2a9.985 9.985 0 0 0-8 4 9.985 9.985 0 0 0 8 4 9.985 9.985 0 0 0 8-4 9.985 9.985 0 0 0-8-4z"></path></svg>
                        </div>
                        <!-- <avatar-prime v-else :image="null" class="mr-2" size="xlarge" shape="circle" /> -->
                    </li>
                </ul>
                <div class="card flex justify-content-center profile-setting-dropdown">
                    <tieredMenu-prime ref="menu" id="overlay_tmenu" :model="items" popup class="" />
                </div>
            </div>
        </header>

        <!-- Mobile Header -->
        <header class="header-container mobile-bottom-menu">
            <div class="header-left">

                <div class="header-left-menu">
                    <ul class="header-left-menuUl">
                        <li class="header-left-menuItems">
                            <a>
                                <router-link to="/">
                                    <img class="site-logo-amaken mobile-header-homeIcon" src="../../assets/Header-menu-icons/home.svg">
                                    <p>{{ $t('header_home') }}</p>
                                </router-link>
                            </a>
                        </li>
                        <li class="header-left-menuItems">
                            <a>
                                <router-link to="/places">
                                    <img class="site-logo-amaken mobile-header-restaurentsIcon" src="../../assets/Header-menu-icons/service.svg">
                                    <p>{{ $t('header_restaurant') }}</p>
                                </router-link>
                            </a>
                        </li>
                        <li class="header-left-menuItems">
                            <a>
                                <router-link to="/events">
                                <img class="site-logo-amaken mobile-header-eventsIcon" src="../../assets/Header-menu-icons/events.svg">
                                <p>{{ $t('header_events') }}</p>
                                </router-link>
                            </a>
                        </li>
                        <li class="header-left-menuItems">
                            <a>
                                <router-link to="/offers">
                                <img class="site-logo-amaken header-events-icon" src="../../assets/Header-menu-icons/offer.svg">
                                <p>{{ $t('header_offers') }}</p>
                                </router-link>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
</template>

<script setup>
import { ref } from 'vue';
const menu = ref();
const menuLang = ref();

const toggle = (event) => {
    menu.value.toggle(event);
};

const toggleLang = (event) => {
    menuLang.value.toggle(event);
};
</script>
<script>
import axios from 'axios'
import $ from 'jquery'

export default {
  data() {
    return {
        items : [],
        items_lang : [{label: 'EN', command: () => this.language('EN')},{label: 'AR', command: () => this.language('AR')}],
        is_login : true,
        profile_image : null,
        selected_language : localStorage.getItem('amaken_language')
    }
  },
  created() {
    $("body").on("contextmenu", function() {
        return false;
    });
  }, 
  methods : {
    language : function(param) {
        localStorage.setItem('amaken_language', param);
        location.reload();
    },
    signOut : function() {
        axios.get(this.api_url+'signout',{
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('token')
            } 
        }).then((response) => {
           console.log(response);
           localStorage.removeItem('token');
           this.$router.push({name : 'signin'});
        }).catch((error) => {
            console.log(error)
        });
    }, 
    check_user_loggedin : function() {
        axios.get(this.api_url+'loggedin_user_detail',{
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('token')
            } 
        }).then((response) => {
           if(response.status == 200) {
                if (response.data.status == true) {
                    this.items = [
                        {label: this.$t('my_favourites'), icon: 'pi pi-fw pi-star', to: '/favorite'},
                        {label: this.$t('settings'), icon: 'pi pi-fw pi-cog', to: '/profile-setting'},
                        {label: this.$t('privacy_policy'), icon: 'pi pi-fw pi-lock', to: '/privacy-policy'},
                        {label: this.$t('terms_and_conditions'), icon: 'pi pi-fw pi-check-square', to: '/terms-and-conditions'},
                        {label: this.$t('become_a_partner'), icon: 'pi pi-fw pi-users', to: '/become-a-partner'},
                        {separator: true},
                        {label: this.$t('signout'), icon: 'pi pi-fw pi-sign-out', command: () => this.signOut()}
                    ];
                    this.profile_image = response.data.user.profile_image
                } else {
                    this.items = [
                        {label: this.$t('signin')+' | '+this.$t('signup'), icon: 'pi pi-fw pi-sign-in', to: '/signin'},
                        {label: this.$t('privacy_policy'), icon: 'pi pi-fw pi-lock', to: '/privacy-policy'},
                        {label: this.$t('terms_and_conditions'), icon: 'pi pi-fw pi-check-square', to: '/terms-and-conditions'},
                        {label: this.$t('become_a_partner'), icon: 'pi pi-fw pi-users', to: '/become-a-partner'},
                    ];
                }
           }
        }).catch((error) => {
            console.log('error', error);
        });
    }
  },
  mounted() {
    this.check_user_loggedin();
  }
}
</script>
<style>
header.header-container {
    box-shadow: 0 3px 15px #c7c7c773;
    padding: 8px 20px;
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
    background: #fff;
    width: 100%;
}

.header-container .logo img.site-logo-amaken {
    width: 70px;
}

.header-search span.p-input-icon-left i.pi.pi-search {
    z-index: 1;
}

.header-container .header-left {
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.header-search span.p-input-icon-left .p-inputtext {
    border-radius: 1000px;
    min-width: 350px;
    background: #F1F2F5;
    border: 0;
    padding-left: 3rem;
    box-shadow: none;
}

.header-search span.p-input-icon-left > i:first-of-type {
    left: 1.3rem;
}

ul.header-left-menuUl {
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: 2.5rem;
    text-align: center;
}

li.header-left-menuItems {
    display: block;
    cursor: pointer;
}

.header-left-menuItems p {
    margin: 0;
    color: #000;
    font-weight: 500;
    font-size: 14px;
}

ul.header-right-menuUl {
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    text-align: center;
}

li.header-right-menuItems {
    display: flex;
    width: 50px;
    height: 50px;
    background: #e7e7e7;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

li.header-right-menuItems a {
    display: flex;
    align-items: center;
    justify-content: center;
}

li.header-right-menuItems a svg {
    width: 35px;
    height: 35px;
}

li.header-right-menuItems a img {
    width: 32px;
}

li.header-right-menuItems a svg path {
    fill: #2c2c2c;
}

.header-right-menuItems p {
    margin: 0;
    color: #000;
    font-weight: 500;
    font-size: 14px;
}

.header-right-menuItems .p-avatar.p-avatar-xl {
    width: 50px;
    height: 50px;
    font-size: 2rem;
    margin: auto !important;
    overflow: hidden;
}

.header-right-menuItems .p-avatar.p-avatar-xl img {
    width: initial;
    height: initial;
    max-width: 100%;
}

.header-right-menuItems a i.fa {
    font-size: 25px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.header-right-menuItems a .pi {
    font-size: 25px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.p-tieredmenu.p-tieredmenu-overlay {
    transform: translateY(10px) !important;
}

.p-tieredmenu .p-menuitem > .p-menuitem-content {
    background: transparent !important;
}

.p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    background: #e9ecef !important;
}

.header-home-icon {
    width: 30px;
}

.header-restaurents-icon {
    width: 28px;
}

.header-events-icon {
    width: 28px;
}

.userIconDefault svg {
    zoom: 2.2;
    transform: translateY(4px);
    fill: #ccc;
}

.userIconDefault svg path {
    fill: #bbbbbb;
}

.user-profile-imageBox {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

</style>



