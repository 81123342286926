<template>
  <div class="home-page-wrapper">

      <HeaderView />

      <div class="main-content home-content">
        <main>
          <!-- Main Slides Area -->
          <div class="main-slide-container home-slider" style="">
            <div>
              <swiper 
                :slides-per-view="1" 
                :space-between="0" 
                :modules="modules" 
                setWrapperSize="ture"
                :loop="true"
                :autoplay="{
                  delay: 2000,
                  disableOnInteraction: false,
                }"
                :pagination="{
                  clickable: true,
                }"
                :breakpoints="{
                  '@0.00': {slidesPerView: 1,spaceBetween: 0},
                  '@0.75': {slidesPerView: 1,spaceBetween: 0},
                  '@1.00': {slidesPerView: 1,spaceBetween: 0},
                  '@1.50': {slidesPerView: 1,spaceBetween: 0},
                }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
              >
                <swiper-slide  v-for="item in bannersImage" :key="item">  
                  <router-link v-if="item.place_id!=0" :to="'/place-detail/' + item.place_id">     
                  <div class="main-slide-container" v-bind:style="{'background-image': 'url('+item.banner_image+')'}" >
                    <div class="section-title">
                    </div>
                  </div>
                  </router-link>
                  <div v-else class="main-slide-container" v-bind:style="{'background-image': 'url('+item.banner_image+')'}" >
                    <div class="section-title">
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
              
            <!-- <div class="slides-bg">
              <div class="section-title">
                <h2>Find the different ways to explore <br> mouthwatering foods </h2>
              </div>
            </div> -->
          </div>
          <!-- / Main Slides Area -->

          <FilterPopup></FilterPopup>
          <!-- Category Section Area -->
          <!-- @click="socialShareModal = true" -->
          <div class="restaurens-category category-section-area">
            <h2 class="section-title-text">{{ $t('select_category') }}</h2>
            <div class="grid ml-0 mr-0">
              <swiper :slides-per-view="6" :space-between="10" :navigation="true" :modules="modules" setWrapperSize="ture"
                :breakpoints="{
                  '@0.00': {slidesPerView: 1,spaceBetween: 0},
                  '@0.75': {slidesPerView: 2,spaceBetween: 0},
                  '@1.00': {slidesPerView: 3,spaceBetween: 0},
                  '@1.50': {slidesPerView: 6,spaceBetween: 0},
                }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
              >
              
                <swiper-slide v-for="item in categories" :key="item">
                  <router-link :to="'/category/' + item.type+'/'+item.id">   
                  <div class="grid ml-0 mr-0">
                    <div class="restaurents-category-box col-12 md:col-12 lg:col-12 w-full">
                      <div class="restaurents-category-items">
                        <div class="category-image">
                          <!-- <img v-bind:src="require('/src/assets/images/categories/catgory1.png' + item.category_image)" /> -->
                          <img :src="item.image" />
                        </div>
                        <div class="card-info-area">
                          <div class="listing-card-title">
                            <span>{{ get_category_name(item) }}</span>
                          </div>
                          <div class="listing-card-subtitle">
                            <!-- <span>{{ item.countplaces == null ? 0 : (item.countplaces.count > 1 ? item.countplaces.count-1+"+" : item.countplaces.count) }}</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
                </swiper-slide>
                
              </swiper>
            </div>
          </div>
          <!-- / Category Section Area -->

        <!-- Restaurents Section Area -->
        <div class="restaurens-box restaurents-section-area home-restaurent-sliderArea" v-for="item in carousels" :key="item">
          <h2 class="section-title-text">{{ get_carousel_name(item) }}</h2>
          <div class="grid ml-0 mr-0">
            <swiper :slides-per-view="4" :space-between="10" setWrapperSize="ture" :navigation="true" :modules="modules"
              :breakpoints="{
                '@0.00': {slidesPerView: 1,spaceBetween: 0},
                '@0.75': {slidesPerView: 2,spaceBetween: 0},
                '@1.00': {slidesPerView: 3,spaceBetween: 0},
                '@1.50': {slidesPerView: 4,spaceBetween: 0},
              }"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
                <swiper-slide v-for="placelist in item.places" :key="placelist">
                    <div class="grid ml-0 mr-0">
                      <div class="restaurents-main-box col-12 md:col-12 lg:col-12">
                          <div class="restaurents-box-items">
                            <div class="restaurents-box-image">
                              <i :class="'pi pi-heart-fill love-sign-restaurent place-id-'+placelist.id"  @click="addremovelove($event, placelist.id)" />
                              <swiper
                                :slides-per-view="4"
                                :space-between="10"
                                :modules="modules"
                                :navigation="true"
                                :breakpoints="{
                                  '@0.00': {slidesPerView: 1,spaceBetween: 0},
                                  '@0.75': {slidesPerView: 1,spaceBetween: 0},
                                  '@1.00': {slidesPerView: 1,spaceBetween: 0},
                                  '@1.50': {slidesPerView: 1,spaceBetween: 0},
                                }"
                                @swiper="onSwiper"
                                @slideChange="onSlideChange"
                                :pagination="{ clickable: true }"
                              >
                                <swiper-slide v-show="placelist.galleryphotos.length > 0" v-for="placeimage in placelist.galleryphotos" :key="placeimage">
                                  <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                      <div class="mb-3">
                                        <router-link :to="'/place-detail/' + placelist.id">    
                                          <img :src="placeimage.image_path" class="w-6 shadow-2" />
                                        </router-link>  
                                      </div>
                                  </div>
                                </swiper-slide>
                                <swiper-slide v-show="placelist.galleryphotos.length==0">
                                  <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                      <div class="mb-3">
                                        <router-link :to="'/place-detail/' + placelist.id">    
                                          <img v-bind:src="require('/src/assets/images/restaurent-sliders/slider2.jpg')"  class="w-6 shadow-2" />
                                        </router-link>
                                      </div>
                                  </div>
                                </swiper-slide>
                                <swiper-slide v-show="placelist.galleryphotos.length==0">
                                  <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                      <div class="mb-3">
                                        <router-link :to="'/place-detail/' + placelist.id">    
                                          <img v-bind:src="require('/src/assets/images/restaurent-sliders/slider3.jpg')"  class="w-6 shadow-2" />
                                        </router-link>
                                      </div>
                                  </div>
                                </swiper-slide>
                              </swiper>

                              <div class="restaurent-logo">
                                <img v-bind:src="require('/src/assets/images/logo/amaken-logo.svg')">
                              </div>
                            </div>
                            <router-link :to="'/place-detail/' + placelist.id">    
                            <div class="card-info-area card-info-restaurent-area home-card-info-restaurent-area">
                              <div class="restaurent-info-right">
                                <div class="listing-card-subtitle restaurents-opening-time">
                                  <span class="closing-time" v-if="!openedOrClosed(placelist.current_day_timings)">{{ $t('closed') }}</span>
                                  <span class="opening-time" v-else>{{ $t('open_now') }}</span>
                                </div>
                                <div class="listing-card-title">
                                  <span class="restaurents-name">{{ get_place_name(placelist) }} </span>
                                  <!-- <span v-if="placelist.ratings != null" class="rating-label"><rating-prime :stars="1" :cancel="false" />{{placelist.ratings.rating+'.0'}}</span> -->
                                </div>
                                <div class="listing-card-subtitle">
                                  <span class="food-item">{{ set_cuisine_text(placelist.famouscuisine) }}</span>
                                  <!-- <span class="review-label">504 <span>reviews</span></span> -->
                                  <!-- <span class="review-label"><span>no ratings yet!</span></span> -->
                                </div>
                                <div class="listing-card-subtitle">
                                  <span class="food-item">{{ placelist.area == null ? "" : placelist.area.name+", " }} {{ placelist.city == null ? "" : placelist.city.name }} {{ placelist.country == null ? "" : placelist.country.name }}</span>
                                </div>
                                <div class="listing-card-subtitle home-review-label" style="min-height: 40px;text-align: left !important;border-top: 1px solid rgb(221 221 221);margin-top: 0.6rem;padding-top: 0.6rem;">
                                  <span class="review-label restaurents-review-label" style="text-align: left;"><span class="rating-box-area"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star-filled" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z" stroke-width="0" fill="currentColor" /></svg> {{(placelist.ratings != null ? placelist.ratings.rating+'.0' : $t('no_ratings_yet') )}} <span class="currency-icons" v-html="currency_icons(placelist)"></span></span></span><span class="review-label restaurents-review-label" style="text-align: left;text-align: right;"><span v-if="placelist.allow_booking==1" style="background: #dd3636;color: #fff;padding: 0.5rem;border-radius: 4px;font-size: 12px;cursor: pointer;" @click="book(placelist)">{{ $t('book_now') }}</span></span>
                                </div>
                              </div>
                            </div>
                            </router-link>
                          </div>
                      </div>
                    </div>
                </swiper-slide>
              </swiper>
          </div>

          <router-link :to="'/carousel/' + item.carousel_id"><button-prime :label="$t('view_more')" class="view-more-btn" rounded /></router-link>
        </div>
        <!-- / Restaurents Section Area -->

        <!-- Offers Section Area -->
        <div class="restaurens-box restaurents-section-area home-restaurent-sliderArea">
          <h2 class="section-title-text">{{ $t('top_offers') }}</h2>
          <div class="grid ml-0 mr-0">
            <swiper :slides-per-view="4" :space-between="10" setWrapperSize="ture" :navigation="true" :modules="modules"
              :breakpoints="{
                '@0.00': {slidesPerView: 1,spaceBetween: 0},
                '@0.75': {slidesPerView: 2,spaceBetween: 0},
                '@1.00': {slidesPerView: 3,spaceBetween: 0},
                '@1.50': {slidesPerView: 4,spaceBetween: 0},
              }"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
                <swiper-slide v-for="item in offers" :key="item">
                    <div class="grid ml-0 mr-0">
                      <div class="restaurents-main-box col-12 md:col-12 lg:col-12">
                        <div class="restaurents-box-items">
                          <div class="restaurents-box-image">
                            <swiper
                              :slides-per-view="4"
                              :space-between="10"
                              :modules="modules"
                              :navigation="true"
                              :breakpoints="{
                                '@0.00': { slidesPerView: 1, spaceBetween: 0,},
                                '@0.75': { slidesPerView: 1, spaceBetween: 0,},
                                '@1.00': { slidesPerView: 1, spaceBetween: 0,},
                                '@1.50': { slidesPerView: 1, spaceBetween: 0,},
                              }"
                              @swiper="onSwiper"
                              @slideChange="onSlideChange"
                              :pagination="{ clickable: true}"
                            >
                              <swiper-slide v-if="item.photos!=null">
                                <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                    <div class="mb-3">
                                      <router-link :to="'/offer-detail/' + item.id">   
                                        <img :src="item.photos.image_path"  class="w-6 shadow-2" />
                                      </router-link>
                                    </div>
                                </div>
                              </swiper-slide>
                              <swiper-slide v-else>
                                <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                    <div class="mb-3">
                                      <router-link :to="'/offer-detail/' + item.id">   
                                        <img v-bind:src="require('/src/assets/images/restaurent-sliders/slider2.jpg')"  class="w-6 shadow-2" />
                                      </router-link>
                                    </div>
                                </div>
                              </swiper-slide>
                            </swiper>
                          </div>
                          <div class="card-info-area card-info-restaurent-area">
                            <div class="restaurent-info-right restaurent-info-right-offer">
                              <div class="listing-card-title">
                                  <span class="restaurents-name event-name">{{ get_offer_name(item) }}</span>
                              </div>

                              <div class="listing-card-subtitle">
                                  <span class="event-time"> {{ get_place_name(item.place) }} </span>
                              </div>
          
                              <div class="listing-card-subtitle">
                                  <span class="event-time" v-html="get_offer_description(item)"></span>
                              </div>

                              <div class="listing-card-subtitle">
                                  <!-- <span class="event-time">{{item.currentdate}} {{ item.start_time }} </span> -->
                                  <span class="event-time event-time-valid-until"> <span class="pr-1">{{ $t('valid_until') }}</span> <span>{{item.currentdate}}</span> </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </swiper-slide>
              </swiper>
          </div>

          <router-link v-if="offers.length>0" to="/offers"><button-prime :label="$t('view_more')" class="view-more-btn" rounded /></router-link>
        </div>
        <!-- / Offers Section Area -->
        </main>
      </div>

      <FooterView />
      
      <div class="card flex justify-content-center text-center">
        <dialog-prime class="login-popup-modal" v-model:visible="login_alert_modal" modal header="" :style="{ width: '50vw', textAlign : 'center' }">
            <p>{{ $t('login_your_ac_first') }}</p>
            <router-link to="/signin"><button-prime :label="$t('signin')" class="" severity="primary" rounded /></router-link>
            <router-link to="/signin"><button-prime :label="$t('signup')" class="ml-2" severity="primary" rounded /></router-link>
        </dialog-prime>
      </div>
      
      <!-- Social Share Modal -->
      <dialog-prime v-model:visible="socialShareModal" modal header="Share with" :draggable="false" class="socialShareModal"> 
        <p>Share this via</p>
        <div class="social-widget social-widget-detail social-share-modal">
            <div class="widget-social-icon">
                <a target="_blank">
                    <div class="social-icon-circle">
                      <img src="../assets/Icons/footerFbIcon.svg">
                    </div>
                </a>
                <a target="_blank">
                    <div class="social-icon-circle">
                      <img src="../assets/Icons/instaIcon.svg">
                    </div>
                </a>
                <a target="_blank">
                    <div class="social-icon-circle">
                      <img src="../assets/Icons/linkdinIcon.svg">
                    </div>
                </a>
                <a target="_blank">
                    <div class="social-icon-circle">
                      <i class="pi pi-twitter" />
                    </div>
                </a>
                <a target="_blank">
                    <div class="social-icon-circle">
                      <img src="../assets/Icons/snapChatIcon.svg">
                    </div>
                </a>
                <a target="_blank">
                    <div class="social-icon-circle">
                        <img src="../assets/Icons/tiktokIcon.svg">
                    </div>
                </a>
            </div>
        </div>
      </dialog-prime>
      <!-- / Social Share Modal -->
      
  </div>
</template>
<script>
import HeaderView from '/src/components/Header/HeaderView.vue'
import FooterView from '/src/components/Footer/FooterView.vue'
import FilterPopup from '/src/components/FilterPopup.vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
// import Carousel from 'primevue/carousel';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation } from 'swiper';
import { Pagination } from 'swiper';
import { Autoplay } from 'swiper';
import axios from 'axios'
import $ from 'jquery'

export default {
  components: {
    HeaderView,
    FooterView,
    Swiper,
    SwiperSlide,
    // Carousel,
    FilterPopup
  },
  data() {
    return {
      responsiveOptionsThree : [
        {
          breakpoint: '1199px',
          numVisible: 1,
          numScroll: 1
        },
        {
          breakpoint: '991px',
          numVisible: 1, 
          numScroll: 1
        },
        {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1
        }
      ],
      bannersImage : [],
      categories : [],
      carousels : [],
      modules: [Navigation, Pagination, Autoplay],
      heartValue : false,
      like_value : 0,
      like_place : 0,
      login_alert_modal : false,
      liked_places : [],
      offers : []
     }
  },
  mounted() {
    this.get_main_banners();
    this.get_categories();
    this.get_carousels();
    this.get_offers();
  },
  methods: {
    get_offer_name : function(item) {
      var offer_name = item.name;
      if (this.amaken_language == 'AR') {
        offer_name = item.arabic_name;
      }
      if(offer_name == null) {
        offer_name = item.name;
      }
      return offer_name;
    },
    get_offer_description : function(item) {
      var offer_description = item.description;
      if (this.amaken_language == 'AR') {
        offer_description = item.arabic_description;
      }
      if(offer_description == null) {
        offer_description = item.description;
      }
      return offer_description;
    },
    get_place_name : function(item) {
      var place_name = item.name;
      if (this.amaken_language == 'AR') {
        place_name = item.arabic_name;
      }
      if(place_name == null) {
        place_name = item.name;
      }
      return place_name;
    },
    get_carousel_name : function(item) {
      var carousel_name = item.carousel_name;
      if (this.amaken_language == 'AR') {
        carousel_name = item.carousel_arabic_name;
      }
      if(carousel_name == null) {
        carousel_name = item.carousel_name;
      }
      return carousel_name;
    },
    get_category_name : function(item) {
      var cat_name = item.name;
      if (this.amaken_language == 'AR') {
        cat_name = item.arabic_name;
      }
      if(cat_name == null) {
        cat_name = item.name;
      }
      return cat_name;
    },
    get_offers : function() {
      axios.get(this.api_url+'get_offers_home_page').then((response) => {
        this.offers = response.data.offers;
      });
    },
    currency_icons : function(place) {
      if (place.ratings != null){
        var currencyIcons = '<i class="pi pi-dollar"></i><i class="pi pi-dollar"></i><i class="pi pi-dollar"></i>';
        var price = parseFloat(place.average_price);
        if (price < 100) {
          currencyIcons = '<i class="pi pi-dollar"></i><i class="pi pi-dollar"></i><i class="pi pi-dollar" style="color:lightgray !important"></i>';
        }
        if (price < 10) {
          currencyIcons = '<i class="pi pi-dollar"></i><i class="pi pi-dollar" style="color:lightgray !important"></i><i class="pi pi-dollar" style="color:lightgray !important"></i>';
        }
        return currencyIcons;
      } else {
        return '';
      }
      
    },
    book : function(place) {
        // axios.get(this.api_url+'is_user_loggedin', {
        //     headers: { 
        //         'Authorization': 'Bearer '+localStorage.getItem('token')
        //     }
        // }).then((response) => {
        //     if (response.data.status == false) {
        //         this.login_alert_modal = true;
        //     } else {
        //         this.$router.push({
        //             path: '/'+place.parentplace.slug+'/reservation',
        //             query : {
        //                 branch : place.slug
        //             }
        //         });
        //     }
        // }).catch((error) => {
        //     console.log(error);
        // });
        
        this.$router.push({
            path: '/'+place.parentplace.slug+'/reservation',
            query : {
                branch : place.slug
            }
        });
    },
    openedOrClosed : function(list) {
      var text = false;
      for (let i = 0; i < list.length; i++) {
        if (list[i].currentday.name == list[i].currentdayname) {
          var startTime = list[i].start_time;
          var endTime = list[i].end_time;
          var currentDate = new Date()   
          var startDate = new Date(currentDate.getTime());
          startDate.setHours(startTime.split(":")[0]);
          startDate.setMinutes(startTime.split(":")[1]);
          startDate.setSeconds(startTime.split(":")[2]);
          var endDate = new Date(currentDate.getTime());
          endDate.setHours(endTime.split(":")[0]);
          endDate.setMinutes(endTime.split(":")[1]);
          endDate.setSeconds(endTime.split(":")[2]);
          var valid = startDate < currentDate && endDate > currentDate;
          if(valid) {
            text = true;
            return text;
          }
        }
      }
      return text;
    },
    get_liked_data : function() {
      axios.get(this.api_url+'liked_places',{
        headers: { 
            'Authorization': 'Bearer '+localStorage.getItem('token')
        }
      }).then((response) => {
        if (response.data.status == true) {
          this.liked_places = response.data.data; 
          $.each(this.liked_places, function(index, value) {
            $('.place-id-'+value.place_id).addClass('heartActive');
          })
        }
      });
    },
    addremovelove : function(event, place_id) {
      this.like_place = place_id;
      if (event.target.classList.contains('heartActive')) {
        this.like_value = 0;
        this.heartValue = false;
      } else {
        this.like_value = 1;
        this.heartValue = true;
      }
      axios.get(this.api_url+'submit_like',{
        headers: { 
            'Authorization': 'Bearer '+localStorage.getItem('token')
        },
        params : {
          like : this.like_value,
          place : this.like_place
        } 
      }).then((response) => {
        if (response.data.status == false) {
          this.login_alert_modal = true;
        } else {
          if (!this.heartValue) {
            event.target.classList.remove('heartActive');
          } else {
            event.target.classList.toggle('heartActive');
            
          }
        }
      });
    },
    set_cuisine_text : function(cuisinedata) {
      var text = '';
      if(cuisinedata.length > 2){
        cuisinedata.length = 2;
      }
      for (let i = 0; i < cuisinedata.length; i++) {
        if(this.amaken_language == 'AR') {
          text += cuisinedata[i].filtername.arabic_name;
        } else if(this.amaken_language == 'EN') {
          text += cuisinedata[i].filtername.name;
        }
        
        if (cuisinedata.length-1 != i) {
          text += ", ";
        }
        
      }
      return text;
    },
    get_main_banners : function() {
      axios.get(this.api_url+'get_main_banners').then((response) => {
        this.bannersImage = response.data.banners;
      });
    },
    get_categories : function() {
      axios.get(this.api_url+'get_categories').then((response) => {
        this.categories = response.data.categories;
      });
    },
    get_carousels : function() {
      axios.get(this.api_url+'get_advertisements_for_carousel').then((response) => {
        this.carousels = response.data.carousels;
        this.get_liked_data();
      });
    },
    
  }
  
};
</script>
<script setup>
import { ref } from "vue";

const socialShareModal = ref(false);
</script>

<style>
/* *:not(html) {
  -webkit-transform: translate3d(0, 0, 0);
} */

.main-content {
  overflow-x: hidden;
}

.main-slide-container.home-slider .p-carousel.p-component.p-carousel-horizontal {
    display: none;
}

.main-slide-container.home-slider .swiper, 
.main-slide-container.home-slider swiper-container {
  overflow: hidden !important;
}
</style>
