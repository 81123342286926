<template>
        <div class="main-content booking-seating-view" :style="{background : customization.second_box_color}">
            <ReservationHeaderView :customization="customization" />

            <main>
                <div class="container">
                    <div class="col-12 md:col-12 lg:col-12">
                        <div v-if="customization.banner!=null" class="restaurent-img-thumbnail" :style="{backgroundImage : 'url('+customization.banner+')'}"></div>
                        <div v-else class="restaurent-img-thumbnail" :style="{background : customization.banner_background_color}"></div>

                        <div v-if="customization.logo!=null" class="logo-main-box-container" :style="{backgroundImage : 'url('+customization.logo+')'}"></div>
                        <div v-else class="logo-main-box-container"></div>
                    </div>   
                </div>
                <div class="container first-box-element" :style="{background : customization.first_box_color}">
                    <div class="reservation-container grid m-0 second-box-element" :style="{background : customization.second_box_color}">
                        <div class="col-12 md:col-12 lg:col-12">
                            <h4 :style="{color : customization.text_box_color}">Reservation Summary</h4>  

                            <div class="reservation-summary-container">
                                <div class="reservation-summary-info">
                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i class="pi pi-user" :style="{color : customization.text_box_color}"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ info.first_name }} {{ info.last_name }}</p></div>
                                    </div>
                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i class="pi pi-envelope" :style="{color : customization.text_box_color}"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ info.email }}</p></div>
                                    </div>
                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-phone"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ info.mobile }}</p></div>
                                    </div>
                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-user"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ info.persons }} Guests</p></div>
                                    </div>

                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-calendar"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ info.day }}, {{ info.date }}</p></div>
                                    </div>

                                    <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-clock"></i></div>
                                        <div class="info-text"><p :style="{color : customization.text_box_color}">{{ info.start_time }} - {{ info.end_time }}</p></div>
                                    </div>
                                    <div class="reservation-summary-info-list" v-if="info.reservation_note!= '' || info.reservation_note!= null">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-book"></i></div>
                                        <div class="info-text">
                                            <p class="pb-1" :style="{color : customization.text_box_color}">Reservation Notes</p>
                                            <p :style="{color : customization.text_box_color}">{{ info.reservation_note }}</p>
                                        </div>
                                    </div>
                                    <!-- <div class="reservation-summary-info-list">
                                        <div class="info-icon"><i :style="{color : customization.text_box_color}" class="pi pi-star"></i></div>
                                        <div class="info-text">
                                            <p :style="{color : customization.text_box_color}">Special Occasions</p>
                                            <p :style="{color : customization.text_box_color}">Birthday Event</p>
                                        </div>
                                    </div> -->
                                    <div class="reservation-summary-info-list reservation-summary-info-listInfo">
                                        <div class="information-text">
                                            <div class="info-icon"><i class="pi pi-info-circle"></i></div>
                                            <div class="info-text">
                                                <p class="pb-1">Important dining information</p>
                                                <p>We have a 15 minute grace period. Please call us if you are running later than 15 minutes after your reservation time.<br><br>Your table will be reserved for 2 hours for parties of up to 4; 2 hours 30 minutes for parties of up to 6; and 3 hours for parties of 7+.</p>
                                            </div>
                                        </div>

                                        <div class="information-text">
                                            <div class="info-icon"><i class="pi pi-book"></i></div>
                                            <div class="info-text">
                                                <p class="pb-1">A note from the restaurant</p>
                                                <p>Families with children below 12yrs are welcome to dine with us until 9PM.
                                            Smoking: Not permitted indoors (including e-cigarettes), only at the terrace.
                                            Dress code: Smart casual (no flip flops, no tank tops, no gym nor beach wear).
                                            Discounts are not applicable during Public Holidays, special offers, promotions, set menus and brunches.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-0 checkbox-reservationSummary">
                                    <checkbox-prime v-model="privacyPolicy" inputId="privacyPolicyCheck" name="privacyPolicy" value="" />
                                    <label for="privacyPolicyCheck" class="ml-1" :style="{color : customization.text_box_color}"> Sign me up to receive dining offers and news from Amaken by email. </label>
                                </div>

                                <div class="form-fields-main col-12 md:col-12 lg:col-12 mb-1 checkbox-reservationSummary">
                                    <checkbox-prime v-model="receiveNews" inputId="receiveNewsCheck" name="receiveNews" value="" />
                                    <label for="receiveNewsCheck" class="ml-1" :style="{color : customization.text_box_color}"> Yes, I want to get text updates and reminders about my reservations. </label>
                                </div>
                            </div>

                            <div class="col-12 md:col-12 lg:col-12 p-0">
                                <router-link :to="'/' + $route.params.slug+'/thank-you'">
                                    <button-prime class="find-a-time"  label="Confirm" severity="success" rounded :style="{color : customization.text_box_color, background : customization.button_box_color}">Continue</button-prime>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <ReservationFooterView :customization="customization" />
        </div>
</template>
  
<script setup>
import { ref } from "vue";

const receiveNews = ref();
const privacyPolicy = ref();
</script>

<script>
import ReservationHeaderView from '/src/components/reservationHeader.vue'
import ReservationFooterView from '/src/components/reservationFooter.vue'
import axios from 'axios'
import moment from 'moment'
export default {
    components: {
        ReservationHeaderView,
        ReservationFooterView,
    },
    data() {
        return {
            customization : {
                banner_background_color : '#313131',
                header_color : '#000000',
                footer_color : '#000000',
                first_box_color : '#212121',
                second_box_color : '#2C2C2C',
                text_box_color : '#FFFFFF',
                field_box_color : '#000000',
                button_box_color : '#884192',
                banner : null,
                logo : null
            },
            info : {
                first_name : null,
                last_name : null,
                email : null,
                mobile : null,
                persons : null,
                day : null,
                date : null,
                start_time : null,
                end_time : null,
                reservation_note : null,
                occassion : null
            }
        }
    },
    mounted() {
        this.get_customization_settings();
        this.get_booking_details();
    },
    methods: { 
        get_booking_details : function() {
            axios.get(this.api_url+'get_booking_details', {
                params: { 
                    id: atob(this.$route.query.id),
                    slug : this.$route.params.slug
                } 
            }).then((response) => {
                if (response.data.data.customization != '') {
                    this.info.first_name = response.data.data.details.first_name;
                    this.info.last_name = response.data.data.details.last_name;
                    this.info.email = response.data.data.details.email;
                    this.info.mobile = response.data.data.details.mobile;
                    this.info.persons = response.data.data.details.persons;
                    this.info.day = moment(response.data.data.details.start_date_time).format('dddd');
                    this.info.date = moment(response.data.data.details.start_date_time).format('DD MMMM YYYY');
                    this.info.start_time = moment(response.data.data.details.start_date_time).format('hh:mm A');
                    this.info.end_time = moment(response.data.data.details.end_date_time).format('hh:mm A');
                    if (response.data.data.customization != '') {
                        this.info.reservation_note = response.data.data.customization.note;
                    }
                    
                }
            });
        },
        get_customization_settings : function() {
            axios.get(this.api_url+'get_customization', {
                params: { 
                    slug: this.$route.params.slug,
                    type : 'branch'
                } 
            }).then((response) => {
                if (response.data.data.customization != '') {
                    this.customization = response.data.data.customization;
                }
            });
        }
    }
};
</script>
  
<style scoped>
.reservation-summary-info-list {
    display: flex;
    align-items: flex-start;
    column-gap: 0.8rem;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
}

.reservation-summary-info-list .info-icon {
    display: flex;
    align-items: center;
    transform: translateY(3px);
}

.reservation-summary-info-list .info-icon i.pi {
    color: #fff;
}

.reservation-summary-info-list .info-text p {
    color: #fff;
    font-size: 14px;
}

.reservation-summary-container {
    margin-top: 1.5rem;
}
</style>