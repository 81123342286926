<template>
    <div class="reservation-page-footer" :style="{backgroundColor : customization.header_color, color : customization.text_box_color}">
        <p>powered by Amakenapp</p>
    </div>
</template>
<script>
export default {
    props: ['customization'],
    data() {
        return {
            
        }
    },

    mounted() {
        
    },

    methods: { 
        
    }
};
</script>