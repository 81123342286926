<template>
    <div class="signin-wrapper">
        <div class="grid m-0">
            <div class="col-12 md:col-6 lg:col-6 signIn-form-leftArea">
                
                <div class="dropdown-languageSwitcher">
                    <p class="language-switcher" @click="toggleLang" aria-haspopup="true" aria-controls="overlay_tmenuLang">
                        <span>
                            <i class="pi pi-globe" style="transform: translateY(3px);font-size: 1.2rem;padding-right: 0.1rem;"></i>
                            {{selected_language}}
                        </span>
                        <i class="pi pi-angle-down" style="transform: translateY(2px);font-size: 0.8rem;padding-left: 0.1rem;"></i>
                    </p>
                    <div class="card flex justify-content-center profile-setting-dropdown">
                        <tieredMenu-prime ref="menuLang" id="overlay_tmenuLang" :model="items_lang" popup class="" />
                    </div>
                </div>

                <router-link to="/">
                <div class="logo-area">
                    <img class="site-logo-amaken" src="../assets/images/logo/amaken-logo.svg">
                </div>
                </router-link>

                <div class="sign-from-switch-area">
                    <selectButton-prime v-model="section_selected" :options="options" aria-labelledby="basic" />
                </div>
                
                <!-- Sign In Form -->
                <div class="sign-form-area" id="signIn-from" v-if="section_selected==$t('signin')">
                    <message-prime severity="error" v-if="signinValidationErrors.length">{{signinValidationErrors[0]}}</message-prime>
                    <message-prime severity="success" v-if="success_message != null">{{success_message}}</message-prime>
                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                            <inputText-prime class="form-input-fields" v-model="signin_email" :placeholder="$t('email')" />
                        </span>
                    </div>

                    <div class="form-fields-main form-fields-mainMarginBottom">
                        <span class="p-input-icon-left">
                            <img class="inputFields-icons" src="../assets/Icons/password-icon.svg">
                            <password-prime class="form-input-fields" v-model="signin_password" toggleMask :feedback="false" :placeholder="$t('password')" />
                        </span>
                    </div>

                    <div class="form-fields-main">
                        <router-link to="/forgot-password">
                        <span class="forgotPassword-btn">
                            {{$t('forgot_password')}}
                        </span>
                        </router-link>
                    </div>
                    
                    <div class="form-fields-main">
                        <button-prime @click="submitSignInForm" :loading="loading" :label="$t('log_in')" rounded />
                    </div>

                    <!-- <div class="form-fields-main">
                        <div class="flex align-items-center remember-check">
                            <checkbox-prime v-model="checked" :binary="true" />
                            <label for="ingredient1" class="ml-2"> Remember Me </label>
                        </div>
                    </div> -->
                </div>
                <!-- / Sign In Form -->

                <!-- Sign Up Form -->
                <div class="sign-form-area" id="signIn-from" v-if="section_selected==$t('signup')">
                    <message-prime severity="error" v-if="signupValidationErrors.length">{{signupValidationErrors[0]}}</message-prime>

                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                            <inputText-prime class="form-input-fields" v-model="signup_first_name" :placeholder="$t('first_name')" />
                        </span>
                    </div>

                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <img class="inputFields-icons" src="../assets/Icons/user-icon.svg">
                            <inputText-prime class="form-input-fields" v-model="signup_last_name" :placeholder="$t('last_name')" />
                        </span>
                    </div>

                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <vue-tel-input v-model="signup_phone" mode="international" :inputOptions="phone_placeholder"></vue-tel-input>
                        </span>
                    </div>

                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                            <path d="M3 7l9 6l9 -6" />
                            </svg>
                            <inputText-prime class="form-input-fields" v-model="signup_email" :placeholder="$t('write_your_email')" />
                        </span>
                    </div>

                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                            <path d="M3 7l9 6l9 -6" />
                            </svg>
                            <inputText-prime class="form-input-fields" v-model="signup_rewrite_email" :placeholder="$t('rewrite_your_email')" />
                        </span>
                    </div>

                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <img class="inputFields-icons" src="../assets/Icons/password-icon.svg">
                            <password-prime class="form-input-fields" v-model="signup_password" :feedback="false" toggleMask :placeholder="$t('password')" />
                        </span>
                    </div>

                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <img class="inputFields-icons" src="../assets/Icons/password-icon.svg">
                            <password-prime class="form-input-fields" v-model="signup_confirm_password" :feedback="false" toggleMask :placeholder="$t('confirm_pass')" />
                        </span>
                    </div>
                    
                    <div class="form-fields-main">
                        <button-prime @click="submitSignUpForm" :label="$t('signup')" :loading="loading" rounded />
                    </div>
                </div>
                <!-- / Sign Up Form -->

                <div class="signWith-social-platform-area">
                    <p>{{$t('login_with_social_media')}}</p>
                    <div class="signIn-social-buttons">
                        <a :href="external_login_facebook">
                            <button-prime rounded aria-label="Filter" class="social-login-fb"> 
                                <img src="../assets/Icons/social-login-facebook-icon.svg">
                            </button-prime>
                        </a>    
                        <a :href="external_login_google">
                            <button-prime severity="danger" rounded aria-label="Cancel" class="social-login-gmail"> 
                                <img src="../assets/Icons/social-login-gmail-icon.svg"> 
                            </button-prime>
                        </a>    
                        <button-prime severity="secondary" rounded aria-label="Bookmark" class="social-login-apple">
                            <img src="../assets/Icons/social-login-apple-icon.svg">
                        </button-prime>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-6 signIn-Info-rightArea">
                <div class="welcome-text-sign">
                    <h2>{{ $t('welcome_to_amaken') }}</h2>
                    <!-- <p class="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
const menuLang = ref();
const toggleLang = (event) => {
    menuLang.value.toggle(event);
};
</script>

<script>
import axios from 'axios'
import $ from 'jquery'
export default {
    
    data() {
        return {
            phone_placeholder: { placeholder: this.$t('phone_number') },
            items_lang : [{label: 'EN', command: () => this.language('EN')},{label: 'AR', command: () => this.language('AR')}],
            selected_language : localStorage.getItem('amaken_language'),
            options : [this.$t('signin'), this.$t('signup')],
            section_selected : this.$t('signin'),
            checked : false,
            signin_email : null,
            signin_password : null,
            signinValidationErrors: [],
            signupValidationErrors: [],
            signup_email : null,
            signup_phone : null,
            signup_first_name : null,
            signup_last_name : null,
            signup_rewrite_email : null,
            signup_password : null,
            signup_confirm_password : null,
            loading : false,
            success_message : null
        }
    },
    mounted() {
        $('body').addClass('signIn-page');
        const urls = new URL(window.location);
        if(urls.searchParams.get('account') == "verified") {
            this.success_message = this.$t('account_verified_message');
        }
        if(urls.searchParams.get('password') == "changed") {
            this.success_message = this.$t('password_changed_message');
        }
    },
    methods : {
        language : function(param) {
            localStorage.setItem('amaken_language', param);
            location.reload();
        },
        submitSignInForm : function() {
            this.success_message = null;
            this.signinValidationErrors = [];
            this.loading = true;
            axios.get(this.api_url+'signin',{
                params: { 
                    email : this.signin_email,
                    password : this.signin_password,
                    lang : this.amaken_language
                } 
            }).then((response) => {
                this.loading = false;
                localStorage.setItem('token', response.data.token);  
                this.$router.push({name : 'home'});
            }).catch((error) => {
                this.loading = false;
                if(error.response.status == 401) {
                    this.signinValidationErrors.push(error.response.data.errors);
                }
            });
        },
        submitSignUpForm : function() {
            this.signupValidationErrors = [];
            this.loading = true;
            axios.get(this.api_url+'signup',{
                params: { 
                    email : this.signup_email,
                    password : this.signup_password,
                    confirm_password : this.signup_confirm_password,
                    rewrite_email : this.signup_rewrite_email,
                    first_name : this.signup_first_name,
                    last_name : this.signup_last_name,
                    phone : this.signup_phone,
                    lang : this.amaken_language
                } 
            }).then((response) => {
                console.log(response);
                this.loading = false;
                this.$router.push({
                    name: 'verify-email', 
                    query: {
                        token : response.data.token,
                    } 
                });
            }).catch((error) => {
                this.loading = false;
                if(error.response.status == 401) {
                    this.signupValidationErrors.push(error.response.data.errors);
                }
            });
        }
    }
}
</script>

