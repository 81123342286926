<template>
    <div class="main-content booking-seating-view" :style="{background : customization.second_box_color}">
        <main class="make-review-container-main">
            <div v-if="successed==1" class="container first-box-element make-review-box-container" :style="{background : customization.first_box_color}">
                <div class="reservation-container grid m-0 second-box-element" :style="{background : customization.second_box_color}">
                    <div class="col-12 md:col-12 lg:col-12">
                        <h4 class="" :style="{color : customization.text_box_color}">Give your experience</h4>  
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 fields-form-row">
                        <label class="reservation-label" :style="{color : customization.text_box_color}">Review Title</label>
                        <span class="p-input-icon-left w-full">
                            <inputText-prime v-model="title" placeholder="Write review title" class="form-input-fields review-title-field" />
                        </span>
                    </div>
                    
                    <div class="col-12 md:col-12 lg:col-12 fields-form-row">
                        <label class="reservation-label" :style="{color : customization.text_box_color}">Overall Experience</label>
                        <div class="make-review-stars">
                            <rating-prime v-model="overall_rating" :cancel="false"  />
                        </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 fields-form-row">
                        <label class="reservation-label" :style="{color : customization.text_box_color}">Food</label>
                        <div class="make-review-stars">
                            <rating-prime v-model="food_rating" :cancel="false"  />
                        </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 fields-form-row">
                        <label class="reservation-label" :style="{color : customization.text_box_color}">Service</label>
                        <div class="make-review-stars">
                            <rating-prime v-model="service_rating" :cancel="false"  />
                        </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 fields-form-row">
                        <label class="reservation-label" :style="{color : customization.text_box_color}">Atmosphere</label>
                        <div class="make-review-stars">
                            <rating-prime v-model="atmosphere_rating" :cancel="false"  />
                        </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 fields-form-row mb-0">
                        <label class="reservation-label" :style="{color : customization.text_box_color}">Share details of your experience and let us know what we can do better for your next visit</label>
                        <div class="make-review-comment">
                            <textarea-prime v-model="description" class="make-review-textarea" placeholder="Write your review…" autoResize rows="5" cols="30" />
                        </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12">
                        <button-prime class="find-a-time" label="Submit" :loading="loading" severity="success" rounded :style="{color : customization.text_box_color, background : customization.button_box_color}"  @click="save_reviews">Submit</button-prime>
                    </div>
                </div>
            </div>

            <!-- Thank You Box -->
            <div v-if="successed==2" class="container first-box-element make-review-box-container" :style="{background : customization.first_box_color}">
                <div class="reservation-container grid m-0 second-box-element" :style="{background : customization.second_box_color}">
                    <div class="col-12 md:col-12 lg:col-12 thank-you-message-box">
                        <div class="review-stars-icons">
                            <i class="pi pi-star-fill" style="font-size: 2rem"></i>
                            <i class="pi pi-star-fill" style="font-size: 4rem"></i>
                            <i class="pi pi-star-fill" style="font-size: 2rem"></i>
                        </div>
                        <h4 class="" :style="{color : customization.text_box_color}">Thank you for your review</h4>
                        <p class="thank-you-text" :style="{color : customization.text_box_color}">We can’t wait for your next visit.</p>
                    </div>
                </div>
            </div>

            <div v-if="successed==3" class="container first-box-element make-review-box-container" :style="{background : customization.first_box_color}">
                <div class="reservation-container grid m-0 second-box-element" :style="{background : customization.second_box_color}">
                    <div class="col-12 md:col-12 lg:col-12 thank-you-message-box">
                        <h4 class="" :style="{color : customization.text_box_color}">Invalid url</h4>
                    </div>
                </div>
            </div>

            <toast-prime />
        </main>  
    </div>
</template>

<script>
import axios from 'axios'
// import { useToast } from "primevue/usetoast";
export default {
    data() {
        return {
            overall_rating : 0,
            food_rating : 0,
            atmosphere_rating : 0,
            service_rating : 0,
            title : null,
            description : null,
            loading : false,
            successed : 1,
            customization : {
                banner_background_color : '#313131',
                header_color : '#000000',
                footer_color : '#000000',
                first_box_color : '#212121',
                second_box_color : '#2C2C2C',
                text_box_color : '#FFFFFF',
                field_box_color : '#000000',
                button_box_color : '#884192',
                banner : null,
                logo : null,
                note : null
            },
        }
    },
    mounted() {
        this.get_customization_settings();
        this.is_already_submitted();
    },
    methods: {
        is_already_submitted : function() {
            axios.get(this.api_url+'is_already_submitted', {
                params: { 
                    id: this.$route.params.id,
                } 
            }).then(() => {
                
            }).catch((error) => {
                if(error.response.status == 401) {
                    if(error.response.data.errors == 'invalid') {
                        this.successed = 3;
                    }
                    if(error.response.data.errors == 'alreay-submitted') {
                        this.successed = 2;
                    }
                }
            });
        },
        save_reviews : function() {
            if (this.title == null || this.title == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Title is required!', life: 3000 });
                return false;
            } else if (this.overall_rating == 0) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Overall experience rating is required!', life: 3000 });
                return false;
            } else if (this.food_rating == 0) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Food rating is required!', life: 3000 });
                return false;
            } else if (this.service_rating == 0) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Service rating is required!', life: 3000 });
                return false;
            } else if (this.atmosphere_rating == 0) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Atmosphere rating is required!', life: 3000 });
                return false;
            } else if (this.description == null || this.description == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Review is required!', life: 3000 });
                return false;
            }
            axios.get(this.api_url+'save_review', {
                params: { 
                    id: this.$route.params.id,
                    title : this.title,
                    overall_rating : this.overall_rating,
                    food_rating : this.food_rating,
                    service_rating : this.service_rating,
                    atmosphere_rating : this.atmosphere_rating,
                    description : this.description,
                } 
            }).then((response) => {
                if (response.data.status == true) {
                    this.successed = 2;
                }
            });
        },
        get_customization_settings : function() {
            axios.get(this.api_url+'get_customization', {
                params: { 
                    slug: this.$route.params.slug,
                    type : 'place'
                } 
            }).then((response) => {
                if (response.data.data.customization != '') {
                    this.customization = response.data.data.customization;
                }
            });
        }
    }
};
</script>



<style scoped>
.p-inputtext{
    color: v-bind('customization.text_box_color')!important;
    background : v-bind('customization.field_box_color') !important;
}
.pi-star-fill{
    color: gold;
}
</style>