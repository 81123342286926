<template>
  <HeaderView />

  <div class="main-content place-detail-view">        
      <main>
          <div class="main-slide-container home-slider no-bg-image grid-slider-swiper grid-slider-swiperEvent grid-slider-swiperOffer">
            <div>
              <div class="grid m-0">
                <div class="col-12 md:col-6 md:col-6 p-0">
                  <div class="slider-left-content-event-area slider-left-content-event-area-offer">
                    <div class="section-title">
                      <!-- {{$t('event_banner_text')}} -->
                      Enjoy Top Offers In Doha At Esteemed Places. Book With Convenience.
                    </div>
                    <div class="leftBannerSec-bottom-text">
                      <p>{{$t('5000_restaurents')}}</p>
                      <p>{{ $t('banner_subtitle_text') }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 md:col-6 md:col-6 p-0">
                  <div class="main-slide-container event-bg">
                    
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <!-- / Main Slides Area -->

          <!-- <EventsFilterPopup @events="getEvents"></EventsFilterPopup> -->
          <FilterPopup></FilterPopup>

          <!-- Restaurents Section Area -->
          <div class="restaurens-box restaurents-section-area">
              <div class="grid m-0">

                  <div class="all-restaurents-leftBox col-12 md:col-12 lg:col-12">
                      <div class="flex align-items-center justify-content-between mb-5">
                          <h2 class="section-title-text mb-0 margin-top-0">
                            {{ $t('all_offers') }}
                          </h2>
                      </div>

                      <div v-bind:class="{ 'grid ml-0 mr-0 restaurents-grid-layout': !isHidden, 'grid ml-0 mr-0 restaurents-list-layout events-list-layout' : isHidden}">
                          <div v-bind:class="{ 'col-12 md:col-3 lg:col-3': !isHidden, 'col-12 md:col-12 lg:col-12' : isHidden}" v-for="item in offers" :key="item">
                              <div class="restaurents-box-items">
                                  <div class="restaurents-box-image">
                                    <swiper
                                      :slides-per-view="4"
                                      :space-between="10"
                                      :modules="modules"
                                      :navigation="true"
                                      :breakpoints="{
                                        '@0.00': { slidesPerView: 1, spaceBetween: 0,},
                                        '@0.75': { slidesPerView: 1, spaceBetween: 0,},
                                        '@1.00': { slidesPerView: 1, spaceBetween: 0,},
                                        '@1.50': { slidesPerView: 1, spaceBetween: 0,},
                                      }"
                                      @swiper="onSwiper"
                                      @slideChange="onSlideChange"
                                      :pagination="{ clickable: true}"
                                    >
                                    <swiper-slide v-if="item.photos!=null">
                                        <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                            <div class="mb-3">
                                              <router-link :to="'/offer-detail/' + item.id">   
                                                <img :src="item.photos.image_path"  class="w-6 shadow-2" />
                                              </router-link>
                                            </div>
                                        </div>
                                      </swiper-slide>
                                      <swiper-slide v-else>
                                        <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                            <div class="mb-3">
                                              <router-link :to="'/offer-detail/' + item.id">   
                                                <img v-bind:src="require('/src/assets/images/restaurent-sliders/slider2.jpg')"  class="w-6 shadow-2" />
                                              </router-link>
                                            </div>
                                        </div>
                                      </swiper-slide>
                                    </swiper>
 
                                    <div class="restaurent-logo">
                                      <!-- <img v-bind:src="require('/src/assets/images/logo/amaken-logo.svg')"> -->
                                    </div>
                                  </div>
                                  <div class="card-info-area card-info-restaurent-area"><!--Grid view-->
                                    <div class="restaurent-info-right restaurent-info-right-offer">
                                      <div class="listing-card-title">
                                          <span class="restaurents-name event-name">{{ get_offer_name(item) }}</span>
                                      </div>

                                      <div class="listing-card-subtitle">
                                          <span class="event-time"> {{ get_place_name(item.place) }} </span>
                                      </div>
                  
                                      <div class="listing-card-subtitle">
                                          <span class="event-time" v-html="get_offer_description(item)"></span>
                                      </div>

                                      <div class="listing-card-subtitle">
                                          <!-- <span class="event-time">{{item.currentdate}} {{ item.start_time }} </span> -->
                                          <span class="event-time event-time-valid-until"> <span class="pr-1">{{ $t('valid_until') }}</span> <span>{{item.currentdate}}</span> </span>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
          <!-- / Restaurents Section Area -->

      </main>
  </div>

  <FooterView />
</template>

<script>
import HeaderView from '/src/components/Header/HeaderView.vue'
import FooterView from '/src/components/Footer/FooterView.vue'
// import EventsFilterPopup from '/src/components/EventsFilterPopup.vue'
import FilterPopup from '/src/components/FilterPopup.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import axios from 'axios'
// import $ from 'jquery'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation } from 'swiper';
import { Pagination } from 'swiper';
import { Autoplay } from 'swiper';

export default {
  components: {
      HeaderView,
      FooterView,
      Swiper,
      SwiperSlide,
      FilterPopup
      // EventsFilterPopup
  },
  data() {
      return {
        responsiveOptionsThree : [{breakpoint: '1199px',numVisible: 1,numScroll: 1},{breakpoint: '991px',numVisible: 1, numScroll: 1},{breakpoint: '767px',numVisible: 1,numScroll: 1}],
        offers : [],
        modules: [Navigation, Pagination, Autoplay],
        isHidden: false
      }
      
  },
  mounted() {
    this.get_offers();
  },
  methods: {
    get_offer_name : function(item) {
      var offer_name = item.name;
      if (this.amaken_language == 'AR') {
        offer_name = item.arabic_name;
      }
      if(offer_name == null) {
        offer_name = item.name;
      }
      return offer_name;
    },
    get_offer_description : function(item) {
      var offer_description = item.description;
      if (this.amaken_language == 'AR') {
        offer_description = item.arabic_description;
      }
      if(offer_description == null) {
        offer_description = item.description;
      }
      return offer_description;
    },
    get_place_name : function(item) {
      var place_name = item.name;
      if (this.amaken_language == 'AR') {
        place_name = item.arabic_name;
      }
      if(place_name == null) {
        place_name = item.name;
      }
      return place_name;
    },
    get_offers : function() {
      axios.get(this.api_url+'get_offers').then((response) => {
        this.offers = response.data.offers;
      });
    }
  }
};
</script>

<style>
</style>

