<template>
    <div class="main-content booking-seating-view" :style="{background : customization.second_box_color}">
        
            <div class="reservation-page-header" :style="{backgroundColor : customization.header_color, color : customization.text_box_color}">
                <!-- <i @click="back" class="pi pi-angle-left back-btn-arrow" style="font-size: 1.5rem"></i> -->
                <div class="reservation-header-tabs">
                    <div class="reservation-header-tabContent">
                        <router-link :to="'/'+$route.params.slug+'/reservation/'">
                            <i :style="{color : customization.text_box_color}" class="pi pi-calendar"></i>
                            <p :style="{color : customization.text_box_color}">{{$t('reserve')}}</p>
                        </router-link>
                    </div>
                    <div class="reservation-header-tabContent">
                        <router-link :to="'/'+$route.params.slug+'/reviews/'">
                            <i :style="{color : customization.text_box_color}" class="pi pi-star-fill"></i>
                            <p :style="{color : customization.text_box_color}">{{$t('reviews')}}</p>
                        </router-link>
                    </div>
                    <div class="reservation-header-tabContent">
                        <router-link :to="'/'+$route.params.slug+'/contact/'">
                            <i :style="{color : customization.text_box_color}" class="pi pi-phone"></i>
                            <p :style="{color : customization.text_box_color}">{{$t('contact')}}</p>
                        </router-link>
                    </div>
                </div>
            </div>

        <main>
            <div class="container">
                <div class="col-12 md:col-12 lg:col-12">
                    <div v-if="customization.banner!=null" class="restaurent-img-thumbnail" :style="{backgroundImage : 'url('+customization.banner+')'}"></div>
                    <div v-else class="restaurent-img-thumbnail" :style="{background : customization.banner_background_color}"></div>

                    <div v-if="customization.logo!=null" class="logo-main-box-container" :style="{backgroundImage : 'url('+customization.logo+')'}"></div>
                    <div v-else class="logo-main-box-container"></div>
                </div>   
            </div>
            <div class="container first-box-element" :style="{background : customization.first_box_color}">
                <div class="reservation-container grid m-0 second-box-element" :style="{background : customization.second_box_color}">
                    <div class="col-12 md:col-12 lg:col-12">
                        <h4 :style="{color : customization.text_box_color}">{{$t('contact')}}</h4>  

                        <div class="reservationContact-container" v-for="item in branches" :key="item">
                            <div class="reservationContact-listing">
                                <div class="icon-box-contact">
                                    <i :style="{color : customization.text_box_color}" class="pi pi-building"></i>
                                </div>
                                <div class="info-box-contact">
                                    <label class="reservationContact-label" :style="{color : customization.text_box_color}">{{$t('branch_name')}}</label>
                                    <p class="reservationContact-infoText" :style="{color : customization.text_box_color}">{{ item.name }}</p>
                                </div>
                            </div>

                            <div class="reservationContact-listing">
                                <div class="icon-box-contact">
                                    <i :style="{color : customization.text_box_color}" class="pi pi-globe"></i>
                                </div>
                                <div class="info-box-contact">
                                    <label :style="{color : customization.text_box_color}" class="reservationContact-label">{{$t('country')}}</label>
                                    <p :style="{color : customization.text_box_color}" class="reservationContact-infoText">{{ item.country == null ? '' : item.country.name }}</p>
                                </div>
                            </div>
                            
                            <div class="reservationContact-listing">
                                <div class="icon-box-contact">
                                    <i :style="{color : customization.text_box_color}" class="pi pi-building"></i>
                                </div>
                                <div class="info-box-contact">
                                    <label :style="{color : customization.text_box_color}" class="reservationContact-label">{{$t('city')}}</label>
                                    <p :style="{color : customization.text_box_color}" class="reservationContact-infoText">{{ item.city == null ? '' : item.city.name }}</p>
                                </div>
                            </div>

                            <div class="reservationContact-listing">
                                <div class="icon-box-contact">
                                    <i :style="{color : customization.text_box_color}" class="pi pi-map-marker"></i>
                                </div>
                                <div class="info-box-contact">
                                    <label :style="{color : customization.text_box_color}" class="reservationContact-label">{{$t('address')}}</label>
                                    <p :style="{color : customization.text_box_color}" class="reservationContact-infoText">{{ item.address }}</p>
                                </div>
                            </div>

                            <div class="reservationContact-listing">
                                <div class="icon-box-contact">
                                    <i :style="{color : customization.text_box_color}" class="pi pi-phone"></i>
                                </div>
                                <div class="info-box-contact">
                                    <label :style="{color : customization.text_box_color}" class="reservationContact-label">{{$t('mobile')}}</label>
                                    <p :style="{color : customization.text_box_color}" class="reservationContact-infoText">{{ item.mobile }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="reservationContact-container">
                            <div class="reservationContact-listing">
                                <div class="icon-box-contact">
                                    <i class="pi pi-building"></i>
                                </div>
                                <div class="info-box-contact">
                                    <label class="reservationContact-label">Branch Name</label>
                                    <p class="reservationContact-infoText">Hunters Room and Grill</p>
                                </div>
                            </div>

                            <div class="reservationContact-listing">
                                <div class="icon-box-contact">
                                    <i class="pi pi-globe"></i>
                                </div>
                                <div class="info-box-contact">
                                    <label class="reservationContact-label">Country</label>
                                    <p class="reservationContact-infoText">Qatar</p>
                                </div>
                            </div>
                            
                            <div class="reservationContact-listing">
                                <div class="icon-box-contact">
                                    <i class="pi pi-building"></i>
                                </div>
                                <div class="info-box-contact">
                                    <label class="reservationContact-label">City</label>
                                    <p class="reservationContact-infoText">Doha</p>
                                </div>
                            </div>

                            <div class="reservationContact-listing">
                                <div class="icon-box-contact">
                                    <i class="pi pi-map-marker"></i>
                                </div>
                                <div class="info-box-contact">
                                    <label class="reservationContact-label">Address</label>
                                    <p class="reservationContact-infoText">Salwa Road, Bin Mahmoud South, Zone 23, Doha, Ad Dawhah al Jadidah</p>
                                </div>
                            </div>

                            <div class="reservationContact-listing">
                                <div class="icon-box-contact">
                                    <i class="pi pi-phone"></i>
                                </div>
                                <div class="info-box-contact">
                                    <label class="reservationContact-label">Mobile</label>
                                    <p class="reservationContact-infoText">+974 00000000</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </main>

        <div class="reservation-page-footer" :style="{backgroundColor : customization.header_color, color : customization.text_box_color}">
            <p>powered by Amakenapp</p>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
// import moment from 'moment'


export default {
    
    data() {
        return {
            customization : {
                banner_background_color : '#313131',
                header_color : '#000000',
                footer_color : '#000000',
                first_box_color : '#212121',
                second_box_color : '#2C2C2C',
                text_box_color : '#FFFFFF',
                field_box_color : '#000000',
                button_box_color : '#884192',
                banner : null,
                logo : null,
                note : null
            },
            branches : []
        }
    },
    mounted() {
        this.get_customization_settings();
        this.get_branches();
    },

    methods: { 
        get_branches : function() {
            axios.get(this.api_url+'get_branches', {
                params: { 
                    id: this.$route.params.slug,
                } 
            }).then((response) => {
                this.branches = response.data.data.place.branches;
            });
        },
        get_customization_settings : function() {
            axios.get(this.api_url+'get_customization', {
                params: { 
                    slug: this.$route.params.slug,
                    type : 'place'
                } 
            }).then((response) => {
                if (response.data.data.customization != '') {
                    this.customization = response.data.data.customization;
                }
            });
        },
    }

};
</script>
  
<style scoped>
.reservation-summary-info-list {
    display: flex;
    align-items: flex-start;
    column-gap: 0.8rem;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
}

.reservation-summary-info-list .info-icon {
    display: flex;
    align-items: center;
    transform: translateY(3px);
}

.reservation-summary-info-list .info-icon i.pi {
    color: #fff;
}

.reservation-summary-info-list .info-text p {
    color: #fff;
    font-size: 14px;
}

.reservation-summary-container {
    margin-top: 1.5rem;
}

img.thankYouEmailImg {
    width: 50%;
    margin: auto;
    display: flex;
}

.content-box {
    margin-top: 2.5rem;
}

.reservation-thankYou-container .content-box p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

.reservation-thankYou-container .content-box h4 {
    margin-top: 0;
    font-size: 16px !important;
    margin-bottom: 0.5rem;
}

.reservation-thankYou-container .content-box.contact-content-box p {
    margin-bottom: 0.5rem;
}
</style>