<template>
    <div class="reservation-template-header">
            <div class="reservation-template-header-tabs">
                <div class="reservation-template-header-tabContent">
                    <router-link to="/3/reserve">
                        <i class="pi pi-calendar"></i>
                        <p>Reserve</p>
                    </router-link>
                </div>
                <div class="reservation-template-header-tabContent">
                    <router-link to="/">
                        <i class="pi pi-star-fill"></i>
                        <p>Reviews</p>
                    </router-link>
                </div>
                <div class="reservation-template-header-tabContent">
                    <router-link to="/">
                        <i class="pi pi-phone"></i>
                        <p>Contact</p>
                    </router-link>
                </div>
            </div>
        </div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
      }
    },
    methods: {
    },
    mounted(){
    } 
  }
</script>

<style>
.reservation-content-container-interactive .reservation-template-header {
    min-height: 75px;
    background: #fff;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.reservation-content-container-interactive .reservation-template-header-tabs {
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.reservation-content-container-interactive .reservation-template-header-tabContent {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    row-gap: 0.3rem;
    justify-content: center;
    border-right: 0;
    cursor: pointer;
    /* background: rgb(255 255 255 / 20%); */
    border-radius: 10px;
}

.reservation-content-container-interactive .reservation-template-header-tabContent a {
    display: flex;
    align-items: center;
    row-gap: 0.3rem;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    column-gap: 10px;
    min-width: 120px;
}

.reservation-content-container-interactive .reservation-template-header-tabContent:has(.router-link-active) {
    background: rgb(0 0 0 / 10%);
}

.reservation-content-container-interactive .reservation-template-header-tabContent i.pi {
    color: #000;
    font-size: 16px;
}

.reservation-content-container-interactive .reservation-template-header-tabContent p {
    color: #000;
    font-size: 13px;
    font-weight: 500;
}

</style>