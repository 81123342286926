<template>
    <div class="col-12 pt-0 pb-0 mt-4" v-if="results.length>0">
        <div class="review-showing-results text-right" v-if="total_records>0">
            <p>Showing {{from}}-{{ to }} of {{ total_records }} reviews</p>
        </div>
        <div class="review-comment-area">
            <div class="reviews-container">
                <div class="reviews-listing" v-for="item in results" :key="item">
                    <div class="review-header-box">
                        <h4 class="name-rev">{{item.customer.first_name}} {{item.customer.last_name}} <p class="review-date-text">posted {{ formate_date(item.reviews.created_at) }}</p></h4>
                        <div class="reviews-stars"><rating-prime readonly v-model="item.ratings.rating" :cancel="false" /></div>
                    </div>
                    <!-- <p class="review-date-text">{{ formate_date(item.reviews.created_at) }}</p> -->
                    <p class="review-title">{{ item.reviews.title }}</p>
                    <p class="review-message">{{ item.reviews.comments }}</p>
                    <div class="show-more-content hide" :showcontent="item.id">
                        <div class="reviews-more-option-list">
                            <h5>Food</h5>
                            <div class="reviews-star-box">
                                <rating-prime readonly v-model="item.ratings.food_rating" :cancel="false" />
                            </div>
                        </div>
                        <div class="reviews-more-option-list">
                            <h5>Service</h5>
                            <div class="reviews-star-box">
                                <rating-prime readonly v-model="item.ratings.service_rating" :cancel="false" />
                            </div>
                        </div>
                        <div class="reviews-more-option-list">
                            <h5>Atmosphere</h5>
                            <div class="reviews-star-box">
                                <rating-prime readonly v-model="item.ratings.atmosphere_rating" :cancel="false" />
                            </div>
                        </div>
                    </div>
                    <p class="show-more-btn" @click="show_more(item.id)" :databookingid="item.id">Show more</p>
                </div>

                
            </div>
        </div>
    </div>
    <div class="col-12 pt-0 pb-0 mt-4" v-else>
        <div class="review-showing-results">
            <h2 class="section-title-text mb-2">{{ $t('no_reviews_yet') }}</h2>
        </div>
    </div>    
    <div class="card">
        <paginator-prime v-if="total_records>10" @page="page" v-model:first="current_page" :rows="10" :totalRecords="total_records"></paginator-prime>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import $ from 'jquery'
export default {
    data() {
        return {
            current_page : 0,
            total_records : 0,
            page_number : 1,
            results : [],
            from : 0,
            to : 0
        }
    },
    mounted() {
      this.get_reviews_listing()
    },
    methods: {
        show_more : function(id) {
            if($('[showcontent="'+id+'"]').hasClass('hide')) {
                $('[showcontent="'+id+'"]').removeClass('hide');
                $('[databookingid="'+id+'"]').text('Show less');
            } else {
                $('[showcontent="'+id+'"]').addClass('hide');
                $('[databookingid="'+id+'"]').text('Show more');
            }
            
        },
        formate_date : function(dte) {
            // return moment(dte).format('DD MMM YYYY, hh:mm A');
            return moment(dte).format('MM-YY');
        },
        page : function(event) {
            this.page_number = parseInt(event.page+1);
            this.get_reviews_listing();
        },
        get_reviews_listing : function(){
            axios.get(this.api_url+'get_place_reviews?page='+this.page_number+'&place='+this.$route.params.id).then((response) => {
                this.total_records = response.data.data.total;
                this.from = response.data.data.from;
                if(this.from == null)
                this.from = 0;
                this.to = response.data.data.to;
                if(this.to == null)
                this.to = 0;
                this.results = response.data.data.data;
            });
        }
    }
};
</script>
