<template>
    <div class="reservation-template-footer-interactive">
        <p>powered by Amakenapp</p>
    </div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
      }
    },
    methods: {
    },
    mounted(){
    } 
  }
</script>

<style>
.reservation-template-footer-interactive p {
    margin: 0;
    color: #000;
}

.reservation-template-footer-interactive {
    margin-bottom: 2rem;
}
</style>