<template>
    <div class="main-content booking-seating-view" :style="{background : customization.second_box_color}">
        <div class="reservation-page-header" :style="{backgroundColor : customization.header_color, color : customization.text_box_color}">
            <!-- <i @click="back" class="pi pi-angle-left back-btn-arrow" style="font-size: 1.5rem"></i> -->
            <div class="reservation-header-tabs">
                <div class="reservation-header-tabContent">
                    <router-link :to="'/'+$route.params.slug+'/reservation/'">
                        <i :style="{color : customization.text_box_color}" class="pi pi-calendar"></i>
                        <p :style="{color : customization.text_box_color}">{{$t('reserve')}}</p>
                    </router-link>
                </div>
                <div class="reservation-header-tabContent">
                    <router-link :to="'/'+$route.params.slug+'/reviews/'">
                        <i :style="{color : customization.text_box_color}" class="pi pi-star-fill"></i>
                        <p :style="{color : customization.text_box_color}">{{$t('reviews')}}</p>
                    </router-link>
                </div>
                <div class="reservation-header-tabContent">
                    <router-link :to="'/'+$route.params.slug+'/contact/'">
                        <i :style="{color : customization.text_box_color}" class="pi pi-phone"></i>
                        <p :style="{color : customization.text_box_color}">{{$t('contact')}}</p>
                    </router-link>
                </div>
            </div>
        </div>

        <main>
            <div class="container first-box-element" :style="{background : customization.first_box_color}">
                <div class="reservation-container grid m-0 second-box-element reservation-thankYou-container" :style="{background : customization.second_box_color}">
                    <div class="col-12 md:col-12 lg:col-12">
                        <h4 :style="{color : customization.text_box_color}">{{$t('reviews')}}</h4>  
                        <div class="reviews-container" v-if="reviews.length>0">
                            <div class="reviews-listing" v-for="item in reviews" :key="item">
                                <div class="review-header-box">
                                    <h4 class="name-rev" :style="{color : customization.text_box_color}">{{ item.customer.first_name }} {{ item.customer.last_name }} <p class="review-date-text" :style="{color : customization.text_box_color}">posted {{formate_date(item.reviews.created_at)}}</p></h4>
                                    <div class="reviews-stars"><rating-prime readonly v-model="item.ratings.rating" :cancel="false" /></div>
                                </div>
                                <!-- <p class="review-date-text" :style="{color : customization.text_box_color}">{{ formate_date(item.reviews.created_at) }}</p> -->
                                <p class="review-title" :style="{color : customization.text_box_color}">{{ item.reviews.title }}</p>
                                <p class="review-message" :style="{color : customization.text_box_color}">{{ item.reviews.comments }}</p>
                                <div class="show-more-content hide" :showcontent="item.id">
                                    <div class="reviews-more-option-list">
                                        <h5 :style="{color : customization.text_box_color}">{{$t('food')}}</h5>
                                        <div class="reviews-star-box">
                                            <rating-prime readonly v-model="item.ratings.food_rating" :cancel="false" />
                                        </div>
                                    </div>
                                    <div class="reviews-more-option-list">
                                        <h5 :style="{color : customization.text_box_color}">{{$t('service')}}</h5>
                                        <div class="reviews-star-box">
                                            <rating-prime readonly v-model="item.ratings.service_rating" :cancel="false" />
                                        </div>
                                    </div>
                                    <div class="reviews-more-option-list">
                                        <h5 :style="{color : customization.text_box_color}">{{$t('atmosphere')}}</h5>
                                        <div class="reviews-star-box">
                                            <rating-prime readonly v-model="item.ratings.atmosphere_rating" :cancel="false" />
                                        </div>
                                    </div>
                                </div>
                                <!-- <p :style="{color : customization.button_box_color}" class="show-more-btn" :databookingid="item.id" v-on:click="isHidden = !isHidden">Show more</p> -->
                                <p :style="{color : customization.button_box_color}" class="show-more-btn" @click="show_more(item.id)" :databookingid="item.id">Show more</p>
                            </div>
                        </div>
                    <div v-else class="reviews-container">
                            <p :style="{color : customization.text_box_color, textAlign : 'center'}">{{ $t('no_reviews_yet') }}</p>
                    </div>
                    </div>
                </div>
            </div>
        </main>

        <div class="reservation-page-footer" :style="{backgroundColor : customization.header_color, color : customization.text_box_color}">
            <p>powered by Amakenapp</p>
        </div>
    </div>
</template>
  


<script>
import axios from 'axios'
import moment from 'moment'
import $ from 'jquery'

export default {
    
    data() {
        return {
            customization : {
                banner_background_color : '#313131',
                header_color : '#000000',
                footer_color : '#000000',
                first_box_color : '#212121',
                second_box_color : '#2C2C2C',
                text_box_color : '#FFFFFF',
                field_box_color : '#000000',
                button_box_color : '#884192',
                banner : null,
                logo : null,
                note : null
            },
            reviews : [],
            value : null,
            not_found : 0,

            isHidden: true
        }
    },
    mounted() {
        this.get_customization_settings();
        this.get_parent_place_reviews();
    },
    methods: { 
        show_more : function(id) {
            if($('[showcontent="'+id+'"]').hasClass('hide')) {
                $('[showcontent="'+id+'"]').removeClass('hide');
                $('[databookingid="'+id+'"]').text('Show less');
            } else {
                $('[showcontent="'+id+'"]').addClass('hide');
                $('[databookingid="'+id+'"]').text('Show more');
            }
            
        },
        formate_date : function(dte) {
            // return moment(dte).format('DD MMM YYYY, hh:mm A');
            return moment(dte).format('MM-YY');
        },
        get_customization_settings : function() {
            axios.get(this.api_url+'get_customization', {
                params: { 
                    slug: this.$route.params.slug,
                    type : 'place'
                } 
            }).then((response) => {
                if (response.data.data.customization != '') {
                    this.customization = response.data.data.customization;
                }
            });
        },
        get_parent_place_reviews : function() {
            axios.get(this.api_url+'get_parent_place_reviews', {
                params: { 
                    slug: this.$route.params.slug,
                } 
            }).then((response) => {
                if (response.data.data.customization != '') {
                    this.reviews = response.data.data.reviews;
                    if(this.reviews.length==0) {
                        this.not_found = 1;
                    }
                }
            });
        },
    }
};
</script>
  
<style scoped>

.reservation-summary-info-list {
    display: flex;
    align-items: flex-start;
    column-gap: 0.8rem;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
}

.reservation-summary-info-list .info-icon {
    display: flex;
    align-items: center;
    transform: translateY(3px);
}

.reservation-summary-info-list .info-icon i.pi {
    color: #fff;
}

.reservation-summary-info-list .info-text p {
    color: #fff;
    font-size: 14px;
}

.reservation-summary-container {
    margin-top: 1.5rem;
}

img.thankYouEmailImg {
    width: 50%;
    margin: auto;
    display: flex;
}

.content-box {
    margin-top: 2.5rem;
}

.reservation-thankYou-container .content-box p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

.reservation-thankYou-container .content-box h4 {
    margin-top: 0;
    font-size: 16px !important;
    margin-bottom: 0.5rem;
}

.reservation-thankYou-container .content-box.contact-content-box p {
    margin-bottom: 0.5rem;
}
</style>